import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import TasksRestApi, {
  EditableTaskStatus,
  IGetApplicationTasksRequest,
  ITask,
  TasksSortingField,
  TasksSortingType,
} from 'api/digifi/los/TasksApi';
import CommentsRestApi, { ICreateCommentParams } from 'api/digifi/los/CommentsRestApi';

enum TasksActionType {
  GetApplicationTasks = 'tasks/getApplicationTasks',
  UpdateTask = 'tasks/updateTask',
  CreateComment = 'tasks/createComment',
}

const tasksAdapter = createEntityAdapter<ITask>();

export interface ITasksState extends EntityState<ITask> {
  sortingType: TasksSortingType;
}

const initialState: ITasksState = tasksAdapter.getInitialState({
  sortingType: {
    field: TasksSortingField.Description,
    ascending: false,
  },
});

const tasksApi = new TasksRestApi();
const commentsApi = new CommentsRestApi();

export const getApplicationTasks = createAsyncThunk(
  TasksActionType.GetApplicationTasks,
  async (params: IGetApplicationTasksRequest) => {
    const { tasks } = await tasksApi.getApplicationTasks(params);

    return tasks;
  },
);

export const updateApplicationTask = createAsyncThunk<ITask, { id: string, status: EditableTaskStatus }>(
  TasksActionType.UpdateTask,
  async ({ id, status }) => {
    const { task: updatedTask } = await tasksApi.update(id, { status });

    return updatedTask;
  },
);

export const createTaskComment = createAsyncThunk(
  TasksActionType.CreateComment,
  async (params: ICreateCommentParams) => {
    return commentsApi.create({
      taskId: params.taskId,
      message: params.message,
    });
  },
);

const tasksSlice = createSlice({
  name: 'tasksSlice',
  initialState,
  reducers: {
    removeTasks(state) {
      tasksAdapter.removeAll(state);
    },
    updateTask(state, { payload }) {
      tasksAdapter.updateOne(state, payload);
    },
    setTasksSortingType(state, { payload }: PayloadAction<TasksSortingType>) {
      state.sortingType = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getApplicationTasks.fulfilled, (state, { payload }) => {
        tasksAdapter.setAll(state, payload);
      })
      .addCase(updateApplicationTask.fulfilled, (state, { payload}) => {
        tasksAdapter.setOne(state, payload);
      });
  },
});

export const { selectAll: selectAllTasks } = tasksAdapter.getSelectors((state: RootState) => state.tasks);
export const { removeTasks, updateTask, setTasksSortingType } = tasksSlice.actions;

export default tasksSlice.reducer;
