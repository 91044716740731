import React, { FC, useMemo } from 'react';
import Label from 'components/digifi-wrappers/Label/Label';
import UploadFile from 'components/digifi-wrappers/UploadFile/UploadFile';
import acceptFileTypes from 'utils/acceptFileTypes';
import styles from './DocumentUploadForm.module.scss';
import { Collapse } from '@material-ui/core';
import { CloseIcon } from 'static/images';
import { IApplicationDocumentConfiguration } from 'api/digifi/los/CardsApi';
import sortByPosition from 'product_modules/utils/sortByPosition';
import UploadDocumentRequirements from 'components/common/UploadDocumentRequirements';
import useConditionalDisplayLogic from 'product_modules/hooks/useConditionalDisplayLogic';
import useUploadApplicationDocumentsValidation from 'hooks/useUploadApplicationDocumentsValidation';
import SkeletonUploadFileStub from 'components/digifi-wrappers/SkeletonUploadFileStub';
import useFullApplicationData from 'hooks/useFullApplicationData';
import { FormLayoutData } from 'api/Types';
import useCalculationsLogic from 'product_modules/hooks/useCalculationsLogic';
import { ProductCalculation } from 'product_modules/api/LoanOriginationSystem/ProductCalculationsApi';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const MAX_FILE_SIZE = 1024 * 1024 * 25; // 25 Mb
const SKELETON_DOCUMENTS_COUNT = 5;

export interface IDocumentUploadFile {
  configurationId: string;
  configurationName: string;
  folderId?: string | null;
  actualFile: File;
}

interface IDocumentUploadFormProps {
  files: IDocumentUploadFile[];
  applicationDocumentConfiguration: IApplicationDocumentConfiguration[];
  onFileAdd: (file: IDocumentUploadFile) => void;
  onFileRemove: (configurationId: string) => void;
  renderActions: (submitDisabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
  borrowerFormData: FormLayoutData;
  applicationDetailsFormData: FormLayoutData;
  productCalculations: ProductCalculation[] | null;
  isLoading?: boolean;
}
const useStylesCollapse = makeStyles(() =>
  createStyles({
    root: {
     overflow: 'hidden',
    },
  }),
);

const DocumentUploadForm: FC<IDocumentUploadFormProps> = ({
  files,
  applicationDocumentConfiguration,
  onFileAdd,
  onFileRemove,
  renderActions,
  borrowerFormData,
  applicationDetailsFormData,
  productCalculations,
  isLoading,
}) => {
  const classes = useStylesCollapse();

  const fullApplicationData = useFullApplicationData({
    borrowerFormData,
    applicationDetailsFormData,
  });
  const fullApplicationDataWithCalculations = useCalculationsLogic(productCalculations, fullApplicationData);

  const displayFieldsAttributes = useConditionalDisplayLogic(applicationDocumentConfiguration, fullApplicationDataWithCalculations);
  const validateDocuments = useUploadApplicationDocumentsValidation(applicationDocumentConfiguration, files);

  const sortedApplicationDocumentConfiguration = useMemo(() => {
    if (!applicationDocumentConfiguration) {
      return null;
    }

    return sortByPosition(applicationDocumentConfiguration);
  }, [applicationDocumentConfiguration]);

  const filesByConfigurationId: Record<string, IDocumentUploadFile> = useMemo(() => {
    return files.reduce((previousFilesByConfigurationId, file) => ({
      ...previousFilesByConfigurationId,
      [file.configurationId]: file,
    }), {});
  }, [files]);

  const handleFileChange = (configuration: IApplicationDocumentConfiguration, file: File | null) => {
    if (!file) {
      onFileRemove(configuration.id);

      return;
    }

    onFileAdd({
      actualFile: file,
      configurationId: configuration.id,
      configurationName: configuration.name,
      folderId: configuration.folderId,
    });
  };

  const renderFileUpload = (configuration: IApplicationDocumentConfiguration) => {
    const displayFileUpload = !displayFieldsAttributes
      || displayFieldsAttributes[configuration.id]
      || !!filesByConfigurationId[configuration.id]?.actualFile;

    return (
      <Collapse
        in={displayFileUpload}
        key={configuration.id}
        unmountOnExit
        classes={{
          root: classes.root,
        }}
      >
        <Label className={styles.fileUploadLabel} required={configuration.required}>{configuration.name}</Label>
        <UploadFile
          accept={acceptFileTypes}
          maxFileSize={MAX_FILE_SIZE}
          file={filesByConfigurationId[configuration.id]?.actualFile}
          onFileChange={(file) => handleFileChange(configuration, file)}
          closeIcon={<CloseIcon className={styles.deleteFileIcon} />}
        />
      </Collapse>
    );
  };

  const renderFormContent = () => {
    if (isLoading || !sortedApplicationDocumentConfiguration || !displayFieldsAttributes) {
      return (
        <>
          {new Array(SKELETON_DOCUMENTS_COUNT).fill(null).map((key, index) => (
            <SkeletonUploadFileStub className={styles.fileUploadContainer} key={index} />
          ))}
        </>
      );
    }

    return sortedApplicationDocumentConfiguration.map(renderFileUpload);
  };

  return (
    <>
      <div className={styles.fields}>
        {renderFormContent()}
        {sortedApplicationDocumentConfiguration && sortedApplicationDocumentConfiguration.length > 0 && displayFieldsAttributes && <UploadDocumentRequirements />}
        {renderActions(!sortedApplicationDocumentConfiguration, !validateDocuments(displayFieldsAttributes))}
      </div>
    </>
  );
};

export default DocumentUploadForm;
