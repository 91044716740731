import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import SettingsRestApi, { ILegalDocument } from 'api/digifi/dlp/SettingsApi';
import { BorrowerType } from 'enums/BorrowerType';

enum SettingsActionType {
  GetSettings = 'settings/getSettings',
}

export const DEFAULT_PHONE_NUMBER_FORMAT = 'US';
const DEFAULT_CURRENCY = '$';

interface IBrandingParams {
  accentColor: string | null;
  brandColor: string | null;
  faviconId: string | null;
  logoId: string | null;
  companyName: string | null;
  country: string | null;
}

interface IPortalConfiguration {
  borrowerType: BorrowerType;
}

interface IVariablesSettings {
  phoneNumberFormat: string;
  currency: string;
}

export interface ISettingsState {
  branding: IBrandingParams;
  portalConfiguration: IPortalConfiguration;
  variablesSettings: IVariablesSettings;
  legalDocuments: ILegalDocument[];
  selectedDocument: ILegalDocument | null;
}

const initialState: ISettingsState = {
  branding: {
    accentColor: null,
    brandColor: null,
    faviconId: null,
    logoId: null,
    companyName: null,
    country: null,
  },
  portalConfiguration: {
    borrowerType: BorrowerType.Person,
  },
  variablesSettings: {
    phoneNumberFormat: DEFAULT_PHONE_NUMBER_FORMAT,
    currency: DEFAULT_CURRENCY,
  },
  legalDocuments: [],
  selectedDocument: null,
};

const settingsApi = new SettingsRestApi();

export const getSettings = createAsyncThunk(
  SettingsActionType.GetSettings,
  async() => {
    const branding = await settingsApi.getBranding();
    const legalDocuments = await settingsApi.getLegalDocuments();
    const portalConfiguration = await settingsApi.getPortalConfiguration();
    const variablesSettings = await settingsApi.getVariablesSettings();

    return { branding, legalDocuments, portalConfiguration, variablesSettings };
  },
);

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    setSelectedDocument(state, { payload }: PayloadAction<ILegalDocument | null>) {
      state.selectedDocument = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSettings.fulfilled, (state, { payload }) => {
        state.branding = payload.branding;
        state.portalConfiguration = payload.portalConfiguration;
        state.variablesSettings = payload.variablesSettings;
        state.legalDocuments = payload.legalDocuments;
      });
    },
});

export const { setSelectedDocument } = settingsSlice.actions;

export default settingsSlice.reducer;
