import { FC } from 'react';
import { SkeletonCardsForm, SkeletonCardsFormProps } from 'product_modules/components/CardsForm';

export const DEFAULT_SKELETON_CARDS_LAYOUT = [[5], [3]];

const SkeletonCardsFormWrapper: FC<SkeletonCardsFormProps> = (props) => {
  return (
    <SkeletonCardsForm {...props} />
  );
};

export default SkeletonCardsFormWrapper;
