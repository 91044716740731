import { FC } from 'react';
import PopUp from 'components/digifi-wrappers/PopUp';
import PopUpContent from 'components/digifi-wrappers/PopUpContent';
import Button from 'components/digifi-wrappers/Button';
import styles from './MarkAsDonePopUp.module.scss';

interface IMarkAsDonePopUpProps {
  onClose: () => void;
  onComplete: () => void;
  isLoading?: boolean;
}

const MarkAsDonePopUp: FC<IMarkAsDonePopUpProps> = ({ onClose, onComplete, isLoading }) => {
  return (
    <PopUp
      title="Mark As Done"
      closePopUp={onClose}
      classNames={{
        sectionClassName: styles.popUpContainer,
        header: styles.popUpHeader,
      }}
    >
      <PopUpContent hasTopMargin className={styles.popUpContent}>
        Are you sure this task is complete?
        <div className={styles.buttonsContainer}>
          <Button
            kind="primary"
            size="form"
            onClick={onComplete}
            isLoading={isLoading}
          >
            Yes, Mark As Done
          </Button>
          <Button kind="secondary" size="form" onClick={onClose}>
            No, Go Back
          </Button>
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default MarkAsDonePopUp;
