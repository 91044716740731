import { FC } from 'react';
import styles from './TabContent.module.scss';

interface ITabContentProps {
  tabTitle: string | JSX.Element;
}

const TabContent: FC<ITabContentProps> = ({ tabTitle, children }) => {
  return (
    <div className={styles.tabContentContainer}>
      <div className={styles.title}>{tabTitle}</div>
      {children}
    </div>
  );
};

export default TabContent;
