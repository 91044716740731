import { FC } from 'react';
import styles from 'components/ApplicationDetails/ApplicationOverview/ApplicationOverview.module.scss';
import InfoSection from 'components/common/InfoSection';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import ContainerStyled from 'components/BrandingHelpers/ContainerStyled';

const COLUMNS_COUNT = 3;
const MOBILE_ROWS_COUNT = 6;

const SkeletonApplicationOverview: FC = () => {
  const desktopOverviewSkeletonCellsMap = Array.from({ length: COLUMNS_COUNT }).map(() => {
    return new Map<JSX.Element, JSX.Element>([
      [
        <SkeletonText className={styles.skeletonField} width="100px" height="12px" color="primary20" lineHeight="16px" />,
        <SkeletonText className={styles.skeletonValue} width="140px" height="14px" color="primary20" lineHeight="20px"  />,
      ],
      [
        <SkeletonText className={styles.skeletonField} width="100px" height="12px" color="primary20" lineHeight="16px" />,
        <SkeletonText className={styles.skeletonValue} width="140px" height="14px" color="primary10" lineHeight="20px" />,
      ],
    ]);
  });

  const mobileOverflowSkeletonCellMap = new Map<JSX.Element, JSX.Element>([]);
  Array.from({ length: MOBILE_ROWS_COUNT }).forEach(() => {
    mobileOverflowSkeletonCellMap.set(
      <SkeletonText className={styles.skeletonField} width="100px" height="12px" color="primary20" lineHeight="16px" />,
      <SkeletonText className={styles.skeletonValue} width="140px" height="14px" color="primary10" lineHeight="20px" />,
    );
  });

  return (
    <ContainerStyled className={styles.overviewContainer}>
      <div className={styles.overviewTitle}>
        <SkeletonText className={styles.skeletonField} width="220px" height="16px" color="primary20" lineHeight="24px" />
      </div>
      <div className={styles.infoSectionDesktop}>
        <InfoSection
          sectionContent={desktopOverviewSkeletonCellsMap}
          containerClassName={styles.overviewContent}
          fieldTitleClassName={styles.fieldTitle}
          fieldValueClassName={styles.fieldValue}
          fieldClassName={styles.field}
        />
      </div>
      <div className={styles.infoSectionMobile}>
        <InfoSection
          sectionContent={[mobileOverflowSkeletonCellMap]}
          containerClassName={styles.overviewContent}
          fieldTitleClassName={styles.fieldTitle}
          fieldValueClassName={styles.fieldValue}
          fieldClassName={styles.field}
        />
      </div>
    </ContainerStyled>
  );
};

export default SkeletonApplicationOverview;
