import {
  ApplicationDocumentsSortingField,
  ApplicationDocumentsSortingType,
  IApplicationDocument,
} from 'api/digifi/los/DocumentsApi';
import { sortDates, sortNumbers, sortStrings } from 'product_modules/utils/sort';

export const sortApplicationDocuments = (documents: IApplicationDocument[], sortingType: ApplicationDocumentsSortingType) => {
  return [...documents].sort((firstDocument, secondDocument) => {
    return sortFiles(firstDocument, secondDocument)(sortingType);
  });
};

const sortFiles =
  (docA: IApplicationDocument, docB: IApplicationDocument) =>
    ({ ascending, field }: ApplicationDocumentsSortingType) => {
      switch (field) {
        case ApplicationDocumentsSortingField.Name:
        case ApplicationDocumentsSortingField.Extension: {
          return sortStrings(docA[field], docB[field], ascending);
        }
        case ApplicationDocumentsSortingField.CreatedAt: {
          return sortDates(new Date(docA[field]), new Date(docB[field]), ascending);
        }
        case ApplicationDocumentsSortingField.Size: {
          return sortNumbers(docA[field], docB[field], ascending);
        }
        default: {
          return -1;
        }
      }
    };
