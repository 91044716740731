import { FC } from 'react';
import Footer from 'components/PageLayout/Footer';
import Notifications from 'components/Notifications/NotificationsLayout/Notifications';
import { useAppSelector } from 'hooks/reduxHooks';
import LegalDocumentPopUp from 'components/LegalDocumentPopUp';
import styles from './MainLayout.module.scss';
import clsx from 'clsx';
import CustomHtmlHead from 'components/PageLayout/CustomHtmlHead';
import { Snackbar } from 'components/Snackbar/ConnectedSnackbar';

interface IMAinLayoutProps {
  disableScroll?: boolean;
  applicationsBrowserTitle?: string;
  contentClassName?: string;
}

const MainLayout: FC<IMAinLayoutProps> = ({ disableScroll, applicationsBrowserTitle, contentClassName, children }) => {
  const { selectedDocument } = useAppSelector((state) => state.settings);

  const renderLegalDocumentsPopUp = () => {
    return <LegalDocumentPopUp
      name={selectedDocument?.name!}
      body={selectedDocument?.body!}
      updatedAt={selectedDocument?.updatedAt!}
    />;
  };

  return(
    <div className={clsx({ [styles.mainLayoutContainerDisabled]: selectedDocument || disableScroll })}>
      <CustomHtmlHead applicationsBrowserTitle={applicationsBrowserTitle} />
      <div className={clsx(styles.mainLayoutContent, contentClassName)}>
        {children}
        <Footer />
        <Notifications />
        <Snackbar />
      </div>
      {selectedDocument && renderLegalDocumentsPopUp()}
    </div>
  );
};

export default MainLayout;
