import { FC } from 'react';
import PopUp from 'components/digifi-wrappers/PopUp';
import PopUpContent from 'components/digifi-wrappers/PopUpContent';
import Button from 'components/digifi-wrappers/Button';
import styles from './DeleteFormPopUp.module.scss';
import clsx from 'clsx';

interface IDeleteFormPopUpProps {
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteFormPopUp: FC<IDeleteFormPopUpProps> = ({ onClose, onSubmit }) => {
  return (
    <PopUp title="Delete Form" closePopUp={onClose} classNames={{ sectionClassName: styles.popUpContainer }}>
      <PopUpContent hasTopMargin className={clsx(styles.deleteFormContent, styles.popUpContent)} >
        Are you sure you want to delete this application form?
        <div className={styles.buttonsContainer}>
          <Button kind="primary" size="form" onClick={onSubmit}>
            Yes, Delete Form
          </Button>
          <Button kind="secondary" size="form" onClick={onClose}>
            No, Go Back
          </Button>
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default DeleteFormPopUp;
