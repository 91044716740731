import React, { ReactElement, Fragment, useRef, useEffect } from 'react';
import clsx from 'clsx';
import ScrollableTabs from 'product_modules/components/ScrollableTabs';
import ButtonWithImage from 'product_modules/components/ButtonWithImage';
import { RemoveTabImage } from 'product_modules/static/images';
import styles from './TabSwitch.module.scss';
import scrollIntoView from 'scroll-into-view-if-needed';

export interface TabSwitchOption<TabSwitchOptionLabelType = ReactElement | string> {
  label: TabSwitchOptionLabelType;
  id: string;
  type?: 'default' | 'button';
  ref?: React.RefObject<HTMLButtonElement>;
  disableDelete?: boolean;
  onClick?: () => void;
}

export interface TabSwitchProps<TabSwitchOptionLabelType> {
  tabs: TabSwitchOption<TabSwitchOptionLabelType>[];
  selectedTabId?: string;
  onSelect?: (tab: TabSwitchOption<TabSwitchOptionLabelType>) => void;
  className?: string;
  buttonsTabIndex?: number;
  allowTabDelete?: boolean;
  onTabDelete?: (tab: TabSwitchOption<TabSwitchOptionLabelType>) => void;
  tabClassName?: string;
  buttonTabClassName?: string;
  removeTabClassName?: string;
  borderContainerClassName?: string;
  checkedTabClassName?: string;
  hideBorder?: boolean;
  touchScroll?: boolean;
  onTabButtonClick?: (tabId: string, defaultTabAction?: () => void) => void;
}

const TabSwitch = <TabSwitchOptionType,>({
  tabs,
  selectedTabId,
  onSelect,
  onTabDelete,
  allowTabDelete,
  className,
  tabClassName,
  buttonsTabIndex,
  buttonTabClassName,
  removeTabClassName,
  borderContainerClassName,
  onTabButtonClick,
  hideBorder,
  checkedTabClassName,
  touchScroll,
}: TabSwitchProps<TabSwitchOptionType>) => {
  const checkedTabClassNames = clsx(styles.checked, checkedTabClassName);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const getTargetElementToScroll = () => {
    const tab = tabs.find(({ id }) => id === selectedTabId)!;
    const tabIndex = tabs.indexOf(tab);

    return scrollableContainerRef.current!.children[tabIndex];
  };

  useEffect(() => {
    if (scrollableContainerRef.current && touchScroll) {
      scrollIntoView(getTargetElementToScroll(), {
        scrollMode: 'if-needed',
        behavior: 'smooth',
      });
    }
  }, [selectedTabId, scrollableContainerRef, touchScroll]);

  return (
    <div className={clsx(styles.container, className)}>
      <ScrollableTabs
        className={styles.tabs}
        scrollableElementsClassName={styles.scrollableElements}
        scrollableTabContainerClassName={styles.scrollableTabContainer}
        touchScroll={touchScroll}
        ref={scrollableContainerRef}
      >
        {tabs.map((tab) => (
          <Fragment key={tab.id}>
            {(!tab.type || tab.type === 'default') && (
              <div className={clsx(styles.tab, tabClassName)}>
                <div className={clsx(styles.defaultTabContent, selectedTabId === tab.id && checkedTabClassNames)}>
                  <input
                    type="radio"
                    id={tab.id}
                    name="switch"
                    checked={selectedTabId === tab.id}
                    onChange={() => onSelect?.(tab)}
                  />
                  <label htmlFor={tab.id}>{tab.label}</label>
                  {allowTabDelete && !tab.disableDelete && (
                    <div className={clsx(styles.closeImage, removeTabClassName)} onClick={() => onTabDelete?.(tab)}>
                      <RemoveTabImage />
                    </div>
                  )}
                </div>
              </div>
            )}
            {tab.type === 'button' && (
              <div className={clsx(styles.tab, buttonTabClassName)} key={tab.id}>
                <label className={styles.addTabButton}>
                  <ButtonWithImage
                    ref={tab.ref}
                    title={(tab.label as unknown) as ReactElement | string}
                    kind="add"
                    onClick={() => onTabButtonClick ? onTabButtonClick(tab.id, tab.onClick) : tab.onClick?.()}
                    tabIndex={buttonsTabIndex}
                  />
                </label>
              </div>
            )}
          </Fragment>
        ))}
      </ScrollableTabs>
      {!hideBorder && (
        <div className={clsx(styles.borderContainer, borderContainerClassName)}>
          <div className={styles.border} />
        </div>
      )}
    </div>
  );
};

export default TabSwitch;
