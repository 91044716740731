import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import CardsRestApi, { IBorrowerProfileCard } from 'api/digifi/los/CardsApi';

enum BorrowersProfileCardsActionType {
  GetBorrowerProfileCards = 'borrowerProfileCards/getBorrowerProfileCards',
}

const borrowerProfileCardsAdapter = createEntityAdapter<IBorrowerProfileCard>();

const initialState = borrowerProfileCardsAdapter.getInitialState();

const cardsRestApi = new CardsRestApi();

export const getBorrowerProfileCards = createAsyncThunk(
  BorrowersProfileCardsActionType.GetBorrowerProfileCards,
  async () => {
    return cardsRestApi.getBorrowerProfileCards();
  },
);

const borrowerProfileCardsSlice = createSlice({
  name: 'borrowerProfileCardsSlice',
  initialState,
  reducers: {
    removeBorrowerProfileCards(state) {
      borrowerProfileCardsAdapter.removeAll(state);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getBorrowerProfileCards.fulfilled, (state, { payload }) => {
        borrowerProfileCardsAdapter.setAll(state, payload);
      });
  },
});

export const { selectAll: selectBorrowerProfileCards } = borrowerProfileCardsAdapter.getSelectors((state: RootState) => state.borrowerProfileCards);
export const { removeBorrowerProfileCards } = borrowerProfileCardsSlice.actions;

export default borrowerProfileCardsSlice.reducer;
