import { TransformWrapper, TransformComponent,  ReactZoomPanPinchState } from 'react-zoom-pan-pinch';
import React, { FC, useEffect, useState } from 'react';
import styles from './ImagePreview.module.scss';
import { MinusIcon, PlusIcon } from 'product_modules/static/images';
import clsx from 'clsx';
import { DocumentExtension } from 'api/digifi/los/DocumentsApi';
import heic2any from 'heic2any';

interface IImagePreviewProps {
  previewLink: string;
  filename: string;
  fileExtension: string;
  onLoad: () => void;
  onError: () => void;
}

interface ITransformProps {
  zoomIn: () => void;
  zoomOut: () => void;
  resetTransform: () => void;
  state: ReactZoomPanPinchState;
}

const MIN_SCALE = 0.5;
const MAX_SCALE = 8;

const IMAGE_FORMATS_TO_CONVERT = [
  DocumentExtension.Heic,
];

const ImagePreview: FC<IImagePreviewProps> = ({ previewLink, filename, fileExtension, onLoad, onError }) => {
  const [src, setSrc] = useState<string | null>(null);

  const convertImage = async () => {
    const downloadResponse = await fetch(previewLink);

    const convertedFile = await heic2any({
      blob: await downloadResponse.blob(),
    }) as Blob;

    return window.URL.createObjectURL(convertedFile);
  };

  const processImage = async () => {
    if (IMAGE_FORMATS_TO_CONVERT.includes(fileExtension as DocumentExtension)) {
      const convertedPreviewLink = await convertImage();

      setSrc(convertedPreviewLink);

      return;
    }

    setSrc(previewLink);
  };

  useEffect(() => {
    processImage();
  }, [previewLink]);

  return (
    <TransformWrapper
      minScale={MIN_SCALE}
      maxScale={MAX_SCALE}
    >
      {({ zoomIn, zoomOut, resetTransform, state }: ITransformProps) => {
       return (
         <>
          <TransformComponent wrapperClass={styles.wrapper} contentClass={styles.content}>
            {src && <img
              src={src}
              alt={filename}
              onLoad={onLoad}
              onError={onError}
              className={styles.image}
            />}
          </TransformComponent>
          <div className={styles.tools}>
            <div
              onClick={() => zoomOut()}
              className={clsx(styles.zoomIcon, state.scale === MIN_SCALE && styles.disabled)}
            >
              <MinusIcon />
            </div>
            <div
              onClick={resetTransform}
              className={styles.resetButton}
            >
              {Math.round(state.scale * 100)}%
            </div>
            <div
              onClick={() => zoomIn()}
              className={clsx(styles.zoomIcon, state.scale === MAX_SCALE && styles.disabled)}
            >
              <PlusIcon />
            </div>
          </div>
        </>
       );
      }}
    </TransformWrapper>
  );
};

export default ImagePreview;
