import { FC, useEffect, useState } from 'react';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import OnlineSignUp from 'components/Auth/OnlineSignUp';
import { useNavigate, useParams } from 'react-router-dom';
import AcceptInvite from 'components/Auth/AcceptInvite';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getInviteInfo } from 'handlers/authSlice';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import { AppRoute } from 'enums/AppRoute';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createNotification } from 'handlers/notificationsSlice';

const CreateAccount: FC = () => {
  const { token } = useParams();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const { inviteData, accountData } = useAppSelector(state => state.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchData = async (inviteToken: string) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(getInviteInfo(inviteToken));
      setIsLoading(false);
    } catch (error) {
      createNotification({
        type: 'error',
        notification: error.message,
        dispatch,
      });

      setIsLoading(false);
      navigate(AppRoute.SignIn);
    }
  };

  useEffect(() => {
    if (token && !accountData) {
      fetchData(token);
    }
  }, [token]);

  const renderForm = () => {
    return token ? <AcceptInvite token={token} /> : <OnlineSignUp />;
  };

  if (token && (!inviteData || isLoading)) {
    return <LoaderOverlay />;
  }

  return  (
      <UnauthorizedLayout
        formTitle="Create Your Account"
        formSubtitle="Please tell us about yourself."
        footerText="Already have an account?"
        footerLinkText="Sign In"
        footerLink={AppRoute.SignIn}
      >
        {renderForm()}
      </UnauthorizedLayout>
    );
};

export default CreateAccount;
