import { sortDates, sortNumbers, sortStrings } from 'product_modules/utils/sort';
import { ApplicationSortingField, ApplicationsSortingType, ISimplifiedApplicationParams } from 'api/digifi/los/ApplicationsApi';

export const sortApplications = (applications: ISimplifiedApplicationParams[], sortingType: ApplicationsSortingType) => {
  return [...applications].sort((firstApplication, secondApplication) => {
    return sortApplicationPair(firstApplication, secondApplication)(sortingType);
  });
};

const sortApplicationPair =
  (applicationA: ISimplifiedApplicationParams, applicationB: ISimplifiedApplicationParams) =>
    ({ ascending, field }: ApplicationsSortingType) => {
      switch (field) {
        case ApplicationSortingField.DisplayId:
        case ApplicationSortingField.ProductName:
        case ApplicationSortingField.Status: {
          return sortStrings(applicationA[field], applicationB[field], ascending);
        }
        case ApplicationSortingField.CreatedAt:
        case ApplicationSortingField.StatusDate: {
          return sortDates(new Date(applicationA[field] || ''), new Date(applicationB[field] || ''), ascending);
        }
        case ApplicationSortingField.LoanAmount: {
          return sortNumbers(applicationA[field], applicationB[field], ascending);
        }
        default: {
          return -1;
        }
      }
    };
