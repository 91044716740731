import {
  VariableClientType,
  VisualDataType,
  VariableAdvancedDataTypeAttributes,
  VisualDataTypeWithAttributes,
  Variable,
  VariableAccessPermissionType,
  VariableAccessPermissions,
  VariableCapAttributes,
  DataType,
  UndefinedVisualDataTypeWithNoAttributes,
  PhoneNumberWithAttributes,
  IdentificationNumberWithAttributes,
  MonetaryWithAttributes,
  DateWithAttributes,
  ListWithAttributes, AddressWithAttributes,
} from './VariablesTypes';
import { unhandledCase } from 'product_modules/utils/unhandledCase';
import { VARIABLE_DATA_TYPES } from 'product_modules/components/VariableForm/DataTypes';
import { PermissionGroupId } from 'product_modules/components/PermissionGroups/Types';
import { GeocodeResult, Suggestions } from 'use-places-autocomplete';

export type VariableData = Pick<Variable, 'dataType' | 'numberFormat' | 'stringFormat' | 'optionsList'>;

interface VariableUserAccessPermissionsHolder {
  permissions: VariableAccessPermissions;
}

const EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES = {
  numberFormat: null,
  stringFormat: null,
  optionsList: null,
  phoneNumberFormat: null,
  identificationNumberType: null,
  identificationNumberDescription: null,
  currency: null,
  dateFormat: null,
  getSuggestions: null,
  getGeocode: null,
  country: null,
};

export const getVisualDataType = (variableData: VariableData): VisualDataType => {
  const { dataType, numberFormat, stringFormat, optionsList } = variableData;
  switch (dataType) {
    case 'Boolean': {
      return 'Boolean';
    }
    case 'Date': {
      return 'Date';
    }
    case 'Number': {
      return numberFormat || 'Number';
    }
    case 'String': {
      if (optionsList && optionsList.length) {
        return 'List';
      }
      if (stringFormat) {
        return stringFormat;
      }
      return 'Text';
    }
    default: {
      return dataType;
    }
  }
};

export const getVisualDataTypeWithAttributes = (
  variable: Pick<
    Variable,
    | 'dataType'
    | 'phoneNumberFormat'
    | 'identificationNumberType'
    | 'identificationNumberDescription'
    | 'currency'
    | 'dateFormat'
    | 'optionsList'
    | 'numberFormat'
    | 'stringFormat'
    | 'minAllowedValue'
    | 'maxAllowedValue'
    | 'getSuggestions'
    | 'getGeocode'
    | 'country'
    | 'classNames'
  >,
): VisualDataTypeWithAttributes & VariableCapAttributes & { formatType: DataType } => {
  const dataType = getVisualDataType(variable);

  const baseAttributes = {
    minAllowedValue: variable.minAllowedValue,
    maxAllowedValue: variable.maxAllowedValue,
    formatType: variable.dataType,
  };

  if (dataType === 'PhoneNumber') {
    return {
      ...baseAttributes,
      dataType,
      phoneNumberFormat: variable.phoneNumberFormat,
    };
  }

  if (dataType === 'IdentificationNumber') {
    return {
      ...baseAttributes,
      dataType,
      identificationNumberType: variable.identificationNumberType,
      identificationNumberDescription: variable.identificationNumberDescription,
    };
  }
  if (dataType === 'Monetary') {
    return {
      ...baseAttributes,
      dataType,
      currency: variable.currency,
    };
  }
  if (dataType === 'Date') {
    return {
      ...baseAttributes,
      dataType,
      dateFormat: variable.dateFormat,
    };
  }
  if (dataType === 'List') {
    return {
      ...baseAttributes,
      dataType,
      optionsList: variable.optionsList!,
    };
  }
  if(dataType === 'Address') {
    return {
      ...baseAttributes,
      dataType,
      country: variable.country!,
      getGeocode: variable.getGeocode!,
      getSuggestions: variable.getSuggestions!,
      classNames: variable.classNames,
    };
  }

  return {
    ...baseAttributes,
    dataType,
  };
};

export const getVariableType = (variable: VariableClientType): VariableAdvancedDataTypeAttributes => {
  const { dataType } = variable;
  switch (dataType) {
    case 'Boolean': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'Boolean',
      };
    }
    case 'Date': {
      const { dateFormat } = variable;
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'Date',
        dateFormat,
      };
    }
    case 'Monetary': {
      const { currency } = variable;
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'Number',
        numberFormat: 'Monetary',
        currency,
      };
    }
    case 'Percentage': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'Number',
        numberFormat: 'Percentage',
      };
    }
    case 'Number': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'Number',
      };
    }
    case 'List': {
      const { optionsList } = variable;
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'String',
        optionsList,
      };
    }
    case 'Text': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'String',
      };
    }
    case 'LargeText': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'String',
      };
    }
    case 'PhoneNumber': {
      const { phoneNumberFormat } = variable;
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'String',
        stringFormat: 'PhoneNumber',
        phoneNumberFormat,
      };
    }
    case 'EmailAddress': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'String',
        stringFormat: 'EmailAddress',
      };
    }
    case 'IdentificationNumber': {
      const { identificationNumberType, identificationNumberDescription } = variable;
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'String',
        stringFormat: 'IdentificationNumber',
        identificationNumberType,
        identificationNumberDescription,
      };
    }
    case 'Address': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'Address',
      };
    }
    default: {
      return unhandledCase(dataType);
    }
  }
};

export const getVariableDataTypeName = (dataType: VisualDataType): string => {
  const option = VARIABLE_DATA_TYPES.find(({ value }) => value === dataType)!;
  return option.name;
};

export const getVariableAccessPermissions = (
  variable: VariableUserAccessPermissionsHolder | undefined,
  userPermissionGroupId: PermissionGroupId | undefined,
) => {
  if (!userPermissionGroupId) {
    return undefined;
  }

  return variable?.permissions[userPermissionGroupId] ?? VariableAccessPermissionType.ReadWrite;
};

export const isPhoneNumber = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is PhoneNumberWithAttributes => props.dataType === 'PhoneNumber';

export const isIdentificationNumber = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is IdentificationNumberWithAttributes => props.dataType === 'IdentificationNumber';

export const isMonetaryDataType = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is MonetaryWithAttributes => props.dataType === 'Monetary';

export const isDateDataType = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is DateWithAttributes => props.dataType === 'Date';

export const isListDataType = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is ListWithAttributes => props.dataType === 'List';

export const isAddressDataType = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is AddressWithAttributes => props.dataType === 'Address';
