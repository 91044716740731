import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import ProductsRestApi, { IProduct } from 'api/digifi/los/ProductsApi';
import { RootState } from 'store';

enum ProductsActionType {
  GetProducts = 'products/getProducts',
}

const productsAdapter = createEntityAdapter<IProduct>();

export interface IProductsState extends EntityState<IProduct> {
  selectedProduct: IProduct | null;
}

const initialState: IProductsState = productsAdapter.getInitialState({
  selectedProduct: null,
});

const productsApi = new ProductsRestApi();

export const getAllAvailableProducts = createAsyncThunk(
  ProductsActionType.GetProducts,
  async() => {
    return productsApi.getAllActiveProductNames();
  },
);

const productsSlice = createSlice({
  name: 'productsSlice',
  initialState,
  reducers: {
    setSelectedProduct(state, { payload }: PayloadAction<IProduct>) {
      state.selectedProduct = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllAvailableProducts.fulfilled, (state, { payload }) => {
        productsAdapter.setAll(state, payload);
      });
  },
});

export const { selectAll: selectAllProducts } = productsAdapter.getSelectors((state: RootState) => state.products);
export const { setSelectedProduct } = productsSlice.actions;

export default productsSlice.reducer;
