import { FC } from 'react';
import styles from 'components/ApplicationsList/ApplicationsHeader/ApplicationsHeader.module.scss';
import Logo from 'components/common/Logo';
import BorrowerDetails from 'components/ApplicationsList/BorrowerDetails';
import { useAppSelector } from 'hooks/reduxHooks';
import { selectAllBorrowers } from 'handlers/borrowersSlice';
import { getBorrowerFullName } from 'utils/borrowerNameHelper';

interface IApplicationsHeaderProps {
  onLoginDetailsClick?: () => void;
  onLogoutClick?: () => void;
  isLoading?: boolean;
  hideBorrowerDetails?: boolean;
}

const ApplicationsHeader: FC<IApplicationsHeaderProps> = ({ onLoginDetailsClick, onLogoutClick, isLoading, hideBorrowerDetails }) => {
  const [borrower] = useAppSelector(selectAllBorrowers);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerContent}>
        <Logo />
        {!hideBorrowerDetails && (
          <BorrowerDetails
            borrowerName={getBorrowerFullName(borrower?.variables, borrower?.type)}
            onLoginDetailsClick={onLoginDetailsClick}
            onLogoutClick={onLogoutClick}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default ApplicationsHeader;
