import baseStyled, { ThemedStyledInterface } from 'styled-components';

export interface IAppTheme {
  colors: {
    accentColor: string;
    brandColor: string;
    backgroundHoverAccentColor: string;
    hoverAccentColor: string;
    white100Base: string;
  };
}

export interface IStyledComponentProps {
  theme: ThemedStyledInterface<IAppTheme>;
}

const styled = baseStyled as ThemedStyledInterface<IAppTheme>;

export default styled;
