import React, { FocusEvent, forwardRef, KeyboardEvent } from 'react';
import TextInputWrapper, { TextInputWrapperProps } from 'product_modules/components/TextInputWrapper/TextInputWrapper';
import { useTrimmedPhoneNumber } from './utils';
import useSamplePhoneNumber from 'product_modules/components/PhoneNumberInput/useSamplePhoneNumber';
import LoaderWithState, { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import countryCodeStyles from 'product_modules/components/CountryCodeSelect/CountryCodeSelect.module.scss';
import PhoneInput, { flags } from 'product_modules/components/PhoneInput';
import styles from 'product_modules/components/PhoneNumberInput/PhoneNumberInput.module.scss';
import clsx from 'clsx';
import TextInput from 'product_modules/components/TextInput';

export interface PhoneNumberInputProps extends Omit<TextInputWrapperProps<any>, 'children'> {
  value: string;
  country: string | null;
  onChange: (value: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  containerClassName?: string;
  withFlag?: boolean;
  inputIcon?: React.ReactNode;
  raw?: boolean;
  required?: boolean;
  name?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  autoComplete?: string;
}

const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(({
  value,
  country,
  onChange,
  onFocus,
  onBlur,
  readOnly,
  disabled,
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
  withFlag,
  inputIcon,
  raw = false,
  required,
  name,
  onKeyDown,
  autoComplete,
  ...wrapperProps
}, ref) => {
  const placeholder = useSamplePhoneNumber(country);

  const [trimmedValue, handleChange] = useTrimmedPhoneNumber(value, onChange, country);
  const Flag = withFlag && country && flags[country];

  const focusInputElement = ({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    currentTarget.querySelector('input')!.focus();
  };

  if (raw) {
    return (
      <TextInput
        value={value}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
        tabIndex={tabIndex}
        inputIcon={inputIcon}
        ref={ref}
        type="tel"
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        {...wrapperProps}
      />
    )
  }

  return (
    <TextInputWrapper required={required} {...wrapperProps} disabled={disabled}>
      <div className={clsx(styles.container, disabled && styles.container__disabled)} onClick={focusInputElement}>
        {withFlag && Flag && <Flag className={countryCodeStyles.flag} />}
        <PhoneInput
          className={styles.phoneNumberInput}
          value={trimmedValue}
          placeholder={placeholder}
          country={country}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          readOnly={readOnly}
          disabled={disabled}
          tabIndex={tabIndex}
          name={name}
          type="tel"
          ref={ref}
          onKeyDown={onKeyDown}
          autoComplete={autoComplete}
        />
        {showLoader && <LoaderWithState state={loaderState} onStateReset={onLoaderStateReset} />}
        {inputIcon && <div className={styles.inputIconContainer}>{inputIcon}</div>}
      </div>
    </TextInputWrapper>
  );
});

export default PhoneNumberInput;
