import React, { useMemo, useCallback, FocusEvent } from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import { VariableValue } from 'product_modules/api/LoanOriginationSystem/Types';
import InputWithDataType from 'product_modules/components/InputWithDataType';
import { isPhoneNumber, getVisualDataTypeWithAttributes } from 'product_modules/components/Variables/utils';
import variableOptionsListToSelectInputOptions from 'product_modules/utils/variableOptionsListToSelectInputOptions';
import { BaseConfigurableFormVariableField } from 'product_modules/components/ConfigurableForm/types';
import { LoaderState } from 'product_modules/components/LoaderWithState';
import CalculationsFunctionIcon from 'product_modules/components/CalculationsFunctionIcon';
import { PermissionGroupId } from 'product_modules/components/PermissionGroups/Types';
import { getInputModeFromVariableConfiguration, getMaskModeBasedOnPermissions } from './utils';
import styles from './ConfigurableFormVariable.module.scss';

export interface ConfigurableFormVariableProps<ConfigurableFormVariableFieldType extends BaseConfigurableFormVariableField> {
  className?: string;
  field: ConfigurableFormVariableFieldType;
  value: VariableValue;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  required?: boolean;
  tabIndex: number;
  userPermissionGroupId: PermissionGroupId;
  formatDisplayTitle?: (systemName: string, displayName: string) => string;
  onChange: (field: ConfigurableFormVariableFieldType, value: VariableValue) => void;
  onFocus?: (field: ConfigurableFormVariableFieldType, event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (field: ConfigurableFormVariableFieldType, event?: FocusEvent<HTMLInputElement>) => void;
  onLoaderStateReset?: (field: ConfigurableFormVariableFieldType) => void;
  visible?: boolean;
  isCalculated?: boolean;
}

const ConfigurableFormVariable = <ConfigurableFormVariableFieldType extends BaseConfigurableFormVariableField>({
  className,
  field,
  value,
  showLoader,
  loaderState,
  required,
  tabIndex,
  userPermissionGroupId,
  formatDisplayTitle,
  onChange,
  onFocus,
  onBlur,
  onLoaderStateReset,
  visible = true,
  isCalculated,
}: ConfigurableFormVariableProps<ConfigurableFormVariableFieldType>) => {
  const { variable } = field;

  const visualDataTypeWithAttributes = getVisualDataTypeWithAttributes(variable);

  const labelTitle = useMemo(() => {
    return formatDisplayTitle
      ? formatDisplayTitle(variable.systemName, variable.displayName)
      : variable.displayName;
  }, [formatDisplayTitle, variable]);

  const inputMode = useMemo(() => {
    return getInputModeFromVariableConfiguration(variable, userPermissionGroupId);
  }, [variable, userPermissionGroupId]);

  const maskMode = useMemo(() => {
    return getMaskModeBasedOnPermissions(visualDataTypeWithAttributes.dataType, variable, userPermissionGroupId)
  }, [variable, visualDataTypeWithAttributes.dataType, userPermissionGroupId]);

  const options = useMemo(() => {
    return variable.optionsList
      ? variableOptionsListToSelectInputOptions(variable.optionsList)
      : null
  }, [variable.optionsList]);

  const phoneNumberAttributes = isPhoneNumber(visualDataTypeWithAttributes) ? { phoneNumberWithFlag: true } : {};

  const handleChange = useCallback((updatedValue: VariableValue) => {
    onChange(field, updatedValue);
  }, [onChange, field]);

  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    onFocus?.(field, event);
  }, [onFocus, variable]);

  const handleBlur = useCallback((event?: FocusEvent<HTMLInputElement>) => {
    onBlur?.(field, event);
  }, [onBlur, field]);

  const handleLoaderStateReset = useCallback(() => {
    onLoaderStateReset?.(field);
  }, [onLoaderStateReset, field]);

  const inputIcon = useMemo(() => {
    if (!isCalculated) {
      return null;
    }

    return (
      <CalculationsFunctionIcon />
    );
  }, [isCalculated]);

  return (
    <Collapse in={visible}>
      <div className={clsx(styles.input, className)}>
        <InputWithDataType
          {...visualDataTypeWithAttributes}
          {...phoneNumberAttributes}
          {...maskMode}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onLoaderStateReset={handleLoaderStateReset}
          value={value}
          labelTitle={labelTitle}
          options={options}
          disabled={isCalculated || inputMode.disabled}
          tabIndex={tabIndex}
          required={!!required}
          showLoader={showLoader}
          loaderState={loaderState}
          inputIcon={inputIcon}
        />
      </div>
    </Collapse>
  );
};

export default React.memo(ConfigurableFormVariable) as typeof ConfigurableFormVariable;
