const getFileExtension = (name: string) => {
  const lastIndexOfDot = name.lastIndexOf('.');

  if (lastIndexOfDot < 0) {
    return name;

  }

  return name.slice(lastIndexOfDot + 1).toLocaleLowerCase();
};

export default getFileExtension;
