import { FC, useEffect, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { removeSnackTimer, showSnack } from 'handlers/snacksSlice';
import { SnackbarComponentTypes } from 'components/Snackbar/Types';
import {
  downloadAllDocuments,
  downloadApplicationDocument,
  getApplicationDocuments,
  setDocumentsSortingType,
} from 'handlers/documentsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import ApplicationDocumentsTab from 'components/ApplicationDetails/ApplicationDocumentsTab';
import { ApplicationDocumentsSortingType, IApplicationDocument } from 'api/digifi/los/DocumentsApi';
import { sortApplicationDocuments } from 'utils/sortFiles';

interface IApplicationDocumentsTabConnectorProps {
  applicationId: string;
  applicationDisplayId: string;
  documents: IApplicationDocument[];
  onApplicationDocumentClick: (document: IApplicationDocument) => void;
  onTabContentLoadingComplete: () => void;
  onTabClick?: () => void;
}

const ApplicationDocumentsTabConnector: FC<IApplicationDocumentsTabConnectorProps> = ({
  applicationId,
  applicationDisplayId,
  documents,
  onApplicationDocumentClick,
  onTabContentLoadingComplete,
  onTabClick,
}) => {
  const dispatch = useAppDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { sortingType } = useAppSelector(state => state.applicationDocuments);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadApplicationDocuments = async () => {
      setIsLoading(true);
      onTabClick?.();
      await dispatchWithUnwrap(getApplicationDocuments({ applicationId }));
      onTabContentLoadingComplete();
    };

    loadApplicationDocuments().finally(() => setIsLoading(false));

  }, [applicationId]);

  const sortedDocuments = useMemo(() => {
    return sortApplicationDocuments(documents, sortingType);
  }, [documents, sortingType]);

  const handleDocumentDownloadClick = async ({ id, name, extension }: IApplicationDocument) => {
    dispatch(showSnack({
      id,
      title: 'Downloading Document',
      content: {
        componentType: SnackbarComponentTypes.ApplicationDownloadDocumentComponent,
        props: {
          documentName: name,
          documentExtension: extension,
          documentDownloadingId: id,
        },
      },
    }));
    await dispatchWithUnwrap(downloadApplicationDocument({ documentId: id }));
    removeSnackTimer(id, dispatch);
  };

  const handleAllDocumentsDownloadClick = async () => {
    const documentDownloadingId = nanoid();

    dispatch(showSnack({
      id: documentDownloadingId,
      title: 'Downloading Document',
      content: {
        componentType: SnackbarComponentTypes.ApplicationDownloadDocumentsComponent,
        props: {
          documentName: `Application Documents - ${applicationDisplayId}`,
          documentDownloadingId,
        },
      },
    }));

    await dispatchWithUnwrap(downloadAllDocuments({ applicationId, documentId: documentDownloadingId }));
    removeSnackTimer(documentDownloadingId, dispatch);
  };

  const handleSort = (updatedSortingType: ApplicationDocumentsSortingType) => {
    dispatch(setDocumentsSortingType(updatedSortingType));
  };

  return (
    <>
      <ApplicationDocumentsTab
        sortingType={sortingType}
        documents={sortedDocuments}
        isLoading={isLoading}
        onAllDocumentsDownloadClick={handleAllDocumentsDownloadClick}
        onDocumentDownloadClick={handleDocumentDownloadClick}
        onDocumentClick={onApplicationDocumentClick}
        onSort={handleSort}
      />
    </>
  );
};

export default ApplicationDocumentsTabConnector;
