import React, { FC } from 'react';
import clsx from 'clsx';
import { SkeletonRectangle } from 'product_modules/components/Skeleton';
import styles from './SkeletonUploadFileStub.module.scss';

export interface SkeletonDraggableUploadFileStubProps {
  className?: string;
}

const SkeletonUploadFileStub: FC<SkeletonDraggableUploadFileStubProps> = ({ className }) => {
  return (
    <div className={clsx(styles.skeletonDraggableUploadFileStub, className)}>
      <SkeletonRectangle width="100px" height="16px" color="primary6" marginBottom="12px" />
      <div className={styles.dashedContainer}>
        <SkeletonRectangle width="100%" height="100%" color="primary6" />
      </div>
    </div>
  );
};

export default SkeletonUploadFileStub;
