import { FC } from 'react';
import LoaderWithState, { LoaderWithStateProps } from 'product_modules/components/LoaderWithState';
import clsx from 'clsx';
import { SPINNER_CLASS_NAME, StyleStyleComponent } from 'components/digifi-wrappers/Spinner/Spinner';

const LoaderWithStateStyled = StyleStyleComponent(LoaderWithState, 'child');

const LoaderWithStateWrapper: FC<LoaderWithStateProps> = (props) => {
  return (
    <LoaderWithStateStyled {...props} spinnerClassName={clsx(SPINNER_CLASS_NAME, props.spinnerClassName)} />
  );
};

export default LoaderWithStateWrapper;
