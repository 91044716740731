import TabSwitch, { TabSwitchProps } from 'product_modules/components/TabSwitch';
import styled from 'types/AppTheme';
import clsx from 'clsx';

const CONTAINER_CLASS_NAME = 'TabSwitchWrapper-container';
const CHECKED_TAB_CLASS_NAME = 'TabSwitchWrapper-checked-tab';

const TabSwitchStyled = styled(TabSwitch)`
  &.${CONTAINER_CLASS_NAME} {
    .${CHECKED_TAB_CLASS_NAME} {
      label {
        border-color: ${({ theme }) => theme.colors.accentColor}
      }
    }
  }
` as <TabSwitchOptionType,>(props: TabSwitchProps<TabSwitchOptionType>) => JSX.Element;

const TabSwitchWrapper = <TabSwitchOptionType,>(props: TabSwitchProps<TabSwitchOptionType>) => {
  return (
    <TabSwitchStyled<TabSwitchOptionType>
      {...props}
      className={clsx(props.className, CONTAINER_CLASS_NAME)}
      checkedTabClassName={clsx(props.checkedTabClassName, CHECKED_TAB_CLASS_NAME)}
      touchScroll
      hideBorder
    />
  );
};

export default TabSwitchWrapper;
