import React, { FC, useMemo } from 'react';
import styles from './UploadDocumentsReviewSection.module.scss';
import { IDocumentUploadFile } from 'components/ApplicationForm/forms/DocumentUploadForm/DocumentUploadForm';
import { IApplicationDocumentConfiguration } from 'api/digifi/los/CardsApi';
import InfoSection from 'components/common/InfoSection';
import sortByPosition from 'product_modules/utils/sortByPosition';
import useFullApplicationData from 'hooks/useFullApplicationData';
import useConditionalDisplayLogic from 'product_modules/hooks/useConditionalDisplayLogic';
import { FormLayoutData } from 'api/Types';
import SkeletonCardsForm from 'components/digifi-wrappers/SkeletonCardsForm';
import { DEFAULT_SKELETON_CARDS_LAYOUT } from 'components/digifi-wrappers/SkeletonCardsForm/SkeletonCardsForm';
import { iconsByExtension } from 'components/ApplicationDetails/DocumentsTableRow/DocumentsTableRow';
import { DocumentExtension } from 'api/digifi/los/DocumentsApi';
import getFileExtension from 'utils/getFileExtension';
import { FileIcon } from 'static/images';
import ButtonWithImage from 'components/digifi-wrappers/ButtonWithImage';
import OverflowedText from 'components/digifi-wrappers/OverflowedText';

interface IUploadDocumentsReviewSectionProps {
  applicationDocumentConfiguration: IApplicationDocumentConfiguration[];
  documentFiles: IDocumentUploadFile[];
  onUploadDocumentsEditClick: () => void;
  borrowerFormData: FormLayoutData;
  applicationDetailsFormData: FormLayoutData;
}

const UploadDocumentsReviewSection: FC<IUploadDocumentsReviewSectionProps> = ({
  applicationDocumentConfiguration,
  documentFiles,
  onUploadDocumentsEditClick,
  borrowerFormData,
  applicationDetailsFormData,
}) => {
  const fullApplicationData = useFullApplicationData({
    borrowerFormData,
    applicationDetailsFormData,
  });
  const displayFieldsAttributes =  useConditionalDisplayLogic(applicationDocumentConfiguration, fullApplicationData);;

  const sortedApplicationDocumentConfiguration = useMemo(() => {
    if (!applicationDocumentConfiguration || !applicationDocumentConfiguration.length) {
      return [];
    }

    return sortByPosition(applicationDocumentConfiguration)
      .filter(configuration => !!displayFieldsAttributes && displayFieldsAttributes[configuration.id]);
  }, [applicationDocumentConfiguration, displayFieldsAttributes]);

  const renderDocumentName = (name: string) => {
    if (!name) {
      return null;
    }

    const fileExtension = getFileExtension(name);

    return (
      <div className={styles.documentNameContainer}>
        {iconsByExtension.get(fileExtension as DocumentExtension) || <FileIcon />}
        <OverflowedText className={styles.documentName}>
          {name}
        </OverflowedText>
      </div>
    );
  };

  const documentTitleToFileMap = useMemo(() => {
    const map = new Map<string, JSX.Element | null>([]);

    documentFiles.forEach(file => {
      map.set(file.configurationName, renderDocumentName(file.actualFile.name));
    });

    return map;
  }, [documentFiles]);

  const desktopDocumentsColumns = useMemo(() => {
    const firstColumn = new Map<string, JSX.Element | null>([]);
    const secondColumn = new Map<string, JSX.Element | null>([]);

    sortedApplicationDocumentConfiguration.forEach((config, index) => {
      if (index < Math.ceil(sortedApplicationDocumentConfiguration.length / 2)) {
        firstColumn.set(config.name, documentTitleToFileMap.get(config.name) || null);
      } else {
        secondColumn.set(config.name, documentTitleToFileMap.get(config.name) || null);
      }
    });

    return [firstColumn, secondColumn];
  }, [sortedApplicationDocumentConfiguration]);

   const mobileDocumentsColumns = useMemo(() => {
     const column = new Map<string, JSX.Element | null>([]);

     sortedApplicationDocumentConfiguration.forEach((config) => {
       column.set(config.name, documentTitleToFileMap.get(config.name) || null);
     });

     return [column];
   }, [sortedApplicationDocumentConfiguration]);

  return (
    <div>
      <div className={styles.documentUploadTitleContainer}>
        <div className={styles.documentUploadSectionTitle}>Document Upload</div>
        <ButtonWithImage
          title="Edit Documents"
          kind="edit"
          className={styles.editDataButton}
          onClick={onUploadDocumentsEditClick}
          fillOnHover
          colorRemainsOnHover
        />
      </div>
      {!displayFieldsAttributes
        ? <SkeletonCardsForm layout={DEFAULT_SKELETON_CARDS_LAYOUT} />
        : (
          <>
            <div className={styles.infoSectionDesktop}>
              <InfoSection
                sectionContent={desktopDocumentsColumns}
                containerClassName={styles.documentsSectionContent}
                fieldClassName={styles.sectionField}
                fieldTitleClassName={styles.sectionTitle}
                fieldValueClassName={styles.sectionValue}
              />
            </div>
            <div className={styles.infoSectionMobile}>
              <InfoSection
                sectionContent={mobileDocumentsColumns}
                containerClassName={styles.documentsSectionContent}
                fieldClassName={styles.sectionField}
                fieldTitleClassName={styles.sectionTitle}
                fieldValueClassName={styles.sectionValue}
              />
            </div>
          </>

        )}
    </div>
  );
};

export default UploadDocumentsReviewSection;
