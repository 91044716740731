import { ICard } from 'types/CardTypes';
import sortByPosition from 'product_modules/utils/sortByPosition';
import { IBaseVariableConfiguration } from 'api/digifi/los/CardsApi';
import { useMemo } from 'react';
import { zip, flatten } from 'lodash';

const groupCardFieldsByColumns = <
  VariableConfigurationType extends IBaseVariableConfiguration,
  CardsType extends ICard<VariableConfigurationType>,
>(card: CardsType) => {
  return card.fields.reduce((accumulator, field) => {
    if (!(field.column.toString() in accumulator)) {
      accumulator[field.column.toString()] = [field];
    } else {
      accumulator[field.column.toString()].push(field);
    }

    return accumulator;
  }, {} as Record<string, VariableConfigurationType[]>);
};

const updateCardFields = <
  VariableConfigurationType extends IBaseVariableConfiguration,
  CardsType extends ICard<VariableConfigurationType>,
>(fields: VariableConfigurationType[], card: CardsType) => {
  const updatedFields = fields.map((field, index) => {
    return {
      ...field,
      column: 0,
      position: index,
    };
  });

  return {...card, fields: updatedFields};
};

const useMobileCards = <
  VariableConfigurationType extends IBaseVariableConfiguration,
  CardsType extends ICard<VariableConfigurationType>,
>(cards: CardsType[]) => {
  return useMemo(() => {
    return cards.map(card => {
     const fieldsByColumn = groupCardFieldsByColumns(card);
      const sortedFieldColumnsByPosition = Object.keys(fieldsByColumn).map(key => {
        return sortByPosition(fieldsByColumn[key]);
      });

      const zippedArrays = zip(...sortedFieldColumnsByPosition);
      const flattenArray = flatten(zippedArrays)
        .filter((item): item is VariableConfigurationType => !!item);

      return updateCardFields(flattenArray, card);
    });
  }, [cards]);
};

export default useMobileCards;
