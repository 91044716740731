import { FC } from 'react';
import TableRow from 'components/digifi-wrappers/TableRow';
import TableBodyCell from 'components/digifi-wrappers/TableBodyCell';
import SkeletonRectangle from 'components/digifi-wrappers/SkeletonRectangle';
import { ApplicationsTableColumnSizes } from 'components/ApplicationsList/ApplicationsTableRow/ApplicationsTableColumnSized';
import styles from './ApplicationsTable.module.scss';

const DESKTOP_TABLE_LENGTH = 10;
const MOBILE_TABLE_LENGTH = 5;

const SkeletonApplicationsTable: FC = () => {
  const renderDesktopTable = () => {
    return Array.from({ length: DESKTOP_TABLE_LENGTH }).map((item, index) => {
      return (
        <TableRow key={index} className={styles.tableRowDesktop} disableHover hasTopBorder={!index}>
          <TableBodyCell width={ApplicationsTableColumnSizes.ApplicationDate}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
          <TableBodyCell width={ApplicationsTableColumnSizes.ProductName}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
          <TableBodyCell width={ApplicationsTableColumnSizes.Id}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
          <TableBodyCell width={ApplicationsTableColumnSizes.LoanAmount}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
          <TableBodyCell width={ApplicationsTableColumnSizes.Status}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
          <TableBodyCell width={ApplicationsTableColumnSizes.StatusDate}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
        </TableRow>
      );
    });
  };

  const renderMobileTable = () => {
    return Array.from({ length: MOBILE_TABLE_LENGTH }).map((row, index) => {
      return (
        <TableRow key={index} className={styles.tableRowMobile} disableHover hasTopBorder={!index}>
          <TableBodyCell width={100} className={styles.tableCellMobile}>
            <div className={styles.skeletonCellTitle}>
              <SkeletonRectangle width="46%" color="primary20" height="16px" />
              <SkeletonRectangle width="80px" color="primary10" height="24px" />
            </div>
            <div className={styles.skeletonContent}>
              {Array.from({ length: 4 }).map((item, tableCellIndex) => {
                return (
                  <div key={tableCellIndex} className={styles.skeletonCellContent}>
                    <SkeletonRectangle width="27%" color="primary10" height="12px" />
                    <SkeletonRectangle width="49%" color="primary10" height="14px" />
                  </div>
                );
              })}
            </div>
          </TableBodyCell>
        </TableRow>
      );
    });
  };

  return (
    <>
      {renderDesktopTable()}
      {renderMobileTable()}
    </>
  );
};

export default SkeletonApplicationsTable;
