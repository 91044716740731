import { FC, useEffect, useState } from 'react';
import FilePreview from 'components/ApplicationDetails/FilePreview';
import { useParams } from 'react-router-dom';
import { applicationDocumentsApi } from 'handlers/documentsSlice';
import { DocumentExtension } from 'api/digifi/los/DocumentsApi';
import MainLayout from 'layout/MainLayout';
import { useAppSelector } from 'hooks/reduxHooks';

const FilePreviewPage: FC = () => {
  const { fileExtension, fileId, filename } = useParams<{
    fileExtension: DocumentExtension;
    fileId: string;
    filename: string;
  }>();
  const { selectedApplication } = useAppSelector(state => state.applications);
  const [previewLink, setPreviewLink] = useState<string>('');

  useEffect(() => {
    if (!fileId) {
      return;
    }

    (async () => {
      const link = await applicationDocumentsApi.getPublicLink(fileId);
      setPreviewLink(link);
    })();
  }, []);

  return (
    <MainLayout>
      <FilePreview
        previewLink={previewLink || ''}
        filename={filename || ''}
        fileExtension={fileExtension || DocumentExtension.Pdf}
        fileId={fileId || ''}
        applicationId={selectedApplication?.displayId}
      />
    </MainLayout>
  );
};

export default FilePreviewPage;
