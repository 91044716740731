import React, { ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import styles from './PopUpCore.module.scss';
import { CSSTransition } from 'react-transition-group';
import FocusLock from 'react-focus-lock';

interface PopUpProps {
  children: ReactNode;
  className?: string;
  warningText?: string;
  sectionClassName?: string;
}

const PopUpCore = forwardRef<HTMLElement, PopUpProps>(({
  children,
  className,
  warningText,
  sectionClassName,
}, ref) => {
  return (
    <CSSTransition
      in
      appear
      timeout={Number(styles.animationTimeout)}
      classNames={{
        appear: styles.popupAppear,
        appearActive: styles.popupAppearActive,
        appearDone: styles.popupAppearDone,
      }}
    >
      <div className={clsx(styles.popupCore, className)}>
        <FocusLock className={styles.focusLockContainer} autoFocus={false}>
          <section className={clsx(styles.popupMain, sectionClassName)} ref={ref}>
            {children}
            {warningText && (
              <p className={styles.warningText}>
                <span>Warning: </span>
                {warningText}
              </p>
            )}
          </section>
        </FocusLock>
      </div>
    </CSSTransition>
  );
});

export default PopUpCore;
