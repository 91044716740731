import { FC, useState } from 'react';
import TableHead from 'components/digifi-wrappers/TableHead';
import TableHeadCell from 'components/digifi-wrappers/TableHeadCell';
import TableBody from 'components/digifi-wrappers/TableBody';
import SkeletonTasksTable from 'components/ApplicationDetails/TasksTable/SkeletonTasksTable';
import { TasksTableColumnSizes } from 'components/ApplicationDetails/TasksTableRow/TasksTableColumnSizes';
import { ITask, TasksSortingField, TasksSortingType, TaskStatus } from 'api/digifi/los/TasksApi';
import TasksTableRow from 'components/ApplicationDetails/TasksTableRow/TasksTableRow';
import SendResponsePopUp from 'components/ApplicationDetails/PopUps/SendResponsePopUp/SendResponsePopUp';
import UploadDocumentPopUp from 'components/ApplicationDetails/PopUps/UploadDocumentPopUp';
import MarkAsDonePopUp from 'components/ApplicationDetails/PopUps/MarkAsDonePopUp';
import { createTaskComment, updateApplicationTask } from 'handlers/tasksSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import styles from './TasksTable.module.scss';
import EmptyTable from 'components/ApplicationDetails/EmptyTable';
import { ApplicationTabType } from 'components/ApplicationDetails/ApplicationTabs/ApplicationTabs';
import TableRow from 'components/digifi-wrappers/TableRow';
import useSorting from 'product_modules/hooks/useSorting';
import Table from 'components/digifi-wrappers/Table';
import { createNotification } from 'handlers/notificationsSlice';
import { useAppDispatch } from 'hooks/reduxHooks';

interface ITasksTableProps {
  tasks: ITask[];
  applicationId: string;
  sortingType: TasksSortingType;
  onTasksSort: (field: TasksSortingField, ascending: boolean) => void;
  isLoading?: boolean;
}

const TasksTable: FC<ITasksTableProps> = ({
  tasks,
  applicationId,
  isLoading,
  onTasksSort,
  sortingType,
}) => {
  const [showCreateCommentPopUp, setShowCreateCommentPopUp] = useState<ITask | null>(null);
  const [showUploadDocumentPopUp, setShowUploadDocumentPopUp] = useState<ITask | null>(null);
  const [showMarkAsDonePopUp, setMarkAsDonePopUp] = useState<ITask | null>(null);
  const [isSendingTaskCommentInProgress, setIsSendingTaskCommentInProgress] = useState<boolean>(false);
  const [isSubmittingTaskInProgress, setIsSubmittingTaskInProgress] = useState<boolean>(false);
  const [undoingTaskInProgress, setIsUndoingTaskInProgress] = useState<Record<string, boolean>>({});
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onTasksSort);
  const dispatch = useAppDispatch();

  const renderTableBody = () => {
    if (isLoading) {
      return <SkeletonTasksTable />;
    }

    if (!tasks.length) {
      return <EmptyTable type={ApplicationTabType.TasksTab} />;
    }

    return (
      <TableBody>
        {tasks.map((task, index) => (
          <TableRow key={task.id} disableHover hasTopBorder={!index}>
            <TasksTableRow
              key={task.id}
              task={task}
              onUploadIconClick={() => setShowUploadDocumentPopUp(task)}
              onSendCommentIconClick={() => setShowCreateCommentPopUp(task)}
              onCheckmarkIconClick={() => setMarkAsDonePopUp(task)}
              onUndoIconClick={handleTaskUndo}
              undoingTaskInProgress={undoingTaskInProgress}
            />
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const handleComplete = async () => {
    try {
      setIsSubmittingTaskInProgress(true);
      await dispatchWithUnwrap(updateApplicationTask({ id: showMarkAsDonePopUp?.id || '', status: TaskStatus.InReview }));
      setMarkAsDonePopUp(null);
      createNotification({
        notification: 'Your task has been sent to review.',
        type: 'success',
        dispatch,
      });
    } catch(error) {
      createNotification({
        notification: error.message,
        type: 'error',
        dispatch,
      });
    } finally {
      setIsSubmittingTaskInProgress(false);
    }
  };

  const handleTaskUndo = async (id: string) => {
    try {
      setIsUndoingTaskInProgress({ [id]: true });
      await dispatchWithUnwrap(updateApplicationTask({ id, status: TaskStatus.NotDone }));
    } catch(error) {
      createNotification({
        notification: error.message,
        type: 'error',
        dispatch,
      });
    } finally {
      const updated = { ...undoingTaskInProgress };
      delete undoingTaskInProgress[id];
      setIsUndoingTaskInProgress(updated);
    }
  };

  const handleCreateTaskComment = async (commentMessage: string) => {
    try {
      setIsSendingTaskCommentInProgress(true);
      await dispatchWithUnwrap(createTaskComment({ taskId: showCreateCommentPopUp?.id || '', message: commentMessage }));
      setShowCreateCommentPopUp(null);
      createNotification({
        notification: 'Your response has been submitted successfully.',
        type: 'success',
        dispatch,
      });
    } catch(error) {
      createNotification({
        notification: error.message,
        type: 'error',
        dispatch,
      });
    } finally {
      setIsSendingTaskCommentInProgress(false);
    }
  };

  return (
    <>
      <Table>
        <TableHead className={styles.tableHead}>
          <TableHeadCell
            width={TasksTableColumnSizes.Description}
            ascending={!isLoading && tasks?.length ? getSortingType(TasksSortingField.Description) : undefined}
            onClick={!isLoading && tasks?.length ? () => changeSorting(TasksSortingField.Description) : undefined}
          >
            Description
          </TableHeadCell>
          <TableHeadCell
            width={TasksTableColumnSizes.Status}
            ascending={!isLoading && tasks?.length ? getSortingType(TasksSortingField.Status) : undefined}
            onClick={!isLoading && tasks?.length ? () => changeSorting(TasksSortingField.Status) : undefined}
          >
            Status
          </TableHeadCell>
          <TableHeadCell
            width={TasksTableColumnSizes.DueDate}
            ascending={!isLoading && tasks?.length ? getSortingType(TasksSortingField.DueDate) : undefined}
            onClick={!isLoading && tasks?.length ? () => changeSorting(TasksSortingField.DueDate) : undefined}
          >
            Due Date
          </TableHeadCell>
          <TableHeadCell width={TasksTableColumnSizes.Actions}>
            Actions
          </TableHeadCell>
        </TableHead>
        {renderTableBody()}
      </Table>
      {showCreateCommentPopUp && (
        <SendResponsePopUp
          onClose={() => setShowCreateCommentPopUp(null)}
          onCreate={handleCreateTaskComment}
          isSendingResponseInProgress={isSendingTaskCommentInProgress}
        />
      )}
      {showUploadDocumentPopUp && (
        <UploadDocumentPopUp
          onClose={() => setShowUploadDocumentPopUp(null)}
          applicationId={applicationId}
          taskId={showUploadDocumentPopUp.id}
        />
      )}
      {showMarkAsDonePopUp && (
        <MarkAsDonePopUp
          onClose={() => setMarkAsDonePopUp(null)}
          onComplete={handleComplete}
          isLoading={isSubmittingTaskInProgress}
        />
      )}
    </>
  );
};

export default TasksTable;
