import React, { ReactNode, RefObject, useEffect, useLayoutEffect, useState, useCallback } from 'react';
import Portal from 'product_modules/components/Portal';
import styles from './DropdownContentWithPortal.module.scss';
import { useDynamicResize } from 'product_modules/components/DynamicSize';
import useWindowSize from 'product_modules/hooks/useWindowSize';

interface DropdownContentWithPortalProps<HandlerRef> {
  children: ReactNode;
  handlerRef: RefObject<HandlerRef>;
  dropdownRef: RefObject<HTMLDivElement>;
}

interface ChildPosition {
  left: number;
  top: number;
  width: number;
}

const DropdownContentWithPortal = <HandlerRef extends HTMLElement>({
  children,
  handlerRef,
  dropdownRef,
}: DropdownContentWithPortalProps<HandlerRef>) => {
  const windowSize = useWindowSize();

  const [childPosition, setChildPosition] = useState<ChildPosition>({
    top: 0,
    left: 0,
    width: handlerRef.current?.clientWidth ?? 0,
  });

  const repositionChild = useCallback(() => {
    if (!children || !handlerRef.current || !dropdownRef?.current || windowSize.height === undefined) return;

    const { top, left, width: anchorWidth, height: anchorHeight } = handlerRef.current.getBoundingClientRect();

    const newTopPosition = top + anchorHeight;
    const topOffset =
      newTopPosition + dropdownRef.current.clientHeight > windowSize.height
        ? dropdownRef.current.clientHeight + anchorHeight
        : 0;

    setChildPosition({
      left,
      top: newTopPosition - topOffset,
      width: anchorWidth,
    });
  }, [handlerRef.current, children, dropdownRef?.current, windowSize]);

  useLayoutEffect(repositionChild, [repositionChild]);

  useDynamicResize(repositionChild);

  useEffect(() => {
    window.addEventListener('scroll', repositionChild, true);
    window.addEventListener('resize', repositionChild, true);

    return () => {
      window.removeEventListener('scroll', repositionChild, true);
      window.removeEventListener('resize', repositionChild, true);
    };
  }, [repositionChild]);

  return (
    <Portal>
      <div className={styles.contentContainer} style={childPosition}>
        {children}
      </div>
    </Portal>
  );
};

export default DropdownContentWithPortal;
