import { AppRoute } from 'enums/AppRoute';

export const AppRouteWithParams = {
  SignIn: AppRoute.SignIn,
  CreateAccount: AppRoute.CreateAccount,
  AcceptInvite: `${AppRoute.AcceptInvite}/:token`,
  PhoneVerification: AppRoute.PhoneVerification,
  ResetPassword: AppRoute.ResetPassword,
  CreateNewPassword: `${AppRoute.ResetPassword}/:resetPasswordToken`,
  ConfirmEmail: AppRoute.ConfirmEmail,
  ResumeProcess: AppRoute.ResumeProcess,
  DocumentPreview: `${AppRoute.DocumentPreview}/:fileExtension/:fileId/:filename`,
  Application: `${AppRoute.ResumeProcess}/:applicationDisplayId`,
  Forbidden: AppRoute.Forbidden,
};
