import { useMemo } from 'react';
import Graph from 'graph-data-structure';
import { IBaseCalculation } from 'api/Types';

const useOrderedCalculations = <CalculationType extends IBaseCalculation>(calculations: CalculationType[] | null) => {
  return useMemo(() => {
    if (!calculations) {
      return null;
    }

    const graph = Graph();

    calculations.forEach((calculation) => {
      graph.addNode(calculation.variable.systemName);

      calculation.requiredVariables.forEach((variable) => {
        graph.addNode(variable);

        graph.addEdge(variable, calculation.variable.systemName);
      });
    });

    const calculationsRunOrder = graph.topologicalSort();

    return [...calculations].sort(({ variable: firstVariable }, { variable: secondVariable }) => {
      return (
        calculationsRunOrder.indexOf(firstVariable.systemName) - calculationsRunOrder.indexOf(secondVariable.systemName)
      );
    });
  }, [calculations]);
};

export default useOrderedCalculations;
