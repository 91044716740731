import { FC, useEffect, useMemo, useState } from 'react';
import OutstandingTasksTab from 'components/ApplicationDetails/OutstandingTasksTab';
import { ITask, TasksSortingType } from 'api/digifi/los/TasksApi';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { sortApplicationTasks } from 'utils/sortTasks';
import { getApplicationTasks, setTasksSortingType } from 'handlers/tasksSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import TabContent from 'components/ApplicationDetails/TabContent';
import { SkeletonTasksTable } from 'components/ApplicationDetails/TasksTable';

interface IOutstandingTasksTabConnectorProps {
  tasks: ITask[];
  applicationId: string;
  onTabContentLoadingComplete: () => void;
  isModalLoading?: boolean;
  isLoading?: boolean;
  onTabClick?: () => void;
}

const OutstandingTasksTabConnector: FC<IOutstandingTasksTabConnectorProps> = ({
  tasks,
  applicationId,
  onTabContentLoadingComplete,
  isModalLoading,
  onTabClick,
  ...props
}) => {
  const { sortingType } = useAppSelector(state => state.tasks);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  useEffect(() => {
    const loadOutstandingTasks = async () => {
      setIsLoading(true);
      onTabClick?.();
      await dispatchWithUnwrap(getApplicationTasks({ applicationId }));
      onTabContentLoadingComplete();
    };

    loadOutstandingTasks().finally(() => setIsLoading(false));

  }, [applicationId]);

  const sortedTasks = useMemo(() => {
    return sortApplicationTasks(tasks, sortingType);
  }, [tasks, sortingType]);

  const handleSort = (updatedSortingType: TasksSortingType) => {
    dispatch(setTasksSortingType(updatedSortingType));
  };

  const renderTabTitle = () => {
    return (
      <SkeletonText width="220px" height="16px" color="primary20" lineHeight="28px" />
    );
  };

  const renderSkeleton = () => {
    return (
      <TabContent tabTitle={renderTabTitle()}>
        <SkeletonTasksTable />
      </TabContent>
    );
  };

  return (
    <>
      {isModalLoading ? renderSkeleton() : (
        <OutstandingTasksTab
          tasks={sortedTasks}
          sortingType={sortingType}
          onSort={handleSort}
          applicationId={applicationId}
          isLoading={isLoading}
          {...props}
        />
      )}
    </>
  );
};

export default OutstandingTasksTabConnector;
