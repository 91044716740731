import { UserInfo } from 'product_modules/api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { Label } from 'product_modules/api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { ViewType } from 'product_modules/components/LoanOriginationSystemApplications/Types';
import { SimplifiedApplication } from 'product_modules/api/LoanOriginationSystem/SimplifiedApplicationsApi';
import { AddressValue } from 'product_modules/api/Types';

export interface DataViewSortingType<Field = string> {
  field: Field;
  ascending: boolean;
}

export type ApplicationsSortingType = DataViewSortingType<ApplicationSortingField>;

export interface ChangeSortingTypePayload extends ApplicationsSortingType {
  viewType: ViewType;
}

export enum ApplicationSortingField {
  BorrowerFullName = 'borrowerFullName',
  DisplayId = 'displayId',
  CreatedAt = 'createdAt',
  Status = 'status',
  BorrowerPhoneNumber = 'borrowerPhoneNumber',
  BorrowerEmail = 'borrowerEmail',
  LoanAmount = 'loanAmount',
  ProductName = 'productName',
  IntermediaryName = 'intermediaryName',
  UpdatedAt = 'updatedAt',
  SearchRelevance = 'searchRelevance',
}

export interface ColumnProductDataFilter {
  search: string;
  members: UserInfo[];
  statuses: string[];
  labels: Label[];
  intermediaries: UserInfo[];
  createdDateRange: DateRange;
}

export interface TableViewData<Item> {
  items: Item[];
  total: number;
}

export type GetTableViewProductDataResult = TableViewData<SimplifiedApplication>;

export interface DataFilter {
  offset: number;
  count: number;
  search?: string;
}

export interface DateRange {
  from: Date | null;
  to: Date | null;
}

export type VariableValue = number | string | boolean | AddressValue | null | undefined;

export type FormLayoutData = Record<string, VariableValue>;
export type FormLayout<Item> = Array<Array<Item>>;

export interface SearchHighlight {
  score: number;
  path: string;
  texts: {
    value: string;
    type: string;
  }[];
}
