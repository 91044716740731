import BaseApi from 'api/BaseApi';

export interface IProduct {
  id: string;
  name: string;
  applicationFormPages: string[];
}

interface IProductsApi {
  getAllActiveProductNames(): Promise<IProduct[]>;
}

export default class ProductsRestApi extends BaseApi implements IProductsApi {
  public getAllActiveProductNames(): Promise<IProduct[]> {
    return this.fetch('/products');
  }
}
