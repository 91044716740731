import { FC, useRef } from 'react';
import EmailInput from 'components/digifi-wrappers/EmailInput';
import PhoneNumberInput from 'components/digifi-wrappers/PhoneNumberInput';
import PasswordInput from 'components/digifi-wrappers/PasswordInput';
import { useAppSelector } from 'hooks/reduxHooks';
import { useFormik } from 'formik';
import { BorrowerType } from 'enums/BorrowerType';
import { validateEmail, validatePassword, validatePhoneNumber } from 'validation';
import getMessage, { MessageType } from 'constants/Messages';
import BorrowerNamePerson from 'components/Auth/forms/BorrowerNamePerson';
import BorrowerNameCompany from 'components/Auth/forms/BorrowerNameCompany';
import { CreateAccountFormType } from 'types';
import SubmitButton from 'components/common/SubmitButton';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';

interface ICreateAccountFormProps {
  handleSubmit: (values: CreateAccountFormType) => Promise<void>;
  isLoading?: boolean;
}

const CreateAccountForm: FC<ICreateAccountFormProps> = ({ handleSubmit, isLoading }) => {
  const { borrowerType } = useAppSelector((state) => state.settings.portalConfiguration);
  const { phoneNumberFormat } = useAppSelector((state) => state.settings.variablesSettings);

  const { inviteData } = useAppSelector(state => state.auth);

  const emailInputRef = useRef<HTMLInputElement>(null);
  const phoneNumberInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const { values, errors, handleChange, setFieldValue, setFieldError } = useFormik({
    initialValues: {
      firstName: inviteData?.firstName || '',
      lastName: inviteData?.lastName || '',
      companyName: inviteData?.companyName || '',
      email: inviteData?.email || '',
      phoneNumber: inviteData?.phone || '',
      password: '',
    },
    onSubmit: handleSubmit,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const clearFieldErrorOnFocus = (fieldName: string) => {
    setFieldError(fieldName, '');
  };

  const handleBlur = (variable: string, messageType: MessageType, value: string) => {
    const trimmedValue = value.trim();

    setFieldValue(variable, trimmedValue);
    setFieldError(variable as string, trimmedValue.length > 0 ? '' : getMessage(messageType));
  };

  const handleEmailBlur = (currentEmail: string) => {
    const trimmedEmail = currentEmail.trim();

    const error = validateEmail(trimmedEmail);
    setFieldValue('email', trimmedEmail);
    setFieldError('email', error === null ? '' : getMessage(error));
  };

  const handlePasswordBlur = (currentPassword: string) => {
    const error = validatePassword(currentPassword);
    setFieldError('password', error === null ? '' : getMessage(error));
  };

  const handlePhoneBlur = (currentPhone: string) => {
    const error = validatePhoneNumber(currentPhone);
    setFieldError('phoneNumber', error === null ? '' : getMessage(error));
  };

  const isNameFilled = borrowerType === BorrowerType.Person
    ? !!values.firstName.length && !!values.lastName
    : !!values.companyName;

  const isButtonEnabled = isNameFilled
    && !validatePhoneNumber(values.phoneNumber)
    && !validateEmail(values.email)
    && !validatePassword(values.password);

  const renderBorrowerName = () => {
    return (
      <>
        {borrowerType === BorrowerType.Person && (
          <BorrowerNamePerson
            clearFieldErrorOnFocus={clearFieldErrorOnFocus}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            onChange={handleChange}
            isLoading={isLoading}
            onKeyDown={TriggerEventOnEnterKeyDown(emailInputRef, 'focus')}
          />
        )}
        {borrowerType === BorrowerType.Company && (
          <BorrowerNameCompany
            clearFieldErrorOnFocus={clearFieldErrorOnFocus}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            onChange={handleChange}
            isLoading={isLoading}
            onKeyDown={TriggerEventOnEnterKeyDown(emailInputRef, 'focus')}
          />
        )}
      </>
    );
  };

  return (
    <div>
      {renderBorrowerName()}
      <EmailInput
        labelTitle="Email"
        onChange={(value) => setFieldValue('email', value)}
        onFocus={() => clearFieldErrorOnFocus('email')}
        onBlur={() => handleEmailBlur(values.email)}
        value={values.email || ''}
        errorMessage={errors.email || ''}
        name="email"
        disabled={isLoading || !!inviteData?.email}
        required
        ref={emailInputRef}
        onKeyDown={TriggerEventOnEnterKeyDown(phoneNumberInputRef, 'focus')}
        autoComplete="username email"
      />
      <PhoneNumberInput
        withFlag
        labelTitle="Phone Number"
        value={values.phoneNumber}
        onChange={(value) => setFieldValue('phoneNumber', value)}
        onFocus={() => clearFieldErrorOnFocus('phoneNumber')}
        onBlur={() => handlePhoneBlur(values.phoneNumber)}
        name="phoneNumber"
        required
        country={phoneNumberFormat}
        errorMessage={errors.phoneNumber}
        disabled={isLoading}
        ref={phoneNumberInputRef}
        onKeyDown={TriggerEventOnEnterKeyDown(passwordInputRef, 'focus')}
        autoComplete="off"
      />
      <PasswordInput
        name="password"
        labelTitle="Password"
        required
        value={values.password}
        onChange={(event) => setFieldValue('password', event.target.value)}
        onBlur={() => handlePasswordBlur(values.password)}
        onFocus={() => clearFieldErrorOnFocus('password')}
        errorMessage={errors.password}
        disabled={isLoading}
        ref={passwordInputRef}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
        autoComplete="new-password"
      />
      <SubmitButton
        title="Create Account"
        isButtonEnabled={isButtonEnabled}
        onSubmit={() => handleSubmit(values)}
        ref={submitButtonRef}
      />
    </div>
  );
};

export default CreateAccountForm;
