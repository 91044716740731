import React, { FocusEvent } from 'react';
import { FormLayoutData, VariableValue } from 'product_modules/api/LoanOriginationSystem/Types';
import useFormLayout from 'product_modules/components/ConfigurableForm/useFormLayout';
import { LoaderState } from 'product_modules/components/LoaderWithState';
import { BaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { Card } from 'product_modules/api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseCalculation } from 'product_modules/api/LoanOriginationSystem/Base/CalculationsApi';
import SkeletonCardsLayout from 'product_modules/components/CardsLayoutConfiguration/SkeletonCardsLayout';
import { DEFAULT_SKELETON_CARDS_LAYOUT } from 'product_modules/components/CardsLayoutConfiguration';
import WithFieldsValidationButton from 'product_modules/components/WithFieldsValidationButton';
import Button from 'product_modules/components/Button';
import ButtonWithImage from 'product_modules/components/ButtonWithImage';
import NoEditPermissionsTooltip from 'product_modules/components/NoEditPermissionsTooltip';
import CardsRow from './CardsRow';
import SkeletonCardsForm from './SkeletonCardsForm';
import styles from './CardsForm.module.scss';
import clsx from 'clsx';

export interface CardsFormProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
  > {
  cards: CardType[] | null;
  data: FormLayoutData;
  isEditMode?: boolean;
  loaderStateById?: Record<string, LoaderState | null | undefined>;
  onFieldChange: (variableConfiguration: VariableConfigurationType, value: VariableValue) => void;
  onFieldFocus?: (variableConfiguration: VariableConfigurationType, event: FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (variableConfiguration: VariableConfigurationType, event?: FocusEvent<HTMLInputElement>) => void;
  onLoaderStateReset?: (variableConfiguration: VariableConfigurationType) => void;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  onSwitchEditMode?: () => void;
  isSwitchEditModeButtonDisabled?: boolean;
  switchEditModeButtonWrapperText?: string;
  title?: string;
  switchEditModeButton?: string;
  className?: string;
  displayHeader?: boolean;
  columnsPerCards?: number;
  skeletonCardsLayout?: Array<Array<number>>;
  isSaveChangesButtonDisabled?: boolean;
  isUpdatingInProgress?: boolean;
  areFieldsInvalid?: boolean;
  onSaveChangesClick?: () => void;
  displayFieldsAttributes?: Record<string, boolean>;
  displaySkeleton?: boolean;
  calculations?: CalculationType[] | null;
  editButtonClassName?: string;
  cardsFormHeaderClassName?: string;
  fieldClassName?: string;
  fieldTitleClassName?: string;
  fieldValueClassName?: string;
  hiddenFieldClassName?: string;
}

const CardsForm = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
  >({
  title,
  cards,
  data,
  isEditMode,
  onFieldChange,
  onFieldBlur,
  onFieldFocus,
  onLoaderStateReset,
  loaderStateById,
  formatVariableConfigurationDisplayTitle,
  onSwitchEditMode,
  isSwitchEditModeButtonDisabled = false,
  switchEditModeButtonWrapperText,
  switchEditModeButton,
  displayHeader = true,
  className,
  columnsPerCards,
  skeletonCardsLayout = DEFAULT_SKELETON_CARDS_LAYOUT,
  isSaveChangesButtonDisabled,
  isUpdatingInProgress,
  onSaveChangesClick,
  areFieldsInvalid,
  displayFieldsAttributes,
  displaySkeleton,
  calculations,
  editButtonClassName,
  cardsFormHeaderClassName,
  fieldClassName,
  fieldTitleClassName,
  fieldValueClassName,
  hiddenFieldClassName,
}: CardsFormProps<VariableConfigurationType, CardType, CalculationType>) => {
  const cardsLayout = useFormLayout(cards);

  const renderForm = () => {
    if (!cardsLayout || displaySkeleton) {
      return isEditMode
        ? <SkeletonCardsLayout cardClassName={styles.skeletonCard} maxColumnsPerCard={columnsPerCards} layout={skeletonCardsLayout} />
        : <SkeletonCardsForm maxColumnsPerCard={columnsPerCards} layout={skeletonCardsLayout} />;
    }

    return (
      <>
        {
          cardsLayout.map((cardsInRow, index) => (
          <CardsRow
            onLoaderStateReset={onLoaderStateReset}
            loaderStateById={loaderStateById}
            key={index}
            cards={cardsInRow}
            data={data}
            onFieldChange={onFieldChange}
            onFieldFocus={onFieldFocus}
            onFieldBlur={onFieldBlur}
            isEditMode={isEditMode}
            formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
            columnsPerCards={columnsPerCards}
            rowIndex={index}
            displayFieldsAttributes={displayFieldsAttributes}
            calculations={calculations}
            fieldClassName={fieldClassName}
            fieldTitleClassName={fieldTitleClassName}
            fieldValueClassName={fieldValueClassName}
            hiddenFieldClassName={hiddenFieldClassName}
          />
        ))}
      </>
    );
  };

  const renderHeaderButton = () => {
    if (isEditMode) {
      return (
        <div className={styles.editModeActions}>
          <Button className={styles.closeEditorButton} kind="secondary" onClick={() => onSwitchEditMode?.()}>
            Close Editor
          </Button>
          <WithFieldsValidationButton
            kind="primary"
            className={styles.saveChangesButton}
            disabled={isSaveChangesButtonDisabled}
            onClick={onSaveChangesClick}
            isLoading={isUpdatingInProgress}
            areFieldsInvalid={areFieldsInvalid}
          >
            Save Changes
          </WithFieldsValidationButton>
        </div>
      );
    }

    return (
      <NoEditPermissionsTooltip
        shouldShowTooltip={isSwitchEditModeButtonDisabled}
        statusName={switchEditModeButtonWrapperText}
      >
        <ButtonWithImage
          className={clsx(styles.editDataButton, editButtonClassName)}
          disabled={!cardsLayout || isSwitchEditModeButtonDisabled}
          title={switchEditModeButton || 'Edit Data'}
          kind="edit"
          onClick={() => onSwitchEditMode?.()}
        />
      </NoEditPermissionsTooltip>
    );
  };

  return (
    <div className={className}>
      {displayHeader && <div className={clsx(styles.cardsFormHeader, cardsFormHeaderClassName)}>
        <h4>{title}</h4>
        {renderHeaderButton()}
      </div>}
      {renderForm()}
    </div>
  );
};

export default CardsForm;
