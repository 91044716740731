import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import styled, { IAppTheme } from 'types/AppTheme';
import ModalOverlay, { IModalOverlayProps } from 'components/common/ModalOverlay/ModalOverlay';
import clsx from 'clsx';
import { getBrandColorBackgroundWithPatternStyles } from 'components/BrandingHelpers/ContainerStyled';
import { StyledComponent } from 'styled-components';

const FORM_HEADER_CONTAINER_CLASS_NAME = 'ModalOverlayConnected-header-container';

const ModalOverlayStyled = styled(ModalOverlay)`
  .${FORM_HEADER_CONTAINER_CLASS_NAME} {
    background: ${({ theme }) => getBrandColorBackgroundWithPatternStyles(theme.colors.brandColor)};
  }
` as StyledComponent<ForwardRefExoticComponent<IModalOverlayProps & RefAttributes<HTMLElement>>, IAppTheme, {}, never>;

const ModalOverlayConnected = forwardRef<HTMLElement, IModalOverlayProps>(({ headerClassName, ...props }, ref) => {
  return (
    <ModalOverlayStyled
      {...props}
      ref={ref}
      headerClassName={clsx(headerClassName, FORM_HEADER_CONTAINER_CLASS_NAME)}
    />
  );
});

export default ModalOverlayConnected;
