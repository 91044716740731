import { FieldComponent } from 'product_modules/api/Types';
import { buildFullAddressString } from 'product_modules/components/AddressInput/buildAddressString';

export const inputAddressComponents: FieldComponent[] = [
  {
    id: 'street_number',
    label: 'Street Number',
    required: true,
    visualAttributes: {
      dataType: 'Text',
    },
  }, {
    id: 'street_name',
    label: 'Street Name',
    required: true,
    visualAttributes: {
      dataType: 'Text',
    },
  }, {
    id: 'sublocality',
    label: 'Sublocality',
    required: true,
    visualAttributes: {
      dataType: 'Text',
    },
  }, {
    id: 'city',
    label: 'City',
    required: true,
    visualAttributes: {
      dataType: 'Text',
    },
  }, {
    id: 'state_or_province',
    label: 'State Or Province',
    required: true,
    visualAttributes: {
      dataType: 'Text',
    },
  }, {
    id: 'zip_or_postal_code',
    label: 'Zip Or Postal Code',
    required: true,
    visualAttributes: {
      dataType: 'Text',
    },
  }, {
    id: 'country',
    label: 'Country',
    required: true,
    visualAttributes: {
      dataType: 'Text',
    },
  },
];

export const outputAddressComponents: FieldComponent[] = [
  {
    id: 'full_address',
    label: 'Full Address',
    visualAttributes: {
      dataType: 'Text',
    },
    transform: (value) => {
      return typeof value === 'object' ? buildFullAddressString(value) : '';
    },
  },
  ...inputAddressComponents,
];

