import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { CreateAccountFormType } from 'types';
import { BorrowerType } from 'enums/BorrowerType';

export interface IAuthResponse {
  accessToken: string;
  refreshToken: string;
}

interface IGetInviteInfoResponseParams {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string;
  borrowerType: BorrowerType;
}

export interface IInvitesApi {
  acceptInvite(body: CreateAccountFormType, token: string): Promise<IAuthResponse>;
  getInviteInfo(token: string): Promise<IGetInviteInfoResponseParams>;
}

export default class InvitesRestApi extends BaseApi implements IInvitesApi {
  public acceptInvite(body: CreateAccountFormType, token: string): Promise<IAuthResponse> {
    return this.fetch(`/invites/${token}`, {
      method: FetchMethodType.POST,
      body,
    });
  };

  public getInviteInfo(token: string): Promise<IGetInviteInfoResponseParams> {
    return this.fetch(`/invites/${token}`);
  }
}
