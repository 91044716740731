import { FC } from 'react';
import styles from './OutstandingTasksInfo.module.scss';
import SkeletonRectangle from 'components/digifi-wrappers/SkeletonRectangle';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';

const SkeletonOutstandingTasksInfo: FC = () => {
  return (
    <div className={styles.tasksInfoContainer}>
      <div className={styles.infoIconContainerSkeleton} />
      <div className={styles.textContainer}>
        <div className={styles.tasksInfoTitle}>
          <SkeletonText width="200px" height="14px" color="primary10" lineHeight="20px"/>
        </div>
        <div className={styles.tasksInfoSubtitle}>
          <SkeletonRectangle width="280px" height="14px" color="primary10" />
        </div>
      </div>
    </div>
  );
};

export default SkeletonOutstandingTasksInfo;
