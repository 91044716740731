import { FC } from 'react';
import SkeletonRectangle from 'components/digifi-wrappers/SkeletonRectangle';
import TableRow from 'components/digifi-wrappers/TableRow';
import TableBodyCell from 'components/digifi-wrappers/TableBodyCell';
import { TasksTableColumnSizes } from 'components/ApplicationDetails/TasksTableRow/TasksTableColumnSizes';
import styles from './TasksTable.module.scss';

const MAX_ROW_COUNT_DESKTOP = 10;
const MAX_ROW_COUNT_MOBILE = 5;

const SkeletonTasksTable: FC = () => {
  return (
    <>
      {Array.from({ length: MAX_ROW_COUNT_DESKTOP }).map(
        (value, index) => (
          <TableRow key={index} className={styles.tableRowDesktop} disableHover hasTopBorder={!index}>
            <TableBodyCell width={TasksTableColumnSizes.Description}>
              <SkeletonRectangle width="38%" color="primary10" height="16px" />
            </TableBodyCell>
            <TableBodyCell width={TasksTableColumnSizes.Status}>
              <SkeletonRectangle width="80%" color="primary6" height="16px" />
            </TableBodyCell>
            <TableBodyCell width={TasksTableColumnSizes.DueDate}>
              <SkeletonRectangle width="80%" color="primary6" height="16px" />
            </TableBodyCell>
            <TableBodyCell width={TasksTableColumnSizes.Actions}>
              <SkeletonRectangle width="80%" color="primary6" height="16px" />
            </TableBodyCell>
          </TableRow>
        ),
      )}
      {Array.from({ length: MAX_ROW_COUNT_MOBILE }).map(
        (value, index) => (
          <TableRow key={index} className={styles.tableRowMobile} disableHover hasTopBorder={!index}>
            <TableBodyCell width={100}>
              <SkeletonRectangle width="76%" color="primary10" height="14px" />
            </TableBodyCell>
          </TableRow>
        ),
      )}
    </>
  );
};

export default SkeletonTasksTable;

