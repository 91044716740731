import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import CardsRestApi, { IApplicationDetailsCard } from 'api/digifi/los/CardsApi';

enum ApplicationDetailsCardsActionType {
  GetApplicationDetailsCards = 'applicationDetailsCards/getApplicationDetailsCards',
}

const applicationDetailsCardsAdapter = createEntityAdapter<IApplicationDetailsCard>();

const initialState = applicationDetailsCardsAdapter.getInitialState();

const cardsRestApi = new CardsRestApi();

export const getApplicationDetailsCards = createAsyncThunk(
  ApplicationDetailsCardsActionType.GetApplicationDetailsCards,
  async (productId: string) => {
    return cardsRestApi.getApplicationDetailsCards(productId);
  },
);

const applicationDetailsCardsSlice = createSlice({
  name: 'applicationDetailsCardsSlice',
  initialState,
  reducers: {
    removeAllApplicationDetailsItems(state) {
      applicationDetailsCardsAdapter.removeAll(state);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getApplicationDetailsCards.fulfilled, (state, { payload }) => {
        applicationDetailsCardsAdapter.setAll(state, payload);
      });
  },
});

export const { selectAll: selectApplicationDetailsCards } = applicationDetailsCardsAdapter.getSelectors((state: RootState) => state.applicationDetailsCards);
export const { removeAllApplicationDetailsItems } = applicationDetailsCardsSlice.actions;

export default applicationDetailsCardsSlice.reducer;
