import React, { FC, useEffect, useMemo, useState } from 'react';
import { Suggestions, Status, GeocodeResult, Suggestion } from 'use-places-autocomplete';
import { AddressValue } from 'product_modules/api/Types';
import useBlockingRequest from 'product_modules/hooks/useBlockingRequest';
import SelectInput, { Option } from 'product_modules/components/SelectInput/SelectInput';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import AddressDetailsPopup from 'product_modules/components/AddressDetailsPopup';
import { LoaderState } from 'product_modules/components/LoaderWithState';
import { AddressIcon } from 'product_modules/static/images';
import { getAddressByGeocodeResult } from './getAddressByGeocodeResult';
import { buildFullAddressString } from './buildAddressString';
import styles from './AddressInput.module.scss';
import { debounce } from 'lodash';

export interface AddressInputProps {
  value: AddressValue | null | undefined;
  onChange: (address: AddressValue | null) => void;
  getSuggestions: (inputValue: string, country: string) => Promise<Suggestions>;
  getGeocode: (placeId: string) => Promise<GeocodeResult[]>;
  country: string;
  onAddressIconClick?: () => void;
  onBlur?: () => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  labelTitle?: string;
  errorMessage?: string;
  inputIcon?: React.ReactNode;
  loaderState?: LoaderState | null;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
  showLoader?: boolean;
  classNames?: Partial<{
    sectionClassName?: string;
    header?: string;
    popupContent?: string;
    closeIcon?: string;
    title?: string;
    saveButton?: string;
  }>;
  tabIndex?: number;
}

const PLACES_AUTOCOMPLETE_DELAY = 300;

const AddressInput: FC<AddressInputProps> = ({
  value: addressValue,
  onChange,
  onAddressIconClick,
  placeholder,
  readOnly,
  labelTitle,
  showLoader,
  onBlur,
  onFocus,
  disabled,
  errorMessage,
  inputIcon,
  loaderState,
  required,
  getSuggestions,
  getGeocode,
  country,
  classNames,
  tabIndex,
}) => {
  const [displayAddressDetails, setDisplayAddressDetails] = useState(false);
  const addressInputValue = useMemo(() => buildFullAddressString(addressValue), [addressValue]);
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [status, setStatus] = useState<Status | null>(null);

  const fetchSuggestions = async (input: string) => {
    if (!input.length) {
      return;
    }

    try {
      setIsLoading(true);
      const { data, status } = await getSuggestions(input, country);
      setSuggestions(data);
      setStatus(status);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setInputValue(addressInputValue);
  }, [addressInputValue]);

  const options = useMemo(() => {
    return suggestions.map((suggestion) => ({
      name: suggestion.description,
      value: suggestion.place_id,
    }));
  }, [suggestions]);

  const selectedOption = useMemo(() => ({
    name: addressInputValue,
    value: '',
  }), [addressInputValue]);

  const resetInputState = () => {
    setSuggestions([]);
    setStatus(null);
    setInputValue(addressInputValue);
  };

  const handleInputBlur = () => {
    resetInputState();

    onBlur?.();
  };

  const debouncedFetch = useMemo(
    () => debounce(fetchSuggestions, PLACES_AUTOCOMPLETE_DELAY),
    [],
  );

  useEffect(() => {
    return () => {
      debouncedFetch.cancel();
    }
  }, []);

  const handleInputChange = (updatedValue: string) => {
    setInputValue(updatedValue);
    debouncedFetch(updatedValue);
  };

  const [optionApplyingInProgress, handleOptionSelect] = useBlockingRequest(async (option: Option) => {
    setInputValue('');
    setSuggestions([]);
    setStatus(null);

    if (!option.value) {
      onChange(null);

      return;
    }

    const [geocodeResult] = await getGeocode(option.value);

    onChange(getAddressByGeocodeResult(geocodeResult));
  });

  const handleAddressIconClick = (event: React.MouseEvent<HTMLOrSVGElement>) => {
    event.preventDefault();

    if (onAddressIconClick) {
      onAddressIconClick();

      return;
    }

    setDisplayAddressDetails(true);
  };

  const handleSubmitAddressDetailsPopup = (updatedAddress: AddressValue) => {
    onChange(updatedAddress);
    setDisplayAddressDetails(false);
  };

  const renderInputIcon = () => (
    <>
      <WrapperWithTooltip tooltip="View Address Details">
        <AddressIcon onClick={handleAddressIconClick} className={styles.addressIcon} />
      </WrapperWithTooltip>
    </>
  );

  return (
    <>
      <SelectInput
        selectControlClassName={styles.selectControl}
        customInputIconContainerClassName={styles.customInputIconContainer}
        clearIconClassName={styles.clearIcon}
        labelTitle={labelTitle}
        required={required}
        readOnly={readOnly}
        disabled={optionApplyingInProgress || disabled}
        placeholder={optionApplyingInProgress ? 'Loading Address Information...' : placeholder}
        loading={isLoading || optionApplyingInProgress}
        selectedOption={!optionApplyingInProgress ? selectedOption : null}
        inputIcon={inputIcon || renderInputIcon()}
        userInput={inputValue}
        onChange={handleOptionSelect}
        onInputChange={handleInputChange}
        onBlur={handleInputBlur}
        onFocus={onFocus}
        hideClearIcon={!inputValue}
        showLoader={showLoader}
        errorMessage={errorMessage}
        options={options}
        loaderState={loaderState}
        loaded={status === 'OK' || status === 'ZERO_RESULTS'}
        blurOnCustomIconClick
        tabIndex={tabIndex}
      />
      {displayAddressDetails && <AddressDetailsPopup
        address={addressValue}
        addressLabel={labelTitle}
        onSubmit={handleSubmitAddressDetailsPopup}
        onClose={() => setDisplayAddressDetails(false)}
        usePortal
        classNames={classNames}
      />}
    </>
  );
};

export default AddressInput;
