import { FC, useEffect, useState } from 'react';
import ApplicationDetailsModal from 'components/ApplicationDetails/ApplicationDetailsModal';
import { IApplicationDocument } from 'api/digifi/los/DocumentsApi';
import { useNavigate, useParams } from 'react-router-dom';
import { batch } from 'react-redux';
import { removeDocuments, selectAllDocuments } from 'handlers/documentsSlice';
import { removeTasks, selectAllTasks } from 'handlers/tasksSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import ApplicationsLayout from 'layout/ApplicationsLayout';
import styles from './ApplicationDetails.module.scss';
import { findApplicationByDisplayId, setSelectedApplication } from 'handlers/applicationsSlice';
import { AppRoute } from 'enums/AppRoute';
import { createNotification } from 'handlers/notificationsSlice';
import { HttpStatusCode } from 'enums/HttpStatusCode';

const ApplicationDetails: FC = () => {
  const documents = useAppSelector(selectAllDocuments);
  const tasks = useAppSelector(selectAllTasks);
  const { currency } = useAppSelector(state => state.settings.variablesSettings);
  const { selectedApplication } = useAppSelector(state => state.applications);
  const { borrowerType } = useAppSelector((state) => state.settings.portalConfiguration);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationDisplayId } = useParams<{ applicationDisplayId: string}>();

  const findApplication = async () => {
    try {
      if (!selectedApplication) {
        setIsLoading(true);
      }

      await dispatchWithUnwrap(findApplicationByDisplayId(applicationDisplayId || ''));
    } catch (error) {
      createNotification({
        notification: error.message,
        type: 'error',
        dispatch,
      });

      if (error.responseStatus === HttpStatusCode.NotFound) {
        navigate(AppRoute.ResumeProcess);
      }
    }
  };

  useEffect(() => {
    findApplication();
  }, []);

  const handleApplicationDocumentClick = (document: IApplicationDocument) => {
    navigate(`${AppRoute.DocumentPreview}/${document.extension}/${document.id}/${document.name}`);
  };

  const handleTabContentLoadingComplete = () => {
    setIsLoading(false);
  };

  const handleApplicationClose = () => {
    batch(() => {
      dispatch(removeDocuments());
      dispatch(removeTasks());
      dispatch(setSelectedApplication(null));
    });

    navigate(AppRoute.ResumeProcess);
  };

  return (
    <ApplicationsLayout
      layoutContentClassName={styles.layoutContent}
      applicationsBrowserTitle={selectedApplication?.displayId || 'Applications'}
    >
      <ApplicationDetailsModal
        application={selectedApplication}
        onClose={handleApplicationClose}
        documents={documents}
        tasks={tasks}
        currency={currency}
        onApplicationDocumentClick={handleApplicationDocumentClick}
        isLoading={isLoading}
        borrowerType={borrowerType}
        onTabContentLoadingComplete={handleTabContentLoadingComplete}
      />
    </ApplicationsLayout>
  );
};

export default ApplicationDetails;
