import {
  IdentificationNumberWithAttributes,
  VariableCapAttributes,
  VisualDataType,
  VisualDataTypeWithAttributes,
} from 'product_modules/components/Variables/VariablesTypes';
import {
  validateEmail,
  validateIDNumber,
  validateMaxDateCap,
  validateMaxNumberCap,
  validateMinDateCap,
  validateMinNumberCap,
  validatePhoneNumber,
} from 'product_modules/utils/validation/validation';
import maskNumberValue from 'product_modules/utils/masks/maskNumberValue';
import formatPercentageValue from 'product_modules/utils/formatPercentageValue';
import formatMonetaryValue from 'product_modules/utils/formatMonetaryValue';
import { getCurrencySymbol } from 'product_modules/components/CurrencySelect/currencies';

interface ValidationOptions {
  disableCapAttributesValidation?: boolean;
}

type ValueValidator = (variableWithAttributes: VisualDataTypeWithAttributes & VariableCapAttributes, value: string) => string;
type ValueValidatorsFactory = (options: ValidationOptions) => ValueValidator[];

const formatCapByVariable = (variable: VisualDataTypeWithAttributes, cap: string) => {
  if (variable.dataType === 'Number') {
    return maskNumberValue(cap);
  }

  if (variable.dataType === 'Percentage') {
    return formatPercentageValue(Number.parseFloat(cap));
  }

  if (variable.dataType === 'Monetary') {
    const currencySymbol = variable.currency ? getCurrencySymbol(variable.currency) : '';

    return formatMonetaryValue(Number.parseFloat(cap), currencySymbol);
  }

  return cap;
};

const getPhoneNumberValidators = (): ValueValidator[] => ([
  (variable, value) => validatePhoneNumber(value),
]);

const getIdentificationNumberValidators = (): ValueValidator[] => ([
  (variable, value) => {
    const { identificationNumberType } = variable as IdentificationNumberWithAttributes;

    return validateIDNumber(value, identificationNumberType!);
  },
]);

const getEmailAddressValidators = (): ValueValidator[] => ([
  (variable, value) => validateEmail(value),
]);

const getNumberValidators = (options: ValidationOptions): ValueValidator[] => (!options?.disableCapAttributesValidation ? [
  (variable, value) => validateMinNumberCap(
    value,
    variable.minAllowedValue,
    (cap) => formatCapByVariable(variable, cap),
  ),
  (variable, value) => validateMaxNumberCap(
    value,
    variable.maxAllowedValue,
    (cap) => formatCapByVariable(variable, cap),
  ),
] : []);

const getDateValidators = (): ValueValidator[] => ([
  (variable, value) => validateMinDateCap(value, variable.minAllowedValue),
  (variable, value) => validateMaxDateCap(value, variable.maxAllowedValue),
]);

const validatorsByVisualDataType = new Map<VisualDataType, ValueValidatorsFactory>([
  ['PhoneNumber', getPhoneNumberValidators],
  ['IdentificationNumber', getIdentificationNumberValidators],
  ['EmailAddress', getEmailAddressValidators],
  ['Number', getNumberValidators],
  ['Monetary', getNumberValidators],
  ['Percentage', getNumberValidators],
  ['Date', getDateValidators],
]);

const getValidationMessage = (
  variableWithAttributes: VisualDataTypeWithAttributes & VariableCapAttributes,
  value: string,
  options: ValidationOptions,
): string => {
  const validators = validatorsByVisualDataType.get(variableWithAttributes.dataType)?.(options) || [];

  for (const validator of validators) {
    const errorMessage = validator(variableWithAttributes, value);

    if (errorMessage) {
      return errorMessage;
    }
  }

  return '';
};

export default getValidationMessage;
