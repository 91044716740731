import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { FormLayoutData } from 'api/Types';
import { BorrowerType } from 'enums/BorrowerType';

export interface ISimplifiedBorrowerParams {
  id: string;
  type: BorrowerType;
  locked: boolean;
  variables: FormLayoutData;
}

export interface IBorrowersApi {
  getBorrower(): Promise<ISimplifiedBorrowerParams>;
  updateBorrower(variables: FormLayoutData): Promise<ISimplifiedBorrowerParams>;
  getExistingBorrowerData(): Promise<ISimplifiedBorrowerParams>;
}

export default class BorrowersRestApi extends BaseApi implements IBorrowersApi {
  public getBorrower(): Promise<ISimplifiedBorrowerParams> {
    return this.fetch('/borrowers/current');
  }

  public updateBorrower(variables: FormLayoutData): Promise<ISimplifiedBorrowerParams> {
    return this.fetch('/borrowers/current', {
      method: FetchMethodType.PUT,
      body: {
        variables,
      },
    });
  }

  public getExistingBorrowerData(): Promise<ISimplifiedBorrowerParams> {
    return this.fetch('/borrowers/data');
  }
}
