import BaseApi from 'api/BaseApi';

export enum VariableType {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Date = 'Date',
}

export interface IProductCalculation {
  id: string;
  code: string;
  requiredVariables: Array<string>;
  variable: {
    id: string;
    systemName: string;
    displayName: string;
    dataType: VariableType;
  };
  productId: string;
  createdAt: Date;
}

interface IProductCalculationsApi {
  getProductCalculations(productId: string): Promise<IProductCalculation[]>;
}

export default class ProductCalculationsRestApi extends BaseApi implements IProductCalculationsApi {
  public getProductCalculations(productId: string): Promise<IProductCalculation[]> {
    const params = new URLSearchParams();
    params.append('productId', productId);

    return this.fetch(`/product-calculations?${params}`);
  }
}
