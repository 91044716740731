import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { ArrowLongRight } from 'product_modules/static/images';
import ScrollableTabs from 'product_modules/components/ScrollableTabs';
import StepComponent from './Step';
import styles from './Stepper.module.scss';
import scrollIntoView from 'scroll-into-view-if-needed';

export interface Step {
  label: string;
  type: string;
}

export interface StepperProps {
  steps: Step[];
  currentStep: string;
  className?: string;
  wrapperClassName?: string;
  stepClassName?: string;
  activeStepClassName?: string;
  passedStepClassName?: string;
  currentStepClassName?: string;
  numberClassName?: string;
  onStepChange: (stepName: string) => void;
  alwaysActive?: boolean;
  hideArrow?: boolean;
  scrollableElementClassName?: string;
  touchScroll?: boolean;
}

const Stepper = ({
  steps,
  currentStep,
  className,
  stepClassName,
  wrapperClassName,
  alwaysActive,
  onStepChange,
  hideArrow,
  activeStepClassName,
  currentStepClassName,
  passedStepClassName,
  numberClassName,
  scrollableElementClassName,
  touchScroll,
}: StepperProps) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const isPassed = (index: number) => index < steps.findIndex((step) => step.type === currentStep);

  const handleClick = (step: string, index: number) => {
    if (!alwaysActive && !isPassed(index)) {
      return;
    }

    onStepChange(step);
  };

  const getTargetElementToScroll = () => {
    const step = steps.find(({ type }) => type === currentStep)!;
    const stepIndex = steps.indexOf(step);

    return scrollableContainerRef.current!.children[stepIndex];
  };

  useEffect(() => {
    if (scrollableContainerRef.current && touchScroll) {
      scrollIntoView(getTargetElementToScroll(), {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        inline: 'start',
      });
    }
  }, [currentStep, scrollableContainerRef, touchScroll]);

  return (
    <div className={clsx(styles.stepsWrapper, wrapperClassName)}>
      <ScrollableTabs className={className} scrollableElementsClassName={scrollableElementClassName} touchScroll={touchScroll} ref={scrollableContainerRef}>
        {steps.map((step, index) => (
          <div key={step.type}>
            <StepComponent
              className={stepClassName}
              number={index + 1}
              title={step.label}
              current={step.type === currentStep}
              active={alwaysActive || step.type === currentStep}
              passed={isPassed(index)}
              onClick={() => handleClick(step.type, index)}
              activeClassName={activeStepClassName}
              currentClassName={currentStepClassName}
              passedClassName={passedStepClassName}
              numberClassName={numberClassName}
            />
            {index < steps.length - 1 && !hideArrow && <ArrowLongRight className={styles.arrow} />}
          </div>
        ))}
      </ScrollableTabs>
    </div>
  );
};

export default Stepper;
