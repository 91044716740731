import { FC } from 'react';
import MainLayout from 'layout/MainLayout';
import ApplicationsHeader from 'components/ApplicationsList/ApplicationsHeader';

interface IApplicationsLayoutProps {
  onLoginDetailsClick?: () => void;
  onLogoutClick?: () => void;
  disableScroll?: boolean;
  isLoading?: boolean;
  applicationsBrowserTitle?: string;
  layoutContentClassName?: string;
}

const ApplicationsLayout: FC<IApplicationsLayoutProps> = ({
  onLoginDetailsClick,
  onLogoutClick,
  disableScroll,
  isLoading,
  applicationsBrowserTitle,
  layoutContentClassName,
  children,
}) => {
  return (
    <MainLayout
      disableScroll={disableScroll}
      applicationsBrowserTitle={applicationsBrowserTitle}
      contentClassName={layoutContentClassName}
    >
      <ApplicationsHeader
        onLoginDetailsClick={onLoginDetailsClick}
        onLogoutClick={onLogoutClick}
        isLoading={isLoading}
      />
        {children}
    </MainLayout>
  );
};

export default ApplicationsLayout;
