import React from 'react';
import clsx from 'clsx';
import { CheckedImage } from 'static/images';
import styles from './Step.module.scss';

interface StepProps {
  className?: string;
  activeClassName?: string;
  passedClassName?: string;
  currentClassName?: string;
  numberClassName?: string;
  title: string;
  passed?: boolean;
  active?: boolean;
  current?: boolean;
  number: number;
  onClick?: () => void;
}

const Step = ({
  title,
  passed,
  current,
  active,
  number,
  onClick,
  className,
  activeClassName,
  currentClassName,
  passedClassName,
  numberClassName,
}: StepProps) => {
  const stepStyles = clsx(
    styles.step,
    active && styles.active,
    current && styles.current,
    passed && styles.passed,
    className,
    active && activeClassName,
    current && currentClassName,
    passed && passedClassName,
  );

  return (
    <div className={stepStyles} onClick={onClick}>
      <div className={clsx(styles.number, numberClassName)}>{passed ? <CheckedImage /> : number}</div>
      <p className={styles.title}>{title}</p>
    </div>
  );
};

export default Step;
