export enum DocumentsTableColumnSizes {
  Name = 51,
  Type = 12,
  Size = 10,
  Uploaded = 27,
}

export enum DocumentsTableColumnSizesMobile {
  Name = 69,
  Size = 19,
  Uploaded = 12,
}
