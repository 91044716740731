import React, { FocusEvent, useMemo } from 'react';
import clsx from 'clsx';
import { FormLayoutData, VariableValue } from 'product_modules/api/Types';
import { BaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { BaseCalculation } from 'product_modules/api/LoanOriginationSystem/Base/CalculationsApi';
import useFormLayout from './useFormLayout';
import { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import { isEmptyVariableValue } from 'product_modules/utils/isEmptyVariableValue';
import ConfigurableFormVariable from './ConfigurableFormVariable';
import { PermissionGroupId } from 'product_modules/components/PermissionGroups/Types';
import styles from './ConfigurableForm.module.scss';
import { FULLY_HIDDEN_VARIABLE_PLACEHOLDER } from 'product_modules/utils/formatValueByVariable';
import { VariableAccessPermissionType } from 'product_modules/components/Variables/VariablesTypes';

interface ConfigurableFormProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CalculationType extends BaseCalculation,
> {
  className?: string;
  configurations: VariableConfigurationType[];
  data: FormLayoutData;
  columns?: number;
  columnClassName?: string;
  formIndex?: number;
  inputContainerClassname?: string;
  onFieldChange: (variableConfiguration: VariableConfigurationType, value: VariableValue) => void;
  onFieldFocus?: (variableConfiguration: VariableConfigurationType, event: FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (variableConfiguration: VariableConfigurationType, event?: FocusEvent<HTMLInputElement>) => void;
  showLoader?: boolean;
  loaderStateById?: Record<string, LoaderState | undefined | null>;
  onLoaderStateReset?: (variableConfiguration: VariableConfigurationType) => void;
  formatDisplayTitle?: (systemName: string, title: string) => string;
  displayFieldsAttributes?: Record<string, boolean>;
  calculations?: CalculationType[] | null;
  userPermissionGroupId?: PermissionGroupId;
}

const DEFAULT_COLUMNS_LENGTH = 2;
const DEFAULT_FORM_INDEX = 0;
const VARIABLES_TAB_INDEX_OFFSET = 50;

const ConfigurableForm = <VariableConfigurationType extends BaseVariableConfiguration, CalculationType extends BaseCalculation>({
  className,
  configurations,
  columns = DEFAULT_COLUMNS_LENGTH,
  onFieldChange,
  onFieldFocus,
  onFieldBlur,
  data,
  columnClassName,
  inputContainerClassname,
  formatDisplayTitle,
  showLoader,
  onLoaderStateReset,
  loaderStateById,
  displayFieldsAttributes,
  calculations,
  formIndex = DEFAULT_FORM_INDEX,
  userPermissionGroupId,
}: ConfigurableFormProps<VariableConfigurationType, CalculationType>) => {
  const layout = useFormLayout(configurations, columns);

  const calculationsBySystemName = useMemo(() => {
    if (!calculations) {
      return {};
    }

    return calculations.reduce((previousCalculationsBySystemName, calculation) => ({
      ...previousCalculationsBySystemName,
      [calculation.variable.systemName]: calculation,
    }), []);
  }, [calculations]);

  if (!layout || !userPermissionGroupId) {
    return null;
  }

  const renderLayoutItem = (variableConfiguration: VariableConfigurationType, index: number) => {
    const visible = !displayFieldsAttributes
      || displayFieldsAttributes[variableConfiguration.id]
      || !isEmptyVariableValue(data[variableConfiguration.variable.systemName]);

    // @ts-ignore
    const isCalculatedVariable = !!calculationsBySystemName[variableConfiguration.variable.systemName];
    const variablePermission = variableConfiguration.variable.permissions[userPermissionGroupId];
    const displayValue = variablePermission === VariableAccessPermissionType.NoAccess
      ? FULLY_HIDDEN_VARIABLE_PLACEHOLDER
      : data[variableConfiguration.variable.systemName];

    return (
      <ConfigurableFormVariable
        className={inputContainerClassname}
        key={variableConfiguration.id}
        field={variableConfiguration}
        value={displayValue}
        showLoader={showLoader}
        loaderState={loaderStateById && loaderStateById[variableConfiguration.id]}
        required={variableConfiguration.required}
        tabIndex={(index + 1) + formIndex * VARIABLES_TAB_INDEX_OFFSET}
        userPermissionGroupId={userPermissionGroupId}
        formatDisplayTitle={formatDisplayTitle}
        onChange={onFieldChange}
        onBlur={onFieldBlur}
        onFocus={onFieldFocus}
        onLoaderStateReset={onLoaderStateReset}
        visible={visible}
        isCalculated={isCalculatedVariable}
      />
    );
  };

  const renderLayoutItems = (layoutItems: Array<VariableConfigurationType>, index: number) => (
    <div key={index} className={columnClassName}>
      {layoutItems.map(renderLayoutItem)}
    </div>
  );

  const configurationFormStyle = { gridTemplateColumns: `repeat(${layout!.length || columns}, minmax(0, 1fr))` };

  return (
    <div style={configurationFormStyle} className={clsx(styles.configurableFormContainer, className)}>
      {layout.map(renderLayoutItems)}
    </div>
  );
};

export default ConfigurableForm;
