import { FC, useEffect, useMemo, useRef, useState} from 'react';
import styles from 'components/ApplicationForm/ApplicationFormModal/ApplicationFormModal.module.scss';
import Stepper from 'components/digifi-wrappers/Stepper';
import { ApplicationFormStep } from 'enums/ApplicationFormStep';
import CreateApplication from 'components/ApplicationForm/CreateApplication';
import { ISimplifiedApplicationParams } from 'api/digifi/los/ApplicationsApi';
import { IProduct } from 'api/digifi/los/ProductsApi';
import ModalOverlayConnected from 'components/ApplicationForm/ApplicationFormModal/ModalOverlayConnected';

const INITIAL_STEP_INDEX = 0;

interface IApplicationFormModalProps {
  onClick: () => void;
  onCreateSuccess: (application: ISimplifiedApplicationParams) => void;
  product: IProduct | null;
  onSubmittingStart: () => void;
  isSubmittingInProgress?: boolean;
}

const applicationFormSteps = new Map<ApplicationFormStep, string>([
  [ApplicationFormStep.BorrowerProfileStep, 'Borrower Profile'],
  [ApplicationFormStep.ApplicationDetailsStep, 'Application Details'],
  [ApplicationFormStep.DocumentUploadStep, 'Document Upload'],
  [ApplicationFormStep.ReviewAndSubmitStep, 'Review & Submit'],
]);

const ApplicationFormModal: FC<IApplicationFormModalProps> = ({
  onClick,
  onCreateSuccess,
  product,
  onSubmittingStart,
  isSubmittingInProgress,
}) => {
  const popupRef = useRef<HTMLElement>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(INITIAL_STEP_INDEX);

  const steps = useMemo<{ label: string, type: ApplicationFormStep }[]>(() => {
    if (!product?.applicationFormPages) {
      return [];
    }

    const productSteps = product?.applicationFormPages
      .map((key) => {
        return {
          label: applicationFormSteps.get(key as ApplicationFormStep) || '',
          type: key as ApplicationFormStep,
        };
      })
      .filter(item => item.label);

    return [
      ...productSteps, {
        label: applicationFormSteps.get(ApplicationFormStep.ReviewAndSubmitStep) || '',
        type: ApplicationFormStep.ReviewAndSubmitStep,
      }];
  }, [applicationFormSteps]);

  const handleContinue = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const handleStepChange = (stepType: string) => {
    if (isSubmittingInProgress) {
      return;
    }

    const newStepIndex = steps.findIndex(({ type }) => type === stepType);
    setCurrentStepIndex(newStepIndex);
  };

  const renderHeaderContent = () => {
    return (
      <div className={styles.headerContent}>
        <div className={styles.headerSubtitle}>{product?.name}</div>
        <Stepper steps={steps} currentStep={steps[currentStepIndex].type} onStepChange={handleStepChange} />
      </div>
    );
  };

  useEffect(() => {
    if (popupRef?.current) {
      popupRef.current.scrollTo({ top: 0 });
    }
  }, [currentStepIndex, popupRef]);

  return (
    <ModalOverlayConnected
      title="Application Form"
      onClick={onClick}
      headerClassName={styles.applicationFormHeaderContainer}
      headerContent={renderHeaderContent()}
      titleClassName={styles.title}
      closeIconClassName={styles.closeIcon}
      ref={popupRef}
    >
      <CreateApplication
        currentStep={steps[currentStepIndex]}
        onStepChange={handleStepChange}
        onCreateSuccess={onCreateSuccess}
        product={product}
        onSubmittingStart={onSubmittingStart}
        isSubmittingInProgress={isSubmittingInProgress}
        onContinueClick={handleContinue}
      />
    </ModalOverlayConnected>
  );
};

export default ApplicationFormModal;
