import { useMemo } from 'react';
import { getCardsFields } from 'components/digifi-wrappers/CardsForm';
import { IBaseVariableConfiguration } from 'api/digifi/los/CardsApi';
import { ICard } from 'types/CardTypes';

const useCardFields = <
  VariableConfigurationType extends IBaseVariableConfiguration,
  CardType extends ICard<VariableConfigurationType>
  >(cards: CardType[] | null) => {
  return useMemo(() => {
    if (!cards || !cards.length) {
      return null;
    }

    return getCardsFields(cards);
  }, [cards]);
};

export default useCardFields;
