import { FC } from 'react';
import Pagination, { PaginationProps } from 'product_modules/components/Pagination';

const PaginationWrapper: FC<PaginationProps> = (props ) => {
  return (
    <Pagination {...props} />
  );
};

export default PaginationWrapper;
