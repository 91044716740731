import { FC, useEffect, useRef, useState } from 'react';
import styles from 'pages/applications/ApplicationsList/ApplicationsList.module.scss';
import PriorApplications from 'components/ApplicationsList/PriorApplications';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { ApplicationStatusCard, SkeletonApplicationStatusCard } from 'components/ApplicationsList/ApplicationStatusCard';
import clsx from 'clsx';
import { StatusCardType } from 'components/ApplicationsList/ApplicationStatusCard/ApplicationStatusCard';
import { ISimplifiedApplicationParams } from 'api/digifi/los/ApplicationsApi';
import ApplicationFormModal from 'components/ApplicationForm/ApplicationFormModal/ApplicationFormModal';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getBorrower, selectAllBorrowers } from 'handlers/borrowersSlice';
import {
  getBorrowerApplications,
  selectActiveApplications,
} from 'handlers/applicationsSlice';
import { batch } from 'react-redux';
import DeleteFormPopUp from 'components/ApplicationForm/DeleteFormPopUp';
import { getAllAvailableProducts, selectAllProducts } from 'handlers/productsSlice';
import { useNavigate } from 'react-router-dom';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import formatDisplayId from 'product_modules/utils/userIdFormat';
import { removeAllApplicationDetailsItems } from 'handlers/applicationDetailsCardsSlice';
import { removeApplicationDocumentsConfiguration } from 'handlers/applicationDocumentConfigurationSlice';
import { removeBorrowerProfileCards } from 'handlers/borrowerProfileCardsSlice';
import AuthorizedLayout from 'layout/AuthorizedLayout';
import { getBorrowerFirstName } from 'utils/borrowerNameHelper';

const ApplicationsList: FC = () => {
  const [borrower] = useAppSelector(selectAllBorrowers);
  const { accountData } = useAppSelector((state) => state.auth);
  const borrowerId = accountData?.borrowerId!;
  const activeApplications = useAppSelector(selectActiveApplications) as ISimplifiedApplicationParams[];
  const { selectedProduct } = useAppSelector(state => state.products);
  const { selectedApplication } = useAppSelector(state => state.applications);
  const products = useAppSelector(selectAllProducts);
  const [showNewApplicationForm, setShowNewApplicationForm] = useState<boolean>(false);
  const [showDeleteFormPopUp, setShowDeleteFormPopUp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmittingInProgress, setIsSubmittingInProgress] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef?.current) {
      contentRef.current.scrollTo({ top: 0 });
    }
  }, [showNewApplicationForm, contentRef]);


  const fetchData = async () => {
    return Promise.all([
      dispatchWithUnwrap(getBorrower()),
      dispatchWithUnwrap(getBorrowerApplications()),
      dispatchWithUnwrap(getAllAvailableProducts()),
    ]);
  };

  useEffect(() => {
    if (borrowerId) {
      if (!borrower) {
        setIsLoading(true);
      }

      fetchData().finally(() => setIsLoading(false));
    }
  }, [borrowerId]);

  const handleProductSelect = async () => {
    setShowNewApplicationForm(true);
  };

  const handleApplicationClick = async (application: ISimplifiedApplicationParams) => {
    navigate(`/applications/${application.displayId}`);
  };

  const renderApplicationStatusCards = () => {
    if (!activeApplications || isLoading) {
      return <SkeletonApplicationStatusCard />;
    } else if (!activeApplications.length) {
      if (borrower?.locked || !products.length) {
        return <ApplicationStatusCard type={StatusCardType.Locked} />;
      }
      return <ApplicationStatusCard type={StatusCardType.NewApplication} onProductSelect={handleProductSelect} />;
    }

    return (
      <>
        {activeApplications.map(application => (
          <ApplicationStatusCard
            key={application.id}
            application={application}
            onClick={() => handleApplicationClick(application)}
          />
        ))}
        {!borrower?.locked && products.length > 0 && <ApplicationStatusCard type={StatusCardType.NewApplication} onProductSelect={handleProductSelect} />}
      </>
    );
  };

  const renderGreeting = () => {
    return isLoading || !borrower
      ? <SkeletonText width="280px" height="24px" color="primary20" lineHeight="44px" />
      : `Hello, ${getBorrowerFirstName(borrower)}!`;
  };

  const renderSubtitle = () => {
    if (isLoading || !activeApplications) {
      return <SkeletonText width="220px" height="18px" color="primary20" lineHeight="28px" />;
    }

    return !activeApplications.length ? 'Apply Now!' : 'Active Applications';
  };

  const handleApplicationFormClose = () => {
    setShowDeleteFormPopUp(true);
  };

  const handleDeleteFormPopUpClose = () => {
    setShowDeleteFormPopUp(false);
  };

  const clearApplicationConfigurations = () => {
    batch(() => {
      dispatch(removeAllApplicationDetailsItems());
      dispatch(removeApplicationDocumentsConfiguration());
      dispatch(removeBorrowerProfileCards());
    });
  };

  const handleDeleteFormPopUpSubmit = () => {
    batch(() => {
      setShowNewApplicationForm(false);
      setShowDeleteFormPopUp(false);
      setIsSubmittingInProgress(false);
      clearApplicationConfigurations();
    });
  };

  const handleCreateApplicationSuccess = (application: ISimplifiedApplicationParams) => {
    batch(() => {
      setIsSubmittingInProgress(false);
      setShowNewApplicationForm(false);
      clearApplicationConfigurations();
      fetchData();
    });

    navigate(`/applications/${application.displayId}`);
  };

  const handleSubmittingStart = () => {
    setIsSubmittingInProgress(true);
  };

  const getApplicationsBrowserTitle = () => {
    if (selectedApplication) {
      return formatDisplayId(selectedApplication.displayId);
    }

    if (showNewApplicationForm) {
      return 'Start New Application';
    }

    return 'Applications';
  };

  return (
    <AuthorizedLayout
      disableScroll={showNewApplicationForm}
      isLoading={isLoading}
      applicationsBrowserTitle={getApplicationsBrowserTitle()}
    >
      <div className={clsx(styles.applicationsListContent, {[styles.applicationsListContent]: activeApplications})} ref={contentRef}>
        <div className={styles.title}>{renderGreeting()}</div>
        <div className={styles.subtitle}>{renderSubtitle()}</div>
        <div className={styles.statusCardsContainer}>
          {renderApplicationStatusCards()}
        </div>
        <PriorApplications
          onClick={handleApplicationClick}
          isLoading={isLoading}
        />
        {showNewApplicationForm && (
          <ApplicationFormModal
            onClick={handleApplicationFormClose}
            onCreateSuccess={handleCreateApplicationSuccess}
            product={selectedProduct}
            onSubmittingStart={handleSubmittingStart}
            isSubmittingInProgress={isSubmittingInProgress}
          />
        )}
        {showDeleteFormPopUp && (
          <DeleteFormPopUp
            onClose={handleDeleteFormPopUpClose}
            onSubmit={handleDeleteFormPopUpSubmit}
          />
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default ApplicationsList;
