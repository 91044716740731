import { Option } from 'product_modules/components/SelectInput/SelectInput';

const variableOptionsListToSelectInputOptions = (optionsList: string[] = []) =>
  optionsList.map(
    (value): Option => ({
      name: value,
      value,
    }),
  );

export default variableOptionsListToSelectInputOptions;
