import styled from 'types/AppTheme';
import BrandColorPattenImageSrc from './brand-color-pattern.svg';

const OVERLAY_COLOR = 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))';

export const getBrandColorBackgroundWithPatternStyles = (brandColor: string) => {
  return `url(${BrandColorPattenImageSrc}), ${OVERLAY_COLOR}, ${brandColor};`;
};

const ContainerStyled = styled.div`
  background: ${({ theme }) => getBrandColorBackgroundWithPatternStyles(theme.colors.brandColor)};
`;

export default ContainerStyled;
