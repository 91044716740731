import { FC, useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from 'enums/AppRoute';
import { useAppSelector } from 'hooks/reduxHooks';
import { AppRouteWithParams } from 'enums/AppRouteWithParams';

export const AUTH_ROUTES = [
  AppRouteWithParams.CreateAccount,
  AppRouteWithParams.SignIn,
  AppRouteWithParams.ResetPassword,
  AppRouteWithParams.CreateNewPassword,
  AppRouteWithParams.AcceptInvite,
];

const RedirectHandler: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { accountData } = useAppSelector(state => state.auth);

  const checkSession = () => {
    const isAuthRoute = AUTH_ROUTES.some((path) => matchPath(path, location.pathname));

    if (!accountData && !matchPath({ path: location.pathname }, '/')) {
      return;
    }

    if (!accountData?.borrowerId) {
      return isAuthRoute
        ? undefined
        : navigate(AppRoute.SignIn);
    }

    if (!accountData.isEmailVerified) {
      return navigate(AppRoute.ConfirmEmail);
    }

    if (!accountData.isMfaComplete) {
      return navigate(AppRoute.PhoneVerification);
    }

    if (isAuthRoute || matchPath('/', location.pathname)) {
      return navigate(AppRoute.ResumeProcess);
    }
  };

  useEffect(() => {
    checkSession();
  }, [accountData]);

  return null;
};

export default RedirectHandler;
