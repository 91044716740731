import { FC } from 'react';
import Header from 'components/PageLayout/Header';
import AuthFormContainer from 'components/Auth/AuthFormContainer';
import styles from './UnauthorizedLayout.module.scss';
import FormTitle from 'components/PageLayout/FormTitle';
import FormFooterLink from 'components/PageLayout/FormFooterLink';
import { AppRoute } from 'enums/AppRoute';
import clsx from 'clsx';
import MainLayout from 'layout/MainLayout';
import { logout } from 'handlers/authSlice';
import { useAppDispatch } from 'hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';

interface IUnauthorizedLayoutProps {
  formTitle: string;
  footerText?: string;
  footerLinkText?: string;
  footerLink?: AppRoute;
  formSubtitle?: string | JSX.Element;
  logoutOnLinkClick?: true;
}

const UnauthorizedLayout: FC<IUnauthorizedLayoutProps> = ({ formTitle, formSubtitle, footerText, footerLinkText, footerLink, logoutOnLinkClick, children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate(footerLink || AppRoute.SignIn);
  };

  return (
    <MainLayout>
      <div className={clsx(styles.authLayoutContainer)}>
        <Header />
        <div className={styles.authLayoutContent}>
          <div className={styles.authLayoutBodyContent}>
            <AuthFormContainer>
              <FormTitle title={formTitle} subTitle={formSubtitle} />
              <div className={styles.formContainer}>
                {children}
              </div>
            </AuthFormContainer>
            {footerLinkText && footerLink && (
              <div className={styles.footerLinkContainer}>
                <FormFooterLink text={footerText} linkText={footerLinkText} to={footerLink} onLinkClick={logoutOnLinkClick && handleLogout} />
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default UnauthorizedLayout;
