import { FC, useState } from 'react';
import styles from './ApplicationStatusCard.module.scss';
import { ISimplifiedApplicationParams } from 'api/digifi/los/ApplicationsApi';
import formatMonetaryValue from 'product_modules/utils/formatMonetaryValue';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import pluralize from 'utils/pluralize';
import formatDate from 'utils/formatDate';
import ProductsList from 'components/ApplicationsList/ProductsList';
import { selectAllProducts, setSelectedProduct } from 'handlers/productsSlice';
import { IProduct } from 'api/digifi/los/ProductsApi';
import NewApplicationWrapperStyled from 'components/ApplicationsList/ApplicationStatusCard/NewApplicationWrapperConnected';
import ActionContainerStyled from 'components/BrandingHelpers/ActionContainerStyled';

export enum StatusCardType {
  ActiveApplication = 'ActiveApplication',
  NewApplication = 'NewApplication',
  Locked = 'Locked',
}

interface IApplicationStatusCardProps {
  application?: ISimplifiedApplicationParams;
  type?: StatusCardType,
  onClick?: () => void;
  onProductSelect?: () => void;
}

const ApplicationStatusCard: FC<IApplicationStatusCardProps> = ({ application, type = StatusCardType.ActiveApplication, onClick, onProductSelect }) => {
  const { currency } = useAppSelector((state) => state.settings.variablesSettings);
  const products = useAppSelector(selectAllProducts);
  const [showListOfProducts, setShowListOfProducts] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const renderLoanAmount = () => {
    return (
      <>
        {application?.loanAmount !== undefined && application?.loanAmount !== null && (
          <ActionContainerStyled>
            {formatMonetaryValue(application!.loanAmount, currency)}
          </ActionContainerStyled>
        )}
      </>
    );
  };

  const renderActiveApplicationCard = () => {
    return (
      <NewApplicationWrapperStyled
        className={styles.applicationStatusContainer}
        onClick={onClick}
      >
        <div className={styles.applicationCardTitle}>
          <span className={styles.productName}>{application!.productName}</span>{renderLoanAmount()}
        </div>
        <div className={styles.applicationCardSubtitle}>{formatDate(application!.createdAt)}</div>
        <div className={styles.applicationStatusText}>
          {application!.incompleteTasksCount > 0
            ? 'Outstanding Tasks'
            : 'Application In Review'}
        </div>
        <div className={styles.applicationsLastRow}>
          {application!.incompleteTasksCount > 0
            ? `You have ${application!.incompleteTasksCount} ${pluralize('task', application!.incompleteTasksCount)} to complete`
            : 'We\'ll be in touch with any questions'}
        </div>
      </NewApplicationWrapperStyled>
    );
  };

  const handleNewApplicationCardClick = () => {
    if (products.length === 1) {
      return handleProductClick(products[0]);
    }

    setShowListOfProducts(true);
  };

  const handleProductClick = (product: IProduct) => {
    dispatch(setSelectedProduct(product));
    setShowListOfProducts(false);
    onProductSelect?.();
  };

  const handleProductListClose = () => {
    setShowListOfProducts(false);
  };

  const renderNewApplicationCard = () => {
    return (
      <NewApplicationWrapperStyled
        active={showListOfProducts}
        className={styles.newApplicationWrapper}
      >
        <ActionContainerStyled
          className={styles.newApplicationContainer}
          onClick={handleNewApplicationCardClick}
        >
          Start New Application
        </ActionContainerStyled>
        {showListOfProducts && (
          <ProductsList
            productsList={products}
            onClick={handleProductClick}
            onClose={handleProductListClose}
          />
        )}
      </NewApplicationWrapperStyled>
    );
  };

  const renderLockedCard = () => {
    return (
      <div className={styles.lockedStatusContainer}>
        You are not eligible to apply at this time. Please contact us for more information.
      </div>
    );
  };

  const renderStatusCard = () => {
    switch (type) {
      case StatusCardType.Locked:
        return renderLockedCard();
      case StatusCardType.NewApplication:
        return renderNewApplicationCard();
      default:
        return renderActiveApplicationCard();
    }
  };

  return renderStatusCard();
};

export default ApplicationStatusCard;
