import React, { FC, useState } from 'react';
import clsx from 'clsx';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import styles from './OverflowedText.module.scss';

export interface OverflowedTextProps {
  className?: string;
  useTooltip?: boolean
  tooltipOnHover?: React.ReactNode;
  style?: React.CSSProperties;
  tag?: 'div' | 'p' | 'span' | 'h5';
}

const OverflowedText: FC<OverflowedTextProps> = ({
  className,
  useTooltip,
  tooltipOnHover,
  children,
  tag = 'div',
  style,
}) => {
  const overflowedTextClassName = clsx(
    styles.overflowedText,
    !useTooltip && styles.overflowedTextWithBreakWords,
    className,
  );

  const TagComponent = tag;

  const [displayTooltip, setDisplayTooltip] = useState(false);

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setDisplayTooltip(event.currentTarget.scrollWidth > event.currentTarget.clientWidth);
  };

  const handleMouseLeave = () => {
    setDisplayTooltip(false);
  };

  if (useTooltip) {
    return (
      <WrapperWithTooltip open={displayTooltip} tooltip={tooltipOnHover || children}>
        <TagComponent style={style} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={overflowedTextClassName}>{children}</TagComponent>
      </WrapperWithTooltip>
    );
  }

  return (
    <TagComponent style={style} className={overflowedTextClassName}>{children}</TagComponent>
  );
};

export default OverflowedText;
