import { FC, useState } from 'react';
import CreateAccountForm from 'components/Auth/forms/CreateAccountForm';
import { createAccount, getCurrentAccount } from 'handlers/authSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppDispatch } from 'hooks/reduxHooks';
import { CreateAccountFormType } from 'types';
import { createNotification, NotificationComponentTypes } from 'handlers/notificationsSlice';
import { HttpStatusCode } from 'enums/HttpStatusCode';

const OnlineSignUp: FC = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (values: CreateAccountFormType) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(createAccount({ ...values }));
      await dispatchWithUnwrap(getCurrentAccount());
    } catch (error) {
      if (error.responseStatus === HttpStatusCode.Conflict) {
        createNotification({
          notification: { componentType: NotificationComponentTypes.CreateAccountComponent },
          type: 'error',
          dispatch,
        });
      } else {
        createNotification({
          notification: error.message,
          type: 'error',
          dispatch,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CreateAccountForm handleSubmit={handleSubmit} isLoading={isLoading} />
  );
};

export default OnlineSignUp;
