import { FC } from 'react';
import styles from './ApplicationTabs.module.scss';
import ApplicationDocumentsTab from 'components/ApplicationDetails/ApplicationDocumentsTabConnector';
import { IApplicationDocument } from 'api/digifi/los/DocumentsApi';
import OutstandingTasksTab from 'components/ApplicationDetails/OutstandingTasksTabConnector';
import { ITask } from 'api/digifi/los/TasksApi';
import TabSwitch from 'components/digifi-wrappers/TabSwitch';

export enum ApplicationTabType {
  DocumentsTab = 'Application Documents',
  TasksTab = 'Outstanding Tasks',
}

export interface ITabProps {
  label: string;
  id: string;
  onTabClick?: () => void;
}

interface IApplicationTabsProps {
  applicationId: string;
  applicationDisplayId: string;
  selectedTabId: string | null;
  documents: IApplicationDocument[];
  tabs: ITabProps[];
  tasks: ITask[];
  onApplicationDocumentClick: (document: IApplicationDocument) => void;
  onTabContentLoadingComplete: () => void;
  onSelect?: (tabId: string) => void;
  isDocumentsInfoLoading?: boolean;
  isLoading?: boolean;
}

const ApplicationTabs: FC<IApplicationTabsProps> = ({
  applicationId,
  applicationDisplayId,
  selectedTabId,
  documents,
  tabs,
  onSelect,
  tasks,
  onApplicationDocumentClick,
  onTabContentLoadingComplete,
  isLoading,
}) => {
  const selectionOption = tabs.find(({ id }) => id === selectedTabId) || tabs[0];

  const renderTab = () => {
    switch (selectionOption.label) {
      case ApplicationTabType.DocumentsTab:
        return (
          <ApplicationDocumentsTab
            documents={documents}
            applicationDisplayId={applicationDisplayId}
            applicationId={applicationId}
            onApplicationDocumentClick={onApplicationDocumentClick}
            onTabContentLoadingComplete={onTabContentLoadingComplete}
            onTabClick={selectionOption.onTabClick}
          />
        );
      case ApplicationTabType.TasksTab:
        return (
          <OutstandingTasksTab
            applicationId={applicationId}
            tasks={tasks}
            onTabContentLoadingComplete={onTabContentLoadingComplete}
            isModalLoading={isLoading}
            onTabClick={selectionOption.onTabClick}
          />
        );
    }
  };

  return (
    <>
      <div className={styles.tabsContainer}>
        <TabSwitch tabs={tabs} selectedTabId={selectionOption.id} onSelect={({ id }) => onSelect?.(id)} />
      </div>
      {renderTab()}
    </>
  );
};

export default ApplicationTabs;
