import { FC } from 'react';
import TableRow from 'components/digifi-wrappers/TableRow';
import styles from './EmptyTable.module.scss';
import clsx from 'clsx';
import { ApplicationTabType } from 'components/ApplicationDetails/ApplicationTabs/ApplicationTabs';

const TABLE_ROW_COUNT = 5;
const MIDDLE_ROW_INDEX = Math.floor(TABLE_ROW_COUNT / 2);

interface IEmptyTableProps {
  type: ApplicationTabType;
}

const EmptyTable: FC<IEmptyTableProps> = ({ type }) => {
  const getText = (tabType: ApplicationTabType) => {
    return tabType === ApplicationTabType.TasksTab
      ? 'There are no Outstanding Tasks at this time.'
      : 'There are no Application Documents at this time.';
  };

  const renderTable = Array.from({ length: TABLE_ROW_COUNT }).map((value, index) => (
    <TableRow
      key={index}
      className={clsx({ [styles.infoContainer]: index === MIDDLE_ROW_INDEX })}
      disableHover
      hasTopBorder={!index}
    >
      {index === MIDDLE_ROW_INDEX && getText(type)}
    </TableRow>
  ));

  return <>{renderTable}</>;
};

export default EmptyTable;
