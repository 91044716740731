import React, { FC } from 'react';
import Label from 'components/digifi-wrappers/Label';
import styles from './SendResponseForm.module.scss';
import Button from 'components/digifi-wrappers/Button';

export interface ICommentFormProps {
  commentMessage: string;
  onCommentMessageChange: (message: string) => void;
  onSubmit: () => Promise<void>;
  isLoading?: boolean;
}

const SendResponseForm: FC<ICommentFormProps> = ({
  commentMessage,
  onCommentMessageChange,
  isLoading,
  onSubmit,
}) => {
  const handleClick = async () => {
   await onSubmit();
  };

  return (
    <div>
      <div className={styles.commentField}>
        <Label htmlFor="comment">Response</Label>
        <textarea
          disabled={isLoading}
          className={styles.textArea}
          name="comment"
          id="comment"
          value={commentMessage}
          onChange={({ target }) => onCommentMessageChange(target.value)}
        />
      </div>
      <Button
        kind="primary"
        size="form"
        isLoading={isLoading}
        onClick={handleClick}
        className={styles.submitButton}
        disabled={!commentMessage}
      >
        Send Response
      </Button>
    </div>
  );
};

export default SendResponseForm;
