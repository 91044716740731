import { useMemo } from 'react';
import { FormLayoutData } from 'api/Types';

interface IFullApplicationDataProps {
  borrowerFormData: FormLayoutData;
  applicationDetailsFormData: FormLayoutData;
}

const useFullApplicationData = ({
  borrowerFormData,
  applicationDetailsFormData,
}: IFullApplicationDataProps) => {

  return useMemo(() => ({
    ...borrowerFormData,
    ...applicationDetailsFormData,
  }), [
    borrowerFormData,
    applicationDetailsFormData,
  ]);
};

export default useFullApplicationData;
