import { useMemo } from 'react';
import { IApplicationDocumentConfiguration } from 'api/digifi/los/CardsApi';
import { IDocumentUploadFile } from 'components/ApplicationForm/forms/DocumentUploadForm/DocumentUploadForm';

const useUploadApplicationDocumentsValidation = (
  configurations: IApplicationDocumentConfiguration[] | null,
  files: IDocumentUploadFile[],
) => {
  const filesByConfigurationId = useMemo(() => {
    return files.reduce((previousFileByConfigurationId, documentFile) => ({
      ...previousFileByConfigurationId,
      [documentFile.configurationId]: documentFile,
    }), {});
  }, [files]);

  return (displayFieldsAttributes?: Record<string, boolean>) => {
    return (configurations || []).every((configuration) => {

      return (displayFieldsAttributes && !displayFieldsAttributes[configuration.id])
        || !configuration.required
        // @ts-ignore
        || !!filesByConfigurationId[configuration.id];
    });
  };
};

export default useUploadApplicationDocumentsValidation;
