import React, { DetailedHTMLProps, ButtonHTMLAttributes, forwardRef, MouseEvent } from 'react';
import clsx from 'clsx';
import ButtonLoader from 'product_modules/components/ButtonLoader';
import styles from './Button.module.scss';

type ButtonSize = 'form' | 'default' | 'small';
export type ButtonType = 'primary' | 'secondary' | 'warning' | 'with-state';

export type HTMLButtonProps = Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'ref'
  >;

export interface ButtonProps extends HTMLButtonProps {
  size?: ButtonSize;
  kind?: ButtonType | null;
  fullWidth?: boolean;
  isLoading?: boolean;
  active?: boolean;
  alwaysClickable?: boolean;
  loadingDotColor?: string | null;
}

export const getButtonSizeClassName = (size: ButtonSize): string | null => {
  switch (size) {
    case 'default':
      return styles.mediumSize;
    case 'form':
      return styles.largeSize;
    case 'small':
      return styles.smallSize;
    default:
      return null;
  }
};

export const getLoadingDotColorByType = (type: ButtonType | null): string | undefined => {
  switch (type) {
    case 'primary':
      return styles.primaryDotColor;
    case 'secondary':
      return styles.secondaryDotColor;
    case 'warning':
      return styles.warningDotColor;
    default:
      return undefined;
  }
}

export const getButtonTypeClassName = (type: ButtonType | null): string | null => {
  switch (type) {
    case 'primary':
      return styles.primary;
    case 'secondary':
      return styles.secondary;
    case 'warning':
      return styles.warning;
    case 'with-state':
      return styles.withState;
    default:
      return null;
  }
};

/**
 * @param {string} size size of the button
 * @param {string} kind type of the button
 * @param {boolean} fullWidth width of the button
 * @param {boolean} isLoading loading state of the button
 */

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    className,
    size = 'default',
    kind = 'secondary',
    fullWidth = false,
    isLoading = false,
    children,
    disabled,
    active,
    alwaysClickable = false,
    loadingDotColor,
    onClick,
    ...buttonProps
  } = props;

  const isDisabled = isLoading || (!alwaysClickable && disabled);

  const mergedClassName = clsx(
    getButtonSizeClassName(size),
    getButtonTypeClassName(kind),
    className,
    fullWidth ? styles.fullWidth : null,
    isLoading && styles.buttonLoading,
    styles.button,
    active && styles.active,
    isDisabled && styles.disabled,
    alwaysClickable && styles.alwaysClickable,
  );

  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (alwaysClickable || !disabled) {
      onClick?.(e);
    }
  };

  return (
    <button
      onClick={handleOnClick}
      ref={ref}
      type="button"
      className={mergedClassName}
      {...buttonProps}
      disabled={isDisabled}
    >
      {isLoading ? <ButtonLoader dotColor={loadingDotColor || getLoadingDotColorByType(kind)} className={styles.loader} /> : children}
    </button>
  );
});

export default Button;
