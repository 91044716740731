import { FC } from 'react';
import styles from 'components/PageLayout/Footer/Footer.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { ILegalDocument } from 'api/digifi/dlp/SettingsApi';
import { setSelectedDocument } from 'handlers/settingsSlice';
import ContainerStyled from 'components/BrandingHelpers/ContainerStyled';

const Footer: FC = () => {
  const currentYear = new Date().getFullYear();
  const { legalDocuments } = useAppSelector((state) => state.settings);
  const { companyName } = useAppSelector(state => state.settings.branding);
  const footerDocuments = legalDocuments.filter(document => document.showInFooter);
  const dispatch = useAppDispatch();

  const handleDocumentClick = (selectedDocument: ILegalDocument) => {
    dispatch(setSelectedDocument(selectedDocument));
    window.scrollTo(0, 0);
  };

  return (
    <ContainerStyled className={styles.footerContainer}>
      <div className={styles.footerContent}>
        <div className={styles.companyName}>© {currentYear} {companyName}, Inc.</div>
        <div className={styles.documentsContainer}>
          {footerDocuments.map((document) => (
            <span
              key={document.name}
              className={styles.documentName}
              onClick={() => handleDocumentClick(document)}
            >
              {document.name}
            </span>
          ))}
        </div>
      </div>
    </ContainerStyled>
  );
};

export default Footer;
