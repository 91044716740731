import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { ApplicationStatus } from 'enums/ApplicationStatus';
import { IDataViewSortingType, FormLayoutData } from 'api/Types';

export interface ISimplifiedApplicationParams {
  id: string;
  displayId: string;
  loanAmount: number;
  status: ApplicationStatus;
  productName: string;
  createdAt: string;
  incompleteTasksCount: number;
  borrowerId: string;
  variables: FormLayoutData;
  updatedAt: string;
  statusDate?: string;
}

interface IGetPriorBorrowerApplicationsRequest {
  count: number;
  offset: number;
}

interface IGetPriorBorrowerApplicationsResponse {
  priorApplications: ISimplifiedApplicationParams[];
  total: number;
}

interface IGetActiveApplicationsResponse {
  activeApplications: ISimplifiedApplicationParams[];
}

export interface ICreateApplicationParams {
  product: string;
  applicationDetails: FormLayoutData;
}

export enum ApplicationSortingField {
  CreatedAt = 'createdAt',
  ProductName = 'productName',
  DisplayId = 'displayId',
  LoanAmount = 'loanAmount',
  Status = 'status',
  StatusDate = 'statusDate',
}

export type ApplicationsSortingType = IDataViewSortingType<ApplicationSortingField>;

export interface IApplicationsApi {
  getPriorBorrowerApplications(params: IGetPriorBorrowerApplicationsRequest): Promise<IGetPriorBorrowerApplicationsResponse>;
  getActiveBorrowerApplications(): Promise<IGetActiveApplicationsResponse>;
  createApplication(params: ICreateApplicationParams): Promise<ISimplifiedApplicationParams>;
  findByDisplayId(displayId: string): Promise<ISimplifiedApplicationParams>;
}

export default class ApplicationsRestApi extends BaseApi implements IApplicationsApi {
 public getPriorBorrowerApplications(params: IGetPriorBorrowerApplicationsRequest): Promise<IGetPriorBorrowerApplicationsResponse> {
   const urlParams = new URLSearchParams();

   for (const param of Object.keys(params)) {
     urlParams.append(param, params[param as keyof IGetPriorBorrowerApplicationsRequest].toString());
   }

   return this.fetch(`/applications/prior?${urlParams}`);
 }

  public getActiveBorrowerApplications(): Promise<IGetActiveApplicationsResponse> {
    return this.fetch('/applications/active');
  }

  public createApplication(params: ICreateApplicationParams): Promise<ISimplifiedApplicationParams> {
    const { product, applicationDetails } = params;

    return this.fetch('/applications', {
      method: FetchMethodType.POST,
      body: {
        product,
        variables: applicationDetails,
      },
    });
  }

  public findByDisplayId(displayId: string): Promise<ISimplifiedApplicationParams> {
    return this.fetch(`/applications/${displayId}`);
  }
}
