import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { BorrowerType } from 'enums/BorrowerType';
import { VariableValue } from 'types/VariableTypes';

export enum CommentReferenceType {
  Task = 'task',
}

export enum CommentAuthorType {
  SystemUser = 'systemUser',
  Borrower = 'borrower',
  Intermediary = 'intermediary',
}

interface IBorrowerCommentAuthor {
  id: string;
  type: BorrowerType;
  variables: Record<string, VariableValue>;
}

export type Comment = {
  id: string;
  message: string;
  organizationId: string;
  referenceId: string;
  referenceType: CommentReferenceType;
  messageEditedAt?: Date;
  deletedAt?: Date;
  createdAt: Date;
} & ({
  authorType: CommentAuthorType.Borrower;
  author: IBorrowerCommentAuthor;
});

interface ICommentsApi {
  create(params: ICreateCommentParams): Promise<Comment>;
}

export interface ICreateCommentParams {
  taskId: string;
  message: string;
}

export default class CommentsRestApi extends BaseApi implements ICommentsApi {
  public create(params: ICreateCommentParams): Promise<Comment> {
    return this.fetch('/comments', {
      method: FetchMethodType.POST,
      body: {
        taskId: params.taskId,
        message: params.message,
      },
    });
  }
}
