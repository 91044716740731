import { FC, useMemo } from 'react';
import styles from './PriorApplications.module.scss';
import ApplicationsTable from 'components/ApplicationsList/ApplicationsTable';
import Pagination from 'components/digifi-wrappers/Pagination';
import usePagination from 'hooks/usePagination';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import { ApplicationSortingField, ISimplifiedApplicationParams } from 'api/digifi/los/ApplicationsApi';
import { selectPriorApplications, setPriorApplicationsSortingType } from 'handlers/applicationsSlice';
import { sortApplications } from 'utils/sortApplications';

interface IPriorApplicationsProps {
  onClick: (application: ISimplifiedApplicationParams) => void;
  isLoading?: boolean;
}

const PriorApplications: FC<IPriorApplicationsProps> = ({ onClick, isLoading }) => {
  const priorApplications = useAppSelector(selectPriorApplications) as ISimplifiedApplicationParams[];
  const { currency } = useAppSelector((state) => state.settings.variablesSettings);
  const { sortingType } = useAppSelector(state => state.applications.priorApplications);
  const paginationProps = usePagination();
  const dispatch = useAppDispatch();

  const sortedApplications = useMemo(() => {
    return priorApplications ? sortApplications(priorApplications || [], sortingType) : priorApplications;
  }, [priorApplications, sortingType]);

  const handleSort = (field: ApplicationSortingField, ascending: boolean) => {
    dispatch(setPriorApplicationsSortingType({ field, ascending }));
  };

  const renderTitle = () => {
    if (!isLoading && priorApplications && !priorApplications.length) {
      return;
    }

    return (
      <div className={styles.title}>
        {isLoading
          ?  <SkeletonText width="220px" color="primary20" height="18px" lineHeight="28px" />
          : 'Prior Applications'}
      </div>
    );
  };

  return (
    <div className={styles.priorApplicationsContainer}>
      {renderTitle()}
      {(isLoading || !priorApplications || priorApplications.length > 0) && (
        <ApplicationsTable
          applications={sortedApplications}
          onClick={onClick}
          currency={currency}
          onSort={handleSort}
          sortingType={sortingType}
          isLoading={isLoading}
        />
      )}
      {!isLoading && <Pagination {...paginationProps} />}
    </div>
  );
};

export default PriorApplications;
