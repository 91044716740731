import { FC, MouseEvent } from 'react';
import TableRow from 'components/digifi-wrappers/TableRow';
import TableBodyCell from 'components/digifi-wrappers/TableBodyCell';
import { DocumentsTableColumnSizes, DocumentsTableColumnSizesMobile } from './DocumentsTableColumnSizes';
import { DocIcon, DownloadIcon, FileIcon, ImageIcon, JsonIcon, PdfIcon, PptIcon, XlsIcon } from 'static/images';
import styles from './DocumentsTableRow.module.scss';
import { DocumentExtension, IApplicationDocument } from 'api/digifi/los/DocumentsApi';
import formatDate from 'utils/formatDate';
import { getMegabytesFromBytes } from 'utils/getMegabytesFromBytes';
import clsx from 'clsx';
import SvgIconStyled from 'components/BrandingHelpers/SvgIconStyled';
import WrapperWithTooltip from 'components/digifi-wrappers/Tooltip';
import OverflowedText from 'components/digifi-wrappers/OverflowedText';

interface IDocumentsTableRow {
  document: IApplicationDocument;
  onDocumentDownloadClick: (document: IApplicationDocument) => void;
  onDocumentClick: (document: IApplicationDocument) => void;
  hasTopBorder: boolean;
}

export const iconsByExtension = new Map([
  [DocumentExtension.Xls, <XlsIcon />],
  [DocumentExtension.Xlsx, <XlsIcon />],
  [DocumentExtension.Csv, <XlsIcon />],
  [DocumentExtension.Doc, <DocIcon />],
  [DocumentExtension.Docx, <DocIcon />],
  [DocumentExtension.Xml, <DocIcon />],
  [DocumentExtension.Txt, <DocIcon />],
  [DocumentExtension.Ppt, <PptIcon />],
  [DocumentExtension.Pptx, <PptIcon />],
  [DocumentExtension.Pdf, <PdfIcon />],
  [DocumentExtension.Json, <JsonIcon />],
  [DocumentExtension.Gif, <ImageIcon />],
  [DocumentExtension.Png, <ImageIcon />],
  [DocumentExtension.Jpeg, <ImageIcon />],
  [DocumentExtension.Jpg, <ImageIcon />],
  [DocumentExtension.Svg, <ImageIcon />],
  [DocumentExtension.Ico, <ImageIcon />],
  [DocumentExtension.Heic, <ImageIcon />],
  [DocumentExtension.Webp, <ImageIcon />],
]);

export const SMALL_SIZE_DISPLAY_SIZE = 0.1;

const getFileSize = (document: IApplicationDocument) =>
  Number(getMegabytesFromBytes(document.size).toFixed(1)) || SMALL_SIZE_DISPLAY_SIZE;

const DocumentsTableRow: FC<IDocumentsTableRow> = ({
  document,
  onDocumentDownloadClick,
  onDocumentClick,
  hasTopBorder,
}) => {
  const handleDocumentDownloadClick = (event: MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();

    onDocumentDownloadClick(document);
  };

  const renderTableRowDesktop = () => {
    return (
      <TableRow
        className={clsx(styles.documentRowContainer, styles.tableRowDesktop)}
        onClick={() => onDocumentClick(document)}
        hasTopBorder={hasTopBorder}
      >
        <TableBodyCell width={DocumentsTableColumnSizes.Name} overflowed noPadding>
          <div className={styles.documentNameCell}>
            <div className={styles.extensionIcon}>
              {iconsByExtension.get(document.extension.toLowerCase() as DocumentExtension) || <FileIcon />}
            </div>
            <OverflowedText className={styles.documentName}>
              {document.name}
            </OverflowedText>
          </div>
        </TableBodyCell>
        <TableBodyCell width={DocumentsTableColumnSizes.Type} overflowed>
          {document.extension}
        </TableBodyCell>
        <TableBodyCell width={DocumentsTableColumnSizes.Size} overflowed>
          {`${getFileSize(document)} Mb`}
        </TableBodyCell>
        <TableBodyCell width={DocumentsTableColumnSizes.Uploaded} noPadding>
          <div className={styles.uploadedContainer}>
            {formatDate(document.createdAt)}
            <WrapperWithTooltip tooltip="Download Document">
              <div onClick={handleDocumentDownloadClick}>
                <SvgIconStyled className={styles.filledIconContainer}>
                  <DownloadIcon />
                </SvgIconStyled>
              </div>
            </WrapperWithTooltip>
          </div>
        </TableBodyCell>
      </TableRow>
    );
  };

  const renderTableRowMobile = () => {
    return (
      <TableRow className={styles.tableRowMobile} onClick={() => onDocumentClick(document)}>
        <TableBodyCell width={DocumentsTableColumnSizesMobile.Name} overflowed noPadding>
          <div className={styles.documentNameCell}>
            <div className={styles.extensionIcon}>
              {iconsByExtension.get(document.extension.toLowerCase() as DocumentExtension) || <FileIcon />}
            </div>
            <OverflowedText>
              {document.name}
            </OverflowedText>
          </div>
        </TableBodyCell>
        <TableBodyCell width={DocumentsTableColumnSizesMobile.Size} overflowed>
          {`${getFileSize(document)} Mb`}
        </TableBodyCell>
        <TableBodyCell width={DocumentsTableColumnSizesMobile.Uploaded} overflowed noPadding>
          <div onClick={handleDocumentDownloadClick}>
            <SvgIconStyled className={styles.filledIconContainer}>
              <DownloadIcon />
            </SvgIconStyled>
          </div>
        </TableBodyCell>
      </TableRow>
    );
  };

  return (
    <>
      {renderTableRowDesktop()}
      {renderTableRowMobile()}
    </>
  );
};

export default DocumentsTableRow;
