import { AnyAction, CombinedState, combineReducers } from '@reduxjs/toolkit';
import authReducer, { AccountsActionType } from 'handlers/authSlice';
import notificationsReducer from './notificationsSlice';
import settingsReducer from './settingsSlice';
import applicationsReducer from 'handlers/applicationsSlice';
import borrowersReducer from 'handlers/borrowersSlice';
import documentsReducer from 'handlers/documentsSlice';
import tasksReducer from 'handlers/tasksSlice';
import snacksReducer from 'handlers/snacksSlice';
import productsReducer from 'handlers/productsSlice';
import applicationDetailsCardsReducer from 'handlers/applicationDetailsCardsSlice';
import borrowerProfileCardsReducer from 'handlers/borrowerProfileCardsSlice';
import applicationDocumentConfigurationReducer from 'handlers/applicationDocumentConfigurationSlice';
import { IReduxState } from 'types/ReduxState';

const appReducer = combineReducers<IReduxState>({
  auth: authReducer,
  notifications: notificationsReducer,
  settings: settingsReducer,
  applications: applicationsReducer,
  borrowers: borrowersReducer,
  applicationDocuments: documentsReducer,
  tasks: tasksReducer,
  snacks: snacksReducer,
  products: productsReducer,
  applicationDetailsCards: applicationDetailsCardsReducer,
  borrowerProfileCards: borrowerProfileCardsReducer,
  applicationDocumentsConfiguration: applicationDocumentConfigurationReducer,
});

const rootReducer = (state: IReduxState | undefined, action: AnyAction): CombinedState<IReduxState> => {
  if (!state) {
    return appReducer(state, action);
  }

  let nextState: Partial<IReduxState> = state;

  if (action.type === `${AccountsActionType.Logout}/fulfilled` || action.type === `${AccountsActionType.Logout}/rejected`) {
    nextState = {
      settings: state.settings,
    };
  }

  return appReducer(nextState as IReduxState, action);
};

export default rootReducer;
