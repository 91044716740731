import { FC } from 'react';
import TableBodyCell from 'components/digifi-wrappers/TableBodyCell';
import formatDate, { DateTimeFormat } from 'utils/formatDate';
import { TasksTableColumnSizes } from 'components/ApplicationDetails/TasksTableRow/TasksTableColumnSizes';
import Tag from 'components/digifi-wrappers/Tag';
import { ITask, TaskStatus } from 'api/digifi/los/TasksApi';
import { CheckMarkIcon, SendResponseIcon, UndoIcon, UploadIcon } from 'static/images';
import styles from './TasksTableRow.module.scss';
import clsx from 'clsx';
import SvgIconStyled from 'components/BrandingHelpers/SvgIconStyled';
import ActionContainerStyled from 'components/BrandingHelpers/ActionContainerStyled';
import Spinner from 'components/digifi-wrappers/Spinner';
import WrapperWithTooltip from 'components/digifi-wrappers/Tooltip';

interface ITasksTableRowProps {
  task: ITask;
  onUploadIconClick: () => void;
  onSendCommentIconClick: () => void;
  onCheckmarkIconClick: () => void;
  onUndoIconClick: (id: string) => void;
  undoingTaskInProgress?: Record<string, boolean>;
}

const SPINNER_SIZE = 24;

const TasksTableRow: FC<ITasksTableRowProps> = ({
  task,
  onUploadIconClick,
  onSendCommentIconClick,
  onCheckmarkIconClick,
  onUndoIconClick,
  undoingTaskInProgress,
}) => {
  const getStatusColor = (status: TaskStatus) => {
    return status === TaskStatus.NotDone ? 'red': 'blue';
  };

  const renderDesktopTableCells = () => {
    return (
      <>
        <TableBodyCell width={TasksTableColumnSizes.Description} overflowed className={styles.tableCellDesktop}>
          {task.description}
        </TableBodyCell>
        <TableBodyCell width={TasksTableColumnSizes.Status} className={styles.tableCellDesktop}>
          <Tag color={getStatusColor((task.status))}>{task.status}</Tag>
        </TableBodyCell>
        <TableBodyCell width={TasksTableColumnSizes.DueDate} className={styles.tableCellDesktop}>
          {!!task.dueDate && formatDate(task.dueDate, DateTimeFormat.LongMonthDate)}
        </TableBodyCell>
        <TableBodyCell width={TasksTableColumnSizes.Actions} noPadding className={styles.tableCellDesktop}>
          <div className={styles.actionsContainer}>
            {task.status === TaskStatus.NotDone
              ? (
                <>
                <WrapperWithTooltip tooltip='Upload Document'>
                  <span>
                    <SvgIconStyled className={styles.filledIconContainer} onClick={onUploadIconClick}>
                      <UploadIcon />
                    </SvgIconStyled>
                  </span>
                </WrapperWithTooltip>
                  <WrapperWithTooltip tooltip='Send Comment'>
                    <span>
                      <SvgIconStyled className={styles.filledIconContainer} onClick={onSendCommentIconClick}>
                        <SendResponseIcon />
                      </SvgIconStyled>
                    </span>
                  </WrapperWithTooltip>
                  <WrapperWithTooltip tooltip='Mark As Done'>
                    <span>
                      <SvgIconStyled className={styles.filledIconContainer} onClick={onCheckmarkIconClick}>
                        <CheckMarkIcon />
                      </SvgIconStyled>
                    </span>
                  </WrapperWithTooltip>
                </>
              )
              : (
                <WrapperWithTooltip tooltip='Back To Not Done'>
                  <span>
                    {undoingTaskInProgress?.[task.id] ? <Spinner size={SPINNER_SIZE} /> : (
                      <SvgIconStyled className={styles.filledIconContainer} onClick={() => onUndoIconClick(task.id)}>
                        <UndoIcon />
                      </SvgIconStyled>
                    )}
                  </span>
                </WrapperWithTooltip>
              )
            }
          </div>
        </TableBodyCell>
      </>
    );
  };

  const renderMobileTableCell = () => {
    return (
      <TableBodyCell className={styles.tableCellMobile} noPadding>
        <div className={styles.taskTitleContainerMobile}>
          <div className={styles.taskTitleMobile}>{task.description}</div>
          <Tag color={getStatusColor((task.status))}>{task.status}</Tag>
        </div>
        <div className={styles.taskTableCellSection}>
          {!!task.dueDate && (
            <div>
              <div className={styles.columnTitleMobile}>Due Date</div>
              {formatDate(task.dueDate, DateTimeFormat.LongMonthDate)}
            </div>
          )}
        </div>
        <div>
          <div className={styles.columnTitleMobile}>Actions</div>
          <div className={styles.actionsContainer}>
            {task.status === TaskStatus.NotDone
              ? (
                <>
                  <div className={styles.actionContainerMobile} onClick={onUploadIconClick}>
                    <SvgIconStyled disableHover className={styles.actionIconMobile}>
                      <UploadIcon />
                    </SvgIconStyled>
                    <ActionContainerStyled>Upload Document</ActionContainerStyled>
                  </div>
                  <div className={styles.actionContainerMobile} onClick={onSendCommentIconClick}>
                    <SvgIconStyled disableHover className={styles.actionIconMobile}>
                      <SendResponseIcon />
                    </SvgIconStyled>
                    <ActionContainerStyled>Send Response</ActionContainerStyled>
                  </div>
                  <div className={styles.actionContainerMobile} onClick={onCheckmarkIconClick}>
                    <SvgIconStyled disableHover className={clsx(styles.checkmarkIcon, styles.actionIconMobile)}>
                      <CheckMarkIcon />
                    </SvgIconStyled>
                    <ActionContainerStyled>Mark As Done</ActionContainerStyled>
                  </div>
                </>
              )
              : (
                <div className={styles.actionContainerMobile} onClick={() => onUndoIconClick(task.id)}>
                  {undoingTaskInProgress?.[task.id] ? <Spinner size={SPINNER_SIZE} /> : (
                    <>
                      <SvgIconStyled disableHover className={clsx(styles.undoIcon, styles.actionIconMobile, styles.undoIcon)}>
                        <UndoIcon />
                      </SvgIconStyled>
                      <ActionContainerStyled>Back To Not Done</ActionContainerStyled>
                    </>
                  )}
                </div>
              )
            }
          </div>
        </div>
      </TableBodyCell>
    );
  };

  return (
    <>
      {renderDesktopTableCells()}
      {renderMobileTableCell()}
    </>
  );
};

export default TasksTableRow;
