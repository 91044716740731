import { FC } from 'react';
import clsx from 'clsx';
import styles from 'components/Notifications/NotificationsLayout/Notifications.module.scss';
import { useAppSelector } from 'hooks/reduxHooks';
import { selectAllNotifications } from 'handlers/notificationsSlice';
import ConnectedNotifications from 'components/Notifications/ConnectedNotifications/ConnectedNotifications';

const LayoutNotification: FC = () => {
  const notificationsList = useAppSelector(selectAllNotifications);

  const className = clsx(
    styles.mainLayoutNotifications,
    styles.leftNavigationCollapsed,
    notificationsList.length === 0 && styles.empty,
  );

  return <ConnectedNotifications className={className} />;
};

export default LayoutNotification;
