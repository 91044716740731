import React, { ReactNode } from 'react';
import PopUpHeader from './PopUpHeader';
import { CloseImage } from 'product_modules/static/images';

import styles from './PopUpHeaderWithClose.module.scss';
import clsx from 'clsx';

interface PopUpHeaderWithCloseProps {
  title: ReactNode;
  subTitle?: ReactNode;
  titleTooltip?: React.ReactNode;
  titleIcons?: React.ReactNode;
  onClose?: () => void;
  closable?: boolean;
  className?: string
  titleClassName?: string;
  closeIconClassName?: string;
}

const PopUpHeaderWithClose = ({ title, subTitle, onClose, closable = true, titleTooltip, titleIcons, className, closeIconClassName, titleClassName }: PopUpHeaderWithCloseProps) => {
  return (
    <PopUpHeader
      title={title}
      titleTooltip={titleTooltip}
      titleIcons={titleIcons}
      className={clsx(styles.header, className)}
      titleClassName={titleClassName}
    >
      <button
        type="button"
        className={clsx(styles.closeBtn, !closable && styles.closeBtn__disabled, closeIconClassName)}
        onClick={onClose}
        disabled={!closable}
      >
        <CloseImage />
      </button>
      {subTitle}
    </PopUpHeader>
  );
};

export default PopUpHeaderWithClose;
