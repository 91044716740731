import React, { FC, useRef } from 'react';
import { useFormik } from 'formik';
import PasswordInput from 'components/digifi-wrappers/PasswordInput/PasswordInput';
import { validatePassword } from 'validation';
import getMessage from 'constants/Messages';
import SubmitButton from 'components/common/SubmitButton';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';

export interface ICreateNewPasswordContextType {
  password: string;
  confirmPassword: string;
}

interface ICreateNewPasswordFormProps {
  handleSubmit: (values: ICreateNewPasswordContextType) => Promise<void>;
  isLoading?: boolean;
}

const CreateNewPasswordForm: FC<ICreateNewPasswordFormProps> = ({ handleSubmit, isLoading }) => {
  const confirmPasswordInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const { setFieldError, errors, values, handleChange } = useFormik<ICreateNewPasswordContextType>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleSubmit,
  });

  const isButtonEnabled = !validatePassword(values.password)
    && !validatePassword(values.confirmPassword)
    && values.password === values.confirmPassword;

  const clearFieldErrorOnFocus = (fieldName: string) => {
    setFieldError(fieldName, '');
  };

  const handlePasswordBlur = (currentPassword: string, name: string) => {
    const error = validatePassword(currentPassword);
    setFieldError(name, error === null ? '' : getMessage(error));
  };

  return (
    <div>
      <PasswordInput
        name="password"
        labelTitle="Password"
        required
        value={values.password}
        onChange={handleChange}
        onBlur={() => handlePasswordBlur(values.password, 'password')}
        onFocus={() => clearFieldErrorOnFocus('password')}
        errorMessage={errors.password}
        disabled={isLoading}
        onKeyDown={TriggerEventOnEnterKeyDown(confirmPasswordInputRef, 'focus')}
      />
      <PasswordInput
        name="confirmPassword"
        labelTitle="Confirm Password"
        required
        value={values.confirmPassword}
        onChange={handleChange}
        onBlur={() => handlePasswordBlur(values.confirmPassword, 'confirmPassword')}
        onFocus={() => clearFieldErrorOnFocus('confirmPassword')}
        errorMessage={errors.confirmPassword}
        disabled={isLoading}
        ref={confirmPasswordInputRef}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
      />
      <SubmitButton
        title="Save New Password"
        isButtonEnabled={isButtonEnabled}
        onSubmit={() => handleSubmit(values)}
        ref={submitButtonRef}
      />
    </div>
  );
};

export default CreateNewPasswordForm;
