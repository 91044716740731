import { Routes, Route } from 'react-router-dom';
import PhoneVerification from 'pages/auth/PhoneVerification';
import ResetPassword from 'pages/auth/ResetPassword';
import CreateNewPassword from 'pages/auth/CreateNewPassword';
import SignIn from 'pages/auth/SignIn';
import EmailVerification from 'pages/auth/EmailVerification';
import CreateAccount from 'pages/auth/CreateAccount';
import FilePreviewPage from 'pages/applications/FilePreviewPage';
import ApplicationDetails from 'pages/applications/ApplicationDetails';
import RedirectHandler from 'components/RedirectHandler/RedirectHandler';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AppRouteWithParams } from 'enums/AppRouteWithParams';
import ApplicationsList from 'pages/applications/ApplicationsList';
import NotFoundPage from 'pages/notFound';
import ForbiddenPage from 'pages/forbidden';
import CustomHtmlHead from 'components/PageLayout/CustomHtmlHead';
import WorkflowWrapper from 'components/PageLayout/WorkflowWrapper';

export const history = createBrowserHistory({ window });

const Router = () => (
  <HistoryRouter history={history}>
    <CustomHtmlHead />
    <WorkflowWrapper>
      <RedirectHandler />
      <Routes>
        <Route path={AppRouteWithParams.CreateAccount} element={<CreateAccount />} />
        <Route path={AppRouteWithParams.AcceptInvite} element={<CreateAccount />} />
        <Route path={AppRouteWithParams.SignIn} element={<SignIn />} />
        <Route path={AppRouteWithParams.ConfirmEmail} element={<EmailVerification />} />
        <Route path={AppRouteWithParams.PhoneVerification} element={<PhoneVerification />} />
        <Route path={AppRouteWithParams.ResetPassword} element={<ResetPassword />} />
        <Route path={AppRouteWithParams.CreateNewPassword} element={<CreateNewPassword />} />
        <Route path={AppRouteWithParams.DocumentPreview} element={<FilePreviewPage />} />
        <Route path={AppRouteWithParams.ResumeProcess} element={<ApplicationsList />} />
        <Route path={AppRouteWithParams.Application} element={<ApplicationDetails />} />
        <Route path={AppRouteWithParams.Forbidden} element={<ForbiddenPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </WorkflowWrapper>
  </HistoryRouter>
);

export default Router;
