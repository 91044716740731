import React, { ChangeEvent, FocusEvent, useMemo, useCallback, KeyboardEvent } from 'react';
import { VariableValue } from 'product_modules/api/Types';
import TextInput from 'product_modules/components/TextInput';
import {
  AddressWithAttributes,
  DataType,
  DateWithAttributes,
  IdentificationNumberWithAttributes,
  ListWithAttributes,
  MonetaryWithAttributes,
  PhoneNumberWithAttributes,
  VariableCapAttributes,
  VisualDataTypeWithAttributes,
} from 'product_modules/components/Variables/VariablesTypes';
import DatePicker from 'product_modules/components/DatePicker';
import AutoCompletion from 'product_modules/components/AutoCompletion';
import MonetaryInput from 'product_modules/components/MonetaryInput';
import PercentageInput from 'product_modules/components/PercentageInput';
import NumberInput from 'product_modules/components/NumberInput';
import { Option } from 'product_modules/components/SelectInput/SelectInput';
import PhoneNumberInput from 'product_modules/components/PhoneNumberInput/PhoneNumberInput';
import IdentificationNumberInput from 'product_modules/components/IdentificationNumberInput';
import AddressInput from 'product_modules/components/AddressInput';
import { getCurrencySymbol } from 'product_modules/components/CurrencySelect/currencies';
import { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import { InputSize } from 'product_modules/components/DatePicker/Input';
import EmailInput from 'product_modules/components/EmailInput';
import useInputValidation from 'product_modules/hooks/useInputValidation';
import getValidationMessage from './getValidationMessage';
import {
  isPhoneNumber,
  isIdentificationNumber,
  isMonetaryDataType,
  isDateDataType,
  isListDataType,
  isAddressDataType,
} from 'product_modules/components/Variables/utils';
import { convertVariableValueByDataType, convertVariableValueToString } from 'product_modules/utils/coerceUtils';
import styles from './InputWithDataType.module.scss';

export interface InputWithDataTypeProps {
  value: VariableValue;
  formatType: DataType;
  options: Option[] | null;
  labelTitle: string;
  titleHint?: string;
  onChange: (value: VariableValue) => void;
  readOnly?: boolean;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabledValidation?: boolean;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  hasLeftNeighbour?: boolean;
  containerClassName?: string;
  inputSize?: InputSize;
  inputIcon?: React.ReactNode;
  disableCapAttributesValidation?: boolean;
  disableVariableValueFormatting?: boolean;
  raw?: boolean;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

const BOOLEAN_VALUE_DROPDOWN_OPTIONS: Option[] = [
  { name: 'True', value: 'true' },
  { name: 'False', value: 'false' },
];

const TEXT_DATA_TYPE_MAX_ROWS = 4;
export type InputWithType = InputWithDataTypeProps & VisualDataTypeWithAttributes & VariableCapAttributes;

const InputWithDataType = React.memo((props: InputWithType) => {
  const {
    dataType,
    value,
    labelTitle,
    titleHint,
    onChange,
    onFocus,
    onBlur,
    readOnly,
    disabled,
    required,
    showLoader,
    loaderState,
    onLoaderStateReset,
    disabledValidation,
    tabIndex,
    hasLeftNeighbour,
    containerClassName,
    inputSize,
    inputIcon,
    raw,
    disableCapAttributesValidation,
    disableVariableValueFormatting,
    maxAllowedValue,
    minAllowedValue,
    formatType,
    onKeyDown,
  } = props;
  const validators = useMemo(() => {
    return [(valueToValidate: string) => getValidationMessage(props, valueToValidate, {
      disableCapAttributesValidation,
    })];
  }, [
    dataType,
    minAllowedValue,
    maxAllowedValue,
    disableCapAttributesValidation,
    (props as PhoneNumberWithAttributes).phoneNumberFormat,
    (props as PhoneNumberWithAttributes).phoneNumberWithFlag,
    (props as IdentificationNumberWithAttributes).identificationNumberType,
    (props as IdentificationNumberWithAttributes).identificationNumberDescription,
    (props as MonetaryWithAttributes).currency,
    (props as DateWithAttributes).dateFormat,
    (props as ListWithAttributes).optionsList,
    (props as AddressWithAttributes).country,
    (props as AddressWithAttributes).getGeocode,
    (props as AddressWithAttributes).getSuggestions,
    (props as AddressWithAttributes).classNames,
  ]);

  const valuesAsString = useMemo(() => {
    return convertVariableValueToString(value);
  }, [value]);

  const [
    handleBlur,
    handleFocus,
    errorMessage,
    ,
    showErrorMessage,
    hideErrorMessage,
  ] = useInputValidation({
    validators,
    value: valuesAsString,
    onFocus,
    onBlur,
    required,
    disabledValidation,
  });

  const handleChangeWithFormatting = useCallback((updatedValue: VariableValue) => {
    onChange(convertVariableValueByDataType(updatedValue, formatType));
  }, [onChange, formatType]);

  const handleOptionChange = useCallback(({ value: optionValue }: Option) => {
    if (disableVariableValueFormatting) {
      onChange(optionValue);

      return;
    }

    handleChangeWithFormatting(optionValue);
  }, [handleChangeWithFormatting, disableVariableValueFormatting, onChange]);

  const commonInputProps = {
    errorMessage,
    labelTitle,
    titleHint,
    readOnly,
    disabled,
    value: valuesAsString,
    onBlur: handleBlur,
    onFocus: handleFocus,
    onChange: disableVariableValueFormatting ? onChange : handleChangeWithFormatting,
    showLoader,
    loaderState,
    onLoaderStateReset,
    tabIndex,
    hasLeftNeighbour,
    containerClassName,
    inputIcon,
    raw,
    required,
    onKeyDown,
  };

  if (isPhoneNumber(props)) {
    const { phoneNumberFormat, phoneNumberWithFlag } = props;
    return (
      <PhoneNumberInput
        {...commonInputProps}
        withFlag={phoneNumberWithFlag}
        country={phoneNumberFormat}
      />
    );
  }

  if (isIdentificationNumber(props)) {
    const { identificationNumberType, identificationNumberDescription } = props;
    return (
      <IdentificationNumberInput
        {...commonInputProps}
        identificationNumberType={identificationNumberType!}
        identificationNumberDescription={identificationNumberDescription}
      />
    );
  }

  if (isMonetaryDataType(props)) {
    const { currency } = props;
    const currencySymbol = currency ? getCurrencySymbol(currency) : null;

    return (
      <MonetaryInput
        {...commonInputProps}
        placeholder={dataType}
        currencySymbol={currencySymbol}
      />
    );
  }

  if (isDateDataType(props)) {
    const { dateFormat } = props;

    return (
      <DatePicker
        {...commonInputProps}
        inputSize={inputSize}
        dateFormat={dateFormat!}
        showErrorMessage={showErrorMessage}
        hideErrorMessage={hideErrorMessage}
      />
    );
  }

  if (isListDataType(props)) {
    const selectedOption = valuesAsString
      ? {
        name: valuesAsString,
        value: valuesAsString,
      }
      : undefined;

    const formattedOptionsList = props.optionsList.map((option) => ({
      name: option,
      value: option,
    }));

    return (
      <AutoCompletion
        {...commonInputProps}
        selectedOption={selectedOption}
        options={formattedOptionsList || []}
        onChange={handleOptionChange}
        placeholder={dataType}
        hasRightNeighbour
      />
    );
  }

  if (isAddressDataType(props)) {
    const { getSuggestions, getGeocode, country, classNames } = props;
    const addressInputValue = typeof value === 'object' ? value : null;

    return (
      <AddressInput
        {...commonInputProps}
        placeholder={labelTitle}
        value={addressInputValue}
        getSuggestions={getSuggestions}
        getGeocode={getGeocode}
        country={country}
        classNames={classNames}
      />
    );
  }

  switch (dataType) {
    case 'Percentage':
      return <PercentageInput {...commonInputProps} placeholder={dataType} />;
    case 'Number':
      return (
        <NumberInput
          {...commonInputProps}
          onBlur={(_value, event) => handleBlur(event)}
          placeholder={dataType}
        />
      );
    case 'Boolean': {
      const getAutoCompletionProps = () => {
        if (raw && !BOOLEAN_VALUE_DROPDOWN_OPTIONS.some((option) => option.value === value)) {
          const selectedOption = {
            name: valuesAsString,
            value: valuesAsString,
          };
          return {
            ...commonInputProps,
            selectedOption,
          };
        }
        return commonInputProps;
      }

      return (
        <AutoCompletion
          {...getAutoCompletionProps()}
          options={BOOLEAN_VALUE_DROPDOWN_OPTIONS}
          onChange={handleOptionChange}
          placeholder={dataType}
          hasRightNeighbour
        />
      );
    }
    case 'EmailAddress':
      return <EmailInput {...commonInputProps} onFocus={handleFocus} onBlur={handleBlur} />;
    default:
      return (
        <div className={styles.container}>
          <TextInput
            {...commonInputProps}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.value)}
            hasRightNeighbour
            placeholder={dataType}
            multiline={dataType === 'Text'}
            maxRows={dataType === 'Text' ? TEXT_DATA_TYPE_MAX_ROWS : undefined}
          />
        </div>
      );
  }
});

export default InputWithDataType;
