import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import CardsRestApi, { IApplicationDocumentConfiguration } from 'api/digifi/los/CardsApi';

enum ApplicationDocumentsConfigurationActionType {
  GetApplicationDocumentsConfiguration = 'applicationDocumentsConfiguration/getApplicationDocumentsConfiguration',
}

const applicationDocumentsConfigurationAdapter = createEntityAdapter<IApplicationDocumentConfiguration>();

const initialState = applicationDocumentsConfigurationAdapter.getInitialState();

const cardsRestApi = new CardsRestApi();

export const getApplicationDocumentsConfiguration = createAsyncThunk(
  ApplicationDocumentsConfigurationActionType.GetApplicationDocumentsConfiguration,
  async (productId: string) => {
    return cardsRestApi.getApplicationDocumentsConfiguration(productId);
  },
);

const applicationDocumentsConfigurationSlice = createSlice({
  name: 'applicationDocumentsConfigurationSlice',
  initialState,
  reducers: {
    removeApplicationDocumentsConfiguration(state) {
      applicationDocumentsConfigurationAdapter.removeAll(state);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getApplicationDocumentsConfiguration.fulfilled, (state, { payload }) => {
        applicationDocumentsConfigurationAdapter.setAll(state, payload);
      });
  },
});

export const { selectAll: selectApplicationDocumentsConfiguration } = applicationDocumentsConfigurationAdapter.getSelectors((state: RootState) => state.applicationDocumentsConfiguration);
export const { removeApplicationDocumentsConfiguration } = applicationDocumentsConfigurationSlice.actions;

export default applicationDocumentsConfigurationSlice.reducer;
