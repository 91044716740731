import { useAppSelector } from 'hooks/reduxHooks';
import {
  getBorrowerApplications,
  ITEMS_PER_PAGE,
  setPriorApplicationsPaginationData,
} from 'handlers/applicationsSlice';
import { batch, useDispatch } from 'react-redux';
import { PaginationProps } from 'components/digifi-wrappers/Pagination';

const usePagination = (): PaginationProps => {
  const dispatch = useDispatch();
  const { page, total, pagesCount } = useAppSelector((state) => state.applications.priorApplications);

  const nextPage = () => {
    if (page < pagesCount) {
      batch(() => {
        dispatch(setPriorApplicationsPaginationData({ page: page + 1, items: null, offset: page * ITEMS_PER_PAGE }));
        dispatch(getBorrowerApplications());
      });
    }
  };

  const prevPage = () => {
    if (page > 1) {
      batch(() => {
        dispatch(setPriorApplicationsPaginationData({ page: page - 1, items: null, offset: (page - 2) * ITEMS_PER_PAGE }));
        dispatch(getBorrowerApplications());
      });
    }
  };

  const setPage = (pageNumber: number) => {
    batch(() => {
      dispatch(setPriorApplicationsPaginationData({ page: pageNumber, items: null, offset: (pageNumber - 1) * ITEMS_PER_PAGE }));
      dispatch(getBorrowerApplications());
    });
  };

  return {
    page,
    pagesCount,
    prevPage,
    nextPage,
    setPage,
    itemsTotal: total,
    itemsPerPage: ITEMS_PER_PAGE,
  };
};

export default usePagination;
