import React from 'react';
import clsx from 'clsx';
import { SkeletonRectangle, SkeletonText } from 'product_modules/components/Skeleton';
import SkeletonColorType from 'product_modules/components/Skeleton/SkeletonColorType';
import styles from './SkeletonCardsForm.module.scss';

export interface SkeletonCardsFormProps {
  layout: Array<Array<number>>;
  maxColumnsPerCard?: number;
  textColor?: SkeletonColorType;
}

const DEFAULT_MAX_COLUMN_PER_CARD = 2;

const SkeletonCardsForm = ({ layout, maxColumnsPerCard = DEFAULT_MAX_COLUMN_PER_CARD, textColor = 'primary6' }: SkeletonCardsFormProps) => {
  const renderSkeletonFields = (length: number) => {
    return new Array(length).fill(null).map((value, index) => {
      const isLastFieldInColumn = index + maxColumnsPerCard >= length;

      return (
        <div className={clsx(styles.field, isLastFieldInColumn && styles.lastFieldInColumn)} key={index}>
          <SkeletonText lineHeight="44px" width="100%" lineWidth="66%" height="14px" color={textColor} />
          <SkeletonText
            className={styles.fieldValue}
            lineHeight="44px"
            width="100%"
            lineWidth="44%"
            height="14px"
            color={textColor}
          />
        </div>
      );
    });
  };

  const renderCard = (fieldsCount: number, index: number) => {
    const fieldsContainerStyle = {
      gridTemplateColumns: `repeat(${maxColumnsPerCard}, minmax(0, 1fr))`,
    };

    return (
      <div className={styles.card} key={index}>
        <SkeletonRectangle width="240px" height="20px" color="primary20" marginBottom="24px" />
        <div style={fieldsContainerStyle} className={styles.fieldsContainer}>
          {renderSkeletonFields(fieldsCount)}
        </div>
      </div>
    );
  }

  const renderRow = (row: Array<number>, rowIndex: number) => (
    <div className={styles.row} key={rowIndex}>
      {row.map((fieldsCount, index) => renderCard(fieldsCount, index))}
    </div>
  )

  return (
    <div className={styles.container}>
      {layout.map((row, index) => renderRow(row, index))}
    </div>
  );
};

export default SkeletonCardsForm;
