import { FC, useState } from 'react';
import SendResponseForm from 'components/ApplicationDetails/SendResponseForm';
import PopUp from 'components/digifi-wrappers/PopUp';
import PopUpContent from 'components/digifi-wrappers/PopUpContent';
import styles from './SendResponsePopUp.module.scss';

interface ICreateCommentPopUpProps {
  onClose: () => void;
  onCreate: (message: string) => void;
  isSendingResponseInProgress?: boolean;
}

const SendResponsePopUp: FC<ICreateCommentPopUpProps> = ({ onClose, onCreate, isSendingResponseInProgress }) => {
  const [commentMessage, setCommentMessage] = useState<string>('');

  const handleSubmit = async () => {
    onCreate(commentMessage);
  };

  return (
    <PopUp
      title="Send Response"
      closePopUp={onClose}
      classNames={{
        sectionClassName: styles.popUpContainer,
        header: styles.popUpHeader,
      }}
    >
      <PopUpContent hasTopMargin className={styles.popUpContent}>
        <SendResponseForm
          isLoading={isSendingResponseInProgress}
          commentMessage={commentMessage}
          onCommentMessageChange={(updatedCommentMessage) => setCommentMessage(updatedCommentMessage)}
          onSubmit={handleSubmit}
        />
      </PopUpContent>
    </PopUp>
  );
};

export default SendResponsePopUp;
