import { FC } from 'react';
import InputWithDataType, { InputWithType } from 'product_modules/components/InputWithDataType';

const InputWithDataTypeWrapper: FC<InputWithType> = (props) => {
  return (
    <InputWithDataType {...props} />
  );
};

export default InputWithDataTypeWrapper;
