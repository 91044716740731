import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import WrapperWithTooltip, { TooltipPosition } from 'product_modules/components/Tooltip';

import styles from './NoEditPermissionsTooltip.module.scss';

type NoEditPermissionsTooltipProps = {
  className?: string;
  children: ReactNode;
  shouldShowTooltip: boolean;
  tooltipPosition?: TooltipPosition;
  statusName?: string;
};

const NoEditPermissionsTooltip: FC<NoEditPermissionsTooltipProps> = ({
  className,
  children,
  shouldShowTooltip,
  statusName,
  tooltipPosition = 'left',
}) => {
  const renderTooltip = () => {
    if (!shouldShowTooltip) {
      return null;
    }

    return `You do not have permission to edit applications in the ${statusName || 'current'} status`;
  };

  return (
    <WrapperWithTooltip tooltip={renderTooltip()} position={tooltipPosition}>
      <div className={clsx(styles.childrenWrapper, className)}>{children}</div>
    </WrapperWithTooltip>
  );
};

export default NoEditPermissionsTooltip;
