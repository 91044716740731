import { FC } from 'react';
import TabContent from 'components/ApplicationDetails/TabContent';
import DocumentsTable from 'components/ApplicationDetails/DocumentsTable';
import {
  ApplicationDocumentsSortingField,
  ApplicationDocumentsSortingType,
  IApplicationDocument,
} from 'api/digifi/los/DocumentsApi';

interface IApplicationDocumentsTabProps {
  sortingType: ApplicationDocumentsSortingType;
  onSort: (sortingType: ApplicationDocumentsSortingType) => void;
  documents: IApplicationDocument[];
  onAllDocumentsDownloadClick: () => void;
  onDocumentDownloadClick: (document: IApplicationDocument) => void;
  onDocumentClick: (document: IApplicationDocument) => void;
  isLoading?: boolean;
}

const ApplicationDocumentsTab: FC<IApplicationDocumentsTabProps> = ({
  sortingType,
  documents,
  isLoading,
  onAllDocumentsDownloadClick,
  onDocumentDownloadClick,
  onDocumentClick,
  onSort,
}) => {
  const handleSort = (field: ApplicationDocumentsSortingField, ascending: boolean) => {
    onSort({ field, ascending });
  };

  return (
    <TabContent tabTitle="Application Documents">
      <DocumentsTable
        documents={documents}
        onAllDocumentsDownloadClick={onAllDocumentsDownloadClick}
        onDocumentDownloadClick={onDocumentDownloadClick}
        isLoading={isLoading}
        onDocumentClick={onDocumentClick}
        sortingType={sortingType}
        onSort={handleSort}
      />
    </TabContent>
  );
};

export default ApplicationDocumentsTab;
