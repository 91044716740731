import { VariableValue } from 'product_modules/api/LoanOriginationSystem/Types';
import { isEmptyVariableValue } from 'product_modules/utils/isEmptyVariableValue';

const isVariableValueChanged = (newVariableValue: VariableValue, initialVariableValue: VariableValue) => {
  if (isEmptyVariableValue(newVariableValue) && isEmptyVariableValue(initialVariableValue)) {
    return false;
  }

  if (typeof newVariableValue === 'object' && typeof initialVariableValue === 'object') {
    return isConfigurableFormDataChanged(
      (newVariableValue || {}) as Record<string, VariableValue>,
      (initialVariableValue || {}) as Record<string, VariableValue>,
    );
  }

  return newVariableValue !== initialVariableValue;
};

export const isConfigurableFormDataChanged = (
  initialData: Record<string, VariableValue>,
  newData: Record<string, VariableValue>,
): boolean => {
  return Object.keys(newData).some((key) => isVariableValueChanged(newData[key], initialData[key]));
};

export const getConfigurableFormDataDifference = (
  initialData: Record<string, VariableValue> | null,
  newData: Record<string, VariableValue> | null,
) => {
  if (!initialData || !newData) {
    return initialData === null
      ? (newData || {})
      : (initialData || {});
  }

  return Object.keys(newData).reduce((previousVariables, key) => ({
    ...previousVariables,
    ...(isVariableValueChanged(newData[key], initialData[key]) ? { [key]: newData[key] } : {}),
  }), {});
}
