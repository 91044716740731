import { FC } from 'react';
import { SkeletonApplicationOverview } from 'components/ApplicationDetails/ApplicationOverview';
import { SkeletonOutstandingTasksInfo } from 'components/ApplicationDetails/OutstandingTasksInfo';
import { SkeletonTasksTable } from 'components/ApplicationDetails/TasksTable';
import styles from './ApplicationDocumentsDetailsModal.module.scss';
import TabSwitch from 'components/digifi-wrappers/TabSwitch';
import { ITabProps } from 'components/ApplicationDetails/ApplicationTabs/ApplicationTabs';
import TabContent from 'components/ApplicationDetails/TabContent';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';

interface ISkeletonApplicationDetailsModal {
  tabs: ITabProps[];
  selectedTabId: string | null;
}

const SkeletonApplicationDetailsModal: FC<ISkeletonApplicationDetailsModal> = ({ tabs, selectedTabId }) => {
  const selectionOption = tabs.find(({ id }) => id === selectedTabId) || tabs[0];

  const renderTabTitle = () => {
    return (
      <SkeletonText width="220px" height="16px" color="primary20" lineHeight="28px" />
    );
  };

  return (
    <div>
      <SkeletonOutstandingTasksInfo />
      <SkeletonApplicationOverview />
      <div className={styles.tabsContainer}>
        <TabSwitch tabs={tabs} selectedTabId={selectionOption.id} />
      </div>
      <TabContent tabTitle={renderTabTitle()}>
        <SkeletonTasksTable />
      </TabContent>
    </div>
  );
};

export default SkeletonApplicationDetailsModal;
