import { VariableValue } from 'product_modules/api/LoanOriginationSystem/Types';
import {
  isEmptyField,
  validateEmail,
  validateIDNumber,
  validateMaxDateCap,
  validateMaxNumberCap,
  validateMinDateCap,
  validateMinNumberCap,
  validatePhoneNumber,
} from 'product_modules/utils/validation/validation';
import { Variable, VisualDataType } from 'product_modules/components/Variables/VariablesTypes';
import { getVisualDataType } from 'product_modules/components/Variables/utils';

interface VariableValueValidator {
  (variable: Variable, value: VariableValue): boolean;
}

const isNumberOrStringVariableValue = (value: VariableValue): value is string | number => {
  return typeof value === 'number' || typeof value === 'string';
};

const isStringVariableValue = (value: VariableValue): value is string => {
  return typeof value === 'string';
}

const isEmptyVariableField = (value: VariableValue) => {
  return value === null || value === undefined || value === '' || isEmptyField(value.toString());
};

const isOptionalStringVariableValue = (value: VariableValue): value is Exclude<VariableValue, number | boolean | object> =>
  typeof value !== 'number' && typeof value !== 'boolean' && (typeof value !== 'object' || value === null);

const validateEmailVariable: VariableValueValidator = (variable, value) => {
  if (!isOptionalStringVariableValue(value)) {
    return false;
  }

  return !validateEmail(value || '');
};

const validateIDNumberVariable: VariableValueValidator = (variable, value) => {
  const { identificationNumberType } = variable;

  if (!isOptionalStringVariableValue(value)) {
    return false;
  }

  return !validateIDNumber(value || '', identificationNumberType!);
};

const validatePhoneNumberVariable: VariableValueValidator = (variable, value) => {
  if (!isOptionalStringVariableValue(value)) {
    return false;
  }

  return !validatePhoneNumber(value || '');
};

const validateVariableMinNumberCap: VariableValueValidator = (variable, value) => {
  return isNumberOrStringVariableValue(value) && !validateMinNumberCap(value, variable.minAllowedValue);
};

const validateVariableMaxNumberCap: VariableValueValidator = (variable, value) => {
  return isNumberOrStringVariableValue(value) && !validateMaxNumberCap(value, variable.maxAllowedValue);
};

const validateVariableMinDateCap: VariableValueValidator = (variable, value) => {
  return isStringVariableValue(value) && !validateMaxDateCap(value, variable.maxAllowedValue);
};

const validateVariableMaxDateCap: VariableValueValidator = (variable, value) => {
  return isStringVariableValue(value) && !validateMinDateCap(value, variable.minAllowedValue);
};

export const validatorsByVisualDataTypeMap = new Map<VisualDataType, VariableValueValidator[]>([
  ['EmailAddress', [validateEmailVariable]],
  ['IdentificationNumber', [validateIDNumberVariable]],
  ['PhoneNumber', [validatePhoneNumberVariable]],
  ['Number', [validateVariableMinNumberCap, validateVariableMaxNumberCap]],
  ['Monetary', [validateVariableMinNumberCap, validateVariableMaxNumberCap]],
  ['Percentage', [validateVariableMinNumberCap, validateVariableMaxNumberCap]],
  ['Date', [validateVariableMinDateCap, validateVariableMaxDateCap]],
]);

export const validateVariable = (variable: Variable, value: VariableValue, required?: boolean) => {
  // Skip all validation since field is not required and empty
  if (!required && isEmptyVariableField(value)) {
    return true;
  }

  if (required && isEmptyVariableField(value)) {
    return false;
  }

  const visualDataType = getVisualDataType(variable);
  const validators = validatorsByVisualDataTypeMap.get(visualDataType) || [];

  return validators.every((validator) => validator(variable, value));
};
