import { FC, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAppSelector } from 'hooks/reduxHooks';
import { matchPath, useLocation } from 'react-router-dom';
import { AppRouteWithParams } from 'enums/AppRouteWithParams';
import MontserratRegularFontSrc from 'static/fonts/Montserrat/Montserrat-Regular.ttf';
import MontserratBoldFontSrc from 'static/fonts/Montserrat/Montserrat-Bold.ttf';

interface ICustomHtmlHeadProps {
  applicationsBrowserTitle?: string;
}

const ROUTE_TITLE_MAP: Record<string, string> = {
  [AppRouteWithParams.CreateAccount]: 'Create Account',
  [AppRouteWithParams.SignIn]: 'Sign In',
  [AppRouteWithParams.AcceptInvite]: 'Accept Invite',
  [AppRouteWithParams.PhoneVerification]: 'Phone Verification',
  [AppRouteWithParams.ConfirmEmail]: 'Confirm Email',
  [AppRouteWithParams.ResetPassword]: 'Reset Password',
  [AppRouteWithParams.CreateNewPassword]: 'Create New Password',
};

const CustomHtmlHead: FC<ICustomHtmlHeadProps> = ({ applicationsBrowserTitle }) => {
  const { faviconId, companyName } = useAppSelector((state) => state.settings.branding);
  const [browserTitle, setBrowserTitle] = useState<string>(companyName || '');
  const location = useLocation();

  const getCompanyNamePart = () => {
    return companyName ? `| ${companyName}` : '';
  };

  useEffect(() => {
    const routeTitleMap = Object.entries(ROUTE_TITLE_MAP)
      .find(([ path ]) => matchPath(path, location.pathname));

    const [, routeTitle] = routeTitleMap || [];

    if (routeTitle) {
      return setBrowserTitle(`${routeTitle} ${getCompanyNamePart()}`);
    }

    if (applicationsBrowserTitle) {
      return setBrowserTitle(`${applicationsBrowserTitle} ${getCompanyNamePart()}`);
    }

    return setBrowserTitle(companyName || '');
  }, [location, applicationsBrowserTitle]);

  return (
    <HelmetProvider>
      <Helmet>
        {faviconId && <link rel="shortcut icon" id="favicon" href={`/api/branding/favicon/${faviconId}`} />}
        <link rel="preload" href={MontserratBoldFontSrc} as="font" type="font/ttf" />
        <link rel="preload" href={MontserratRegularFontSrc} as="font" type="font/ttf" />
        <title>{browserTitle}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default CustomHtmlHead;
