import { FC, Fragment, useState } from 'react';
import styles from './ReviewAndSubmitForm.module.scss';
import Checkbox from 'components/digifi-wrappers/Checkbox';
import { ILegalDocument } from 'api/digifi/dlp/SettingsApi';
import CardsForm from 'components/digifi-wrappers/CardsForm';
import {
  IApplicationDetailsCard,
  IApplicationDocumentConfiguration,
  IBaseVariableConfiguration,
  IBorrowerProfileCard,
} from 'api/digifi/los/CardsApi';
import { FormLayoutData } from 'api/Types';
import { VariableValue } from 'types/VariableTypes';
import { IDocumentUploadFile } from 'components/ApplicationForm/forms/DocumentUploadForm/DocumentUploadForm';
import UploadDocumentsReviewSection from 'components/ApplicationForm/UploadDocumentsReviewSection';
import useCardFields from 'hooks/useCardsFields';
import useConditionalDisplayLogic from 'product_modules/hooks/useConditionalDisplayLogic';
import useMobileCards from 'hooks/useMobileCards';
import LinkButton from 'components/digifi-wrappers/LinkButton';
import { formatDisplayTitle } from 'utils/formatDisplayTitle';
import { HandleKeyDown } from 'product_modules/utils/TriggerEventOnEnterKeyDown';
import KeyEventEnum from 'product_modules/common/KeyEventEnum';
import useCalculationsLogic from 'product_modules/hooks/useCalculationsLogic';
import { ProductCalculation } from 'product_modules/api/LoanOriginationSystem/ProductCalculationsApi';
import useFullApplicationData from 'hooks/useFullApplicationData';

interface IReviewAndSubmitFormProps {
  legalDocuments: ILegalDocument[];
  borrowerProfileCards: IBorrowerProfileCard[];
  borrowerFormData: FormLayoutData;
  applicationDetailsCards: IApplicationDetailsCard[];
  applicationDetailsFormData: FormLayoutData;
  documentFiles: IDocumentUploadFile[];
  renderActions: (submitDisabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
  applicationDocumentConfiguration: IApplicationDocumentConfiguration[];
  onBorrowerFieldChange: (variableConfiguration: IBaseVariableConfiguration, value: VariableValue) => void;
  onApplicationDetailsFieldChange: (variableConfiguration: IBaseVariableConfiguration, value: VariableValue) => void;
  onBorrowerProfileEditClick: () => void;
  onApplicationDetailsEditClick: () => void;
  onUploadDocumentsEditClick: () => void;
  borrowerName: string;
  onLegalDocumentClick: (legalDocument: ILegalDocument) => void;
  productCalculations: ProductCalculation[] | null;
}

const ReviewAndSubmitForm: FC<IReviewAndSubmitFormProps> = ({
  legalDocuments,
  borrowerProfileCards,
  applicationDetailsCards,
  borrowerFormData,
  applicationDetailsFormData,
  documentFiles,
  renderActions,
  applicationDocumentConfiguration,
  onBorrowerFieldChange,
  onApplicationDetailsFieldChange,
  onBorrowerProfileEditClick,
  onApplicationDetailsEditClick,
  onUploadDocumentsEditClick,
  borrowerName,
  onLegalDocumentClick,
  productCalculations,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const fullApplicationData = useFullApplicationData({
    borrowerFormData,
    applicationDetailsFormData,
  });
  const fullBorrowerProfileDataWithCalculations = useCalculationsLogic(productCalculations, fullApplicationData);
  const fullApplicationDetailsDataWithCalculations = useCalculationsLogic(productCalculations, fullApplicationData);
  const borrowerProfileFields = useCardFields(borrowerProfileCards);
  const borrowerDisplayFieldsAttributes = useConditionalDisplayLogic(borrowerProfileFields, fullBorrowerProfileDataWithCalculations);
  const applicationDetailsFields = useCardFields(applicationDetailsCards);
  const applicationDetailsDisplayFieldsAttributes = useConditionalDisplayLogic(applicationDetailsFields, fullApplicationDetailsDataWithCalculations);
  const borrowerProfileMobileCards = useMobileCards(borrowerProfileCards);
  const applicationDetailsMobileCards = useMobileCards(applicationDetailsCards);

  const handleCheckboxValueChange = () => {
    setIsChecked(!isChecked);
  };

  const renderLegalDocuments = () => {
    return legalDocuments.map((document, index) => (
      <Fragment key={`document.name_${index}`}>
        <LinkButton  onClick={() => onLegalDocumentClick(document)}>
          {document.name}{index <= legalDocuments.length - 2 && ','}
        </LinkButton>
        {' '}
      </Fragment>
    ));
  };

  const renderCheckboxLabel = () => {
    return (
      <>By checking this box I confirm that I have read, understood, and consent to
        {' '}{renderLegalDocuments()}.
      </>
    );
  };

  return (
    <>
      <div className={styles.reviewInfoContainer}>
        {borrowerProfileCards.length > 0 && (
          <div className={styles.tableRowDesktop}>
            <CardsForm
              title={`Borrower: ${borrowerName}`}
              switchEditModeButton="Edit Profile"
              cards={borrowerProfileCards}
              data={fullBorrowerProfileDataWithCalculations || {}}
              onFieldChange={onBorrowerFieldChange}
              onSwitchEditMode={onBorrowerProfileEditClick}
              displayFieldsAttributes={borrowerDisplayFieldsAttributes}
              displaySkeleton={!borrowerDisplayFieldsAttributes}
              formatVariableConfigurationDisplayTitle={formatDisplayTitle}
              cardsFormHeaderClassName={styles.cardFormHeader}
              editButtonClassName={styles.editButton}
              calculations={productCalculations}
            />
          </div>
        )}
        {applicationDetailsCards.length > 0 && (
          <div className={styles.tableRowDesktop}>
            <CardsForm
              title="Application Details"
              switchEditModeButton="Edit Details"
              cards={applicationDetailsCards}
              data={fullApplicationDetailsDataWithCalculations || {}}
              onFieldChange={onApplicationDetailsFieldChange}
              onSwitchEditMode={onApplicationDetailsEditClick}
              displayFieldsAttributes={applicationDetailsDisplayFieldsAttributes}
              displaySkeleton={!applicationDetailsDisplayFieldsAttributes}
              cardsFormHeaderClassName={styles.cardFormHeader}
              editButtonClassName={styles.editButton}
              calculations={productCalculations}
            />
          </div>
        )}
        {borrowerProfileMobileCards.length > 0 && (
          <div className={styles.tableRowMobile}>
            <CardsForm
              title={`Borrower: ${borrowerName}`}
              switchEditModeButton="Edit Profile"
              cards={borrowerProfileMobileCards}
              data={fullBorrowerProfileDataWithCalculations || {}}
              onFieldChange={onBorrowerFieldChange}
              onSwitchEditMode={onBorrowerProfileEditClick}
              displayFieldsAttributes={borrowerDisplayFieldsAttributes}
              displaySkeleton={!borrowerDisplayFieldsAttributes}
              columnsPerCards={1}
              editButtonClassName={styles.editButton}
              cardsFormHeaderClassName={styles.cardFormHeader}
              calculations={productCalculations}
            />
         </div>
        )}
        {applicationDetailsMobileCards.length > 0 && (
          <div className={styles.tableRowMobile}>
            <CardsForm
              title="Application Details"
              switchEditModeButton="Edit Details"
              cards={applicationDetailsMobileCards}
              data={fullApplicationDetailsDataWithCalculations || {}}
              onFieldChange={onApplicationDetailsFieldChange}
              onSwitchEditMode={onApplicationDetailsEditClick}
              displayFieldsAttributes={applicationDetailsDisplayFieldsAttributes}
              displaySkeleton={!applicationDetailsDisplayFieldsAttributes}
              columnsPerCards={1}
              editButtonClassName={styles.editButton}
              cardsFormHeaderClassName={styles.cardFormHeader}
              calculations={productCalculations}
            />
          </div>
        )}
        {applicationDocumentConfiguration.length > 0 && (
          <UploadDocumentsReviewSection
            applicationDocumentConfiguration={applicationDocumentConfiguration}
            documentFiles={documentFiles}
            onUploadDocumentsEditClick={onUploadDocumentsEditClick}
            borrowerFormData={borrowerFormData}
            applicationDetailsFormData={applicationDetailsFormData}
          />)
        }
      </div>
      <div className={styles.checkboxContainer}>
        <Checkbox
          label={renderCheckboxLabel()}
          checked={isChecked}
          onChange={handleCheckboxValueChange}
          onKeyDown={HandleKeyDown(KeyEventEnum.Enter, handleCheckboxValueChange)}
        />
      </div>
      {renderActions(!isChecked, false)}
    </>
  );
};

export default ReviewAndSubmitForm;
