import CardsForm, { CardsFormProps } from 'product_modules/components/CardsForm';
import styled from 'types/AppTheme';
import clsx from 'clsx';
import styles from './CardsForm.module.scss';
import { BaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { Card } from 'product_modules/api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseCalculation } from 'product_modules/api/LoanOriginationSystem/Base/CalculationsApi';

const EDIT_BUTTON_CLASS_NAME = 'CardsFormWrapper-edit-button';
const CARDS_TABLE_VIEW_DISABLED_FIELD_CLASS_NAME = 'CardTableView-field-disabled';

const CardsFormStyled = styled(CardsForm)`
  .${EDIT_BUTTON_CLASS_NAME} span {
    color: ${({ theme }) => theme.colors.accentColor};
    opacity: 1;
  }

  .${EDIT_BUTTON_CLASS_NAME}:hover, .${EDIT_BUTTON_CLASS_NAME}:focus {
    background-color: ${({ theme }) => theme.colors.backgroundHoverAccentColor};
    border-radius: 4px;
  }

  .${EDIT_BUTTON_CLASS_NAME} svg path {
    stroke: ${({ theme }) => theme.colors.accentColor};
  }
  
  .${CARDS_TABLE_VIEW_DISABLED_FIELD_CLASS_NAME} {
    max-height: 0;
    min-height: 0;
  }
` as <
    VariableConfigurationType extends BaseVariableConfiguration,
    CardType extends Card<VariableConfigurationType>,
    CalculationType extends BaseCalculation,
  >(props: CardsFormProps<VariableConfigurationType, CardType, CalculationType>) => JSX.Element;

const CardsFormWrapper = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
>({ editButtonClassName, ...props }: CardsFormProps<VariableConfigurationType, CardType, CalculationType>) => {
  return (
    <CardsFormStyled
      {...props}
      editButtonClassName={clsx(EDIT_BUTTON_CLASS_NAME, styles.text, editButtonClassName)}
      fieldClassName={styles.field}
      fieldTitleClassName={styles.fieldTitle}
      fieldValueClassName={styles.fieldValue}
      hiddenFieldClassName={CARDS_TABLE_VIEW_DISABLED_FIELD_CLASS_NAME}
    />
  );
};

export default CardsFormWrapper;
