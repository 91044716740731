import { FC, FocusEvent } from 'react';
import CardsForm from 'components/digifi-wrappers/CardsForm';
import { IBaseVariableConfiguration, IApplicationDetailsCard } from 'api/digifi/los/CardsApi';
import { FormLayoutData } from 'api/Types';
import { VariableValue } from 'types/VariableTypes';
import useVariablesFormValidation from 'hooks/useVariablesFormValidation';
import useCardFields from 'hooks/useCardsFields';
import useConditionalDisplayLogic from 'product_modules/hooks/useConditionalDisplayLogic';
import useFullApplicationData from 'hooks/useFullApplicationData';
import useCalculationsLogic from 'product_modules/hooks/useCalculationsLogic';
import { ProductCalculation } from 'product_modules/api/LoanOriginationSystem/ProductCalculationsApi';
import useMobileCards from 'hooks/useMobileCards';
import styles from 'components/ApplicationForm/forms/Form.module.scss';
import useModifiedCards from 'hooks/useModifiedCards';

interface IApplicationDetailsFormProps {
  cards: IApplicationDetailsCard[];
  onFieldChange: (variableConfiguration: IBaseVariableConfiguration, value: VariableValue) => void;
  onFieldBlur: (variableConfiguration: IBaseVariableConfiguration, event?: FocusEvent<HTMLInputElement>) => void;
  borrowerFormData: FormLayoutData;
  applicationDetailsFormData: FormLayoutData;
  renderActions: (submitDisabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
  productCalculations: ProductCalculation[] | null;
}

const ApplicationDetailsForm: FC<IApplicationDetailsFormProps> = ({
  cards,
  onFieldChange,
  borrowerFormData,
  applicationDetailsFormData,
  renderActions,
  productCalculations,
  onFieldBlur,
}) => {
  const fullApplicationData = useFullApplicationData({
    borrowerFormData,
    applicationDetailsFormData,
  });
  const fullApplicationDataWithCalculations = useCalculationsLogic(productCalculations, fullApplicationData);
  const fields = useCardFields(cards);
  const displayFieldsAttributes = useConditionalDisplayLogic(fields, fullApplicationDataWithCalculations);
  const isApplicationDetailsDataValid = useVariablesFormValidation(
    fields,
    fullApplicationDataWithCalculations,
    displayFieldsAttributes,
  );
  const modifiedCards = useModifiedCards(cards);
  const mobileCards = useMobileCards(modifiedCards);

  return (
    <>
      <div className={styles.tableRowDesktop}>
        <CardsForm
          cards={modifiedCards}
          onFieldChange={onFieldChange}
          onFieldBlur={onFieldBlur}
          data={fullApplicationDataWithCalculations || {}}
          isEditMode
          displayHeader={false}
          displayFieldsAttributes={displayFieldsAttributes}
          displaySkeleton={!displayFieldsAttributes}
          calculations={productCalculations}
        />
      </div>
      <div className={styles.tableRowMobile}>
        <CardsForm
          cards={mobileCards}
          onFieldChange={onFieldChange}
          onFieldBlur={onFieldBlur}
          data={fullApplicationDataWithCalculations || {}}
          isEditMode
          displayHeader={false}
          displaySkeleton={!displayFieldsAttributes}
          displayFieldsAttributes={displayFieldsAttributes}
          calculations={productCalculations}
          columnsPerCards={1}
        />
      </div>
      {renderActions(!cards, !isApplicationDetailsDataValid)}
    </>
  );
};

export default ApplicationDetailsForm;
