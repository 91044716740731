import { FC } from 'react';
import styles from './ApplicationStatusCard.module.scss';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';

const SkeletonApplicationStatusCard: FC = () => {
  return (
    <div className={styles.skeletonContainer}>
      <SkeletonText width="67%" color="primary20" height="16px" lineHeight="24px" />
      <SkeletonText width="50%" color="primary6" height="14px" className={styles.skeletonSecondRow} lineHeight="20px" />
      <SkeletonText width="19%" color="primary20" height="12px" lineHeight="16px" />
      <SkeletonText width="80%" color="primary6" height="14px" lineHeight="20px" />
    </div>
  );
};

export default SkeletonApplicationStatusCard;
