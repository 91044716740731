import ContainerStyled from 'components/BrandingHelpers/ContainerStyled';
import styles from 'components/PageLayout/Header/Header.module.scss';
import { FC } from 'react';

const Header: FC = () => {
  return (
    <ContainerStyled className={styles.headerContainer} />
  );
};

export default Header;
