import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import ApplicationsRestApi, {
  ApplicationSortingField, ApplicationsSortingType,
  ICreateApplicationParams,
  ISimplifiedApplicationParams,
} from 'api/digifi/los/ApplicationsApi';
import { RootState } from 'store';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const ITEMS_PER_PAGE = 10;

enum ApplicationsActionType {
  GetBorrowerApplications = 'applications/getBorrowerApplications',
  CreateApplication = 'applications/createApplication',
  FindByDisplayId = 'applications/findByDisplayId',
}

interface IPriorApplicationsParams {
  items: string[] | null;
  offset: number;
  pagesCount: number;
  total: number;
  page: number;
  sortingType: ApplicationsSortingType;
}

interface IActiveApplicationsParams {
  items: string[] | null;
}

export interface IApplicationsState extends EntityState<ISimplifiedApplicationParams> {
  priorApplications: IPriorApplicationsParams;
  activeApplications: IActiveApplicationsParams;
  selectedApplication: ISimplifiedApplicationParams | null;
}

const applicationsAdapter = createEntityAdapter<ISimplifiedApplicationParams>();

const initialState: IApplicationsState = applicationsAdapter.getInitialState({
  priorApplications: {
    items: null,
    offset: 0,
    page: 1,
    total: 0,
    pagesCount: 0,
    sortingType: {
      field: ApplicationSortingField.CreatedAt,
      ascending: false,
    } as ApplicationsSortingType,
  },
  activeApplications: {
    items: null,
  },
  selectedApplication: null,
});

const applicationsApi = new ApplicationsRestApi();

export const getBorrowerApplications = createAsyncThunk(
  ApplicationsActionType.GetBorrowerApplications,
  async(_, { getState }) => {
    const { applications } = getState() as { applications: IApplicationsState };

    const [activeApplications, priorApplications] = await Promise.all([
      applicationsApi.getActiveBorrowerApplications(),
      applicationsApi.getPriorBorrowerApplications({
        count: ITEMS_PER_PAGE,
        offset: applications.priorApplications.offset,
      }),
    ]);

    return {...activeApplications, ...priorApplications};
  },
);

export const createApplication = createAsyncThunk(
  ApplicationsActionType.CreateApplication,
  async (params: ICreateApplicationParams) => {
    return applicationsApi.createApplication(params);
  },
);

export const findApplicationByDisplayId = createAsyncThunk(
  ApplicationsActionType.FindByDisplayId,
  async (displayId: string, thunkApi) => {
    try {
      return await applicationsApi.findByDisplayId(displayId);
    } catch (error) {
      return rejectWithValueHelper(error, thunkApi);
    }
  },
);

const applicationsSlice = createSlice({
  name: 'applicationsSlice',
  initialState,
  reducers: {
    setPriorApplicationsPaginationData(state, { payload }: PayloadAction<Partial<IPriorApplicationsParams>>) {
      state.priorApplications.pagesCount = payload.pagesCount !== undefined ? payload.pagesCount : state.priorApplications.pagesCount;
      state.priorApplications.page = payload.page !==undefined ? payload.page : state.priorApplications.page;
      state.priorApplications.offset = payload.offset !== undefined ? payload.offset : state.priorApplications.offset;
      state.priorApplications.items = payload.items !== undefined ? payload.items : state.priorApplications.items;
    },
    setPriorApplicationsSortingType(state, { payload }: PayloadAction<ApplicationsSortingType>) {
      state.priorApplications.sortingType = payload;
    },
    setSelectedApplication(state, { payload }: PayloadAction<ISimplifiedApplicationParams | null>) {
      state.selectedApplication = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getBorrowerApplications.fulfilled, (state, { payload }) => {
        applicationsAdapter.setAll(state, [...payload.priorApplications, ...payload.activeApplications]);
        state.priorApplications.items = payload.priorApplications.map(application => application.id);
        state.activeApplications.items = payload.activeApplications.map(application => application.id);
        state.priorApplications.total = payload.total;
        state.priorApplications.pagesCount = Math.ceil(payload.total / ITEMS_PER_PAGE);
      })
      .addCase(createApplication.fulfilled, (state, { payload }) => {
        applicationsAdapter.setOne(state, payload);
        state.activeApplications.items = state.activeApplications.items
          ? [...state.activeApplications.items, payload.id]
          : [payload.id];
      })
      .addCase(findApplicationByDisplayId.fulfilled, (state, { payload }) => {
        state.selectedApplication = payload;
      });
  },
});

export const selectActiveApplications = (state: RootState) => state.applications.activeApplications.items
  ? state.applications.activeApplications.items
    .map(id => state.applications.entities[id])
    .filter(application => !!application)
  : state.applications.activeApplications.items;

export const selectPriorApplications = (state: RootState) => state.applications.priorApplications.items
  ? state.applications.priorApplications.items
    .map(id => state.applications.entities[id])
    .filter(application => !!application)
  : state.applications.priorApplications.items;

export const {
  setPriorApplicationsPaginationData,
  setPriorApplicationsSortingType,
  setSelectedApplication,
} = applicationsSlice.actions;

export default applicationsSlice.reducer;
