import React, { ReactElement, ReactNode, forwardRef } from 'react';
import { Tooltip } from '@material-ui/core';
import styles from './Tooltip.module.scss';
import clsx from 'clsx';

export type TooltipPosition =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export interface WrapperWithTooltipProps {
  tooltip: ReactNode;
  children: ReactElement;
  position?: TooltipPosition;
  className?: string;
  open?: boolean;
}

const DELAY = 300;

const WrapperWithTooltip = forwardRef<HTMLDivElement, WrapperWithTooltipProps>(
  ({ children, open, tooltip, className, position = 'top' }, ref) => {
    return (
      <Tooltip
        title={tooltip || ''}
        classes={{ popper: clsx(styles.tooltip, className) }}
        placement={position}
        enterDelay={DELAY}
        enterNextDelay={DELAY}
        arrow
        open={open}
        ref={ref}
      >
        {children}
      </Tooltip>
    );
  },
);

export default WrapperWithTooltip;
