import { ItemsStateWithPagination } from 'product_modules/pagination';
import { UserInfo } from 'product_modules/api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { VariablesSortingType } from 'product_modules/api/Core/VariablesApi';
import { VariablesFiltersState } from './Filters/Types';
import { PermissionGroupId } from 'product_modules/components/PermissionGroups/Types';
import { GeocodeResult, Suggestions } from 'use-places-autocomplete';

export interface VariablesState extends ItemsStateWithPagination<Variable> {
  isLoading: boolean;
  searched: {
    [searchInputValue: string]: ItemsStateWithPagination<Variable>;
  };
  sortingType: VariablesSortingType;
  filters: VariablesFiltersState;
  showArchived: boolean;
}

export type VisualDataType =
  | 'Number'
  | 'Monetary'
  | 'Percentage'
  | 'List'
  | 'Text'
  | 'LargeText'
  | 'Boolean'
  | 'Date'
  | 'PhoneNumber'
  | 'EmailAddress'
  | 'IdentificationNumber'
  | 'Address';

export type DataType = 'Number' | 'String' | 'Date' | 'Boolean' | 'Address';

export type VariableNumberFormat = 'Monetary' | 'Percentage';

export type VariableStringFormat = 'PhoneNumber' | 'EmailAddress' | 'IdentificationNumber';

export enum VariableAccessPermissionType {
  ReadWrite = 'ReadWrite',
  ReadOnly = 'ReadOnly',
  PartialRead = 'PartialRead',
  NoAccess = 'NoAccess',
}

export type VariableAccessPermissions = Record<PermissionGroupId, VariableAccessPermissionType>;

export interface Variable extends NewVariableApiType {
  id: string;
  systemName: string;
  dataType: DataType;
  createDate: string;
  updateDate: string;
  createdBy: UserInfo;
  updatedBy: UserInfo;
  isStandard?: boolean;
  isArchived?: boolean;
  isNotFound?: true;
  dependsOn?: {
    id: string;
    systemName: string;
    displayName: string;
  } | null;
}

export interface VariableClientType {
  id?: string;
  displayName: string;
  systemName?: string;
  dataType: VisualDataType;
  description: string;
  optionsList: string[] | null;
  permissions: VariableAccessPermissions;
  phoneNumberFormat: string | null;
  identificationNumberType: string | null;
  identificationNumberDescription: string | null;
  currency: string | null;
  dateFormat: string | null;
  maxAllowedValue: string | null;
  minAllowedValue: string | null;
  getSuggestions: (inputValue: string, country: string) => Promise<Suggestions> | null;
  getGeocode: (placeId: string) => Promise<GeocodeResult[]> | null;
  country: string | null;
  classNames?: Partial<{
    sectionClassName?: string;
    header?: string;
    popupContent?: string;
    closeIcon?: string;
    title?: string;
    saveButton?: string;
  }>;
}

export interface VariableAdvancedDataTypeAttributes {
  dataType: DataType;
  optionsList: string[] | null;
  numberFormat: VariableNumberFormat | null;
  stringFormat: VariableStringFormat | null;
  phoneNumberFormat: string | null;
  identificationNumberType: string | null;
  identificationNumberDescription: string | null;
  currency: string | null;
  dateFormat: string | null;
  getSuggestions: ((inputValue: string, country: string) => Promise<Suggestions>) | null;
  getGeocode: ((placeId: string) => Promise<GeocodeResult[]>) | null;
  country: string | null;
  classNames?: Partial<{
    sectionClassName?: string;
    header?: string;
    popupContent?: string;
    closeIcon?: string;
    title?: string;
    saveButton?: string;
  }>;
  maxAllowedValue?: string | null;
  minAllowedValue?: string | null;
}

export interface NewVariableApiType extends VariableAdvancedDataTypeAttributes {
  description: string;
  displayName: string;
  permissions: VariableAccessPermissions;
}

export interface SavedVariableApiType extends NewVariableApiType {
  id: string;
}

type SimpleDataTypeWithNoAttributes = {
  dataType: Exclude<VisualDataType, 'PhoneNumber' | 'IdentificationNumber' | 'Monetary'>;
};

export type PhoneNumberWithAttributes = {
  dataType: 'PhoneNumber';
  phoneNumberFormat: string | null;
  phoneNumberWithFlag?: boolean;
};

export type IdentificationNumberWithAttributes = {
  dataType: 'IdentificationNumber';
  identificationNumberType: string | null;
  identificationNumberDescription: string | null;
};
export type MonetaryWithAttributes = {
  dataType: 'Monetary';
  currency: string | null;
};

export type DateWithAttributes = {
  dataType: 'Date';
  dateFormat: string | null;
};

export type ListWithAttributes = {
  dataType: 'List';
  optionsList: string[];
};

export type AddressWithAttributes = {
  dataType: 'Address';
  getSuggestions: (inputValue: string, country: string) => Promise<Suggestions>;
  getGeocode: (placeId: string) => Promise<GeocodeResult[]>;
  country: string;
  classNames?: Partial<{
    sectionClassName?: string;
    header?: string;
    popupContent?: string;
    closeIcon?: string;
    title?: string;
    saveButton?: string;
  }>;
};

export type VariableCapAttributes = {
  minAllowedValue?: string | null;
  maxAllowedValue?: string | null;
};

export type VisualDataTypeWithAttributes =
  | SimpleDataTypeWithNoAttributes
  | PhoneNumberWithAttributes
  | IdentificationNumberWithAttributes
  | MonetaryWithAttributes
  | DateWithAttributes
  | ListWithAttributes
  | AddressWithAttributes;

export type UndefinedVisualDataTypeWithNoAttributes = {
  dataType: undefined;
};
