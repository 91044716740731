import React, { FC } from 'react';
import styles from 'components/common/UploadDocumentRequirements/UploadDocumentRequirements.module.scss';
import { CheckedImage } from 'static/images';

const requirements = [
  'The following file formats are accepted: .zip, .pdf, .txt, .doc, .docx, .xls, .xlsx, .csv, .xml, .json, .ppt, .pptx, .png, .jpg, .jpeg, .gif',
  'The maximum file size is 25MB',
];

const UploadDocumentRequirements: FC = () => {
  const renderRequirement = (requirement: string, index: number) => (
    <li key={index} className={styles.requirement}>
      <CheckedImage className={styles.checkedIcon} />
      {requirement}
    </li>
  );

  return (
    <ul>{requirements.map(renderRequirement)}</ul>
  );
};

export default UploadDocumentRequirements;
