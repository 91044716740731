import React, { useState } from 'react';
import Button from 'components/digifi-wrappers/Button';
import styles from './UploadDocumentForm.module.scss';
import UploadFile from 'components/digifi-wrappers/UploadFile';
import UploadDocumentRequirements from 'components/common/UploadDocumentRequirements';
import acceptFileTypes from 'utils/acceptFileTypes';

interface IUploadApplicationDocumentFormProps {
  buttonText: string;
  onApplicationDocumentUpload: (file: File) => Promise<unknown>;
}

const MAX_FILE_SIZE = 1024 * 1024 * 25; // 25 Mb

const UploadDocumentForm = ({
  onApplicationDocumentUpload,
  buttonText,
}: IUploadApplicationDocumentFormProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [isUploadingInProgress, setIsUploadingInProgress] = useState(false);

  const handleFileChange = (newFile: File | null) => {
    setFile(newFile);
  };

  const handleDocumentUpload = async () => {
    if (!file) {
      return;
    }

    try {
      setIsUploadingInProgress(true);
      await onApplicationDocumentUpload(file);

      setFile(null);
    } finally {
      setIsUploadingInProgress(false);
    }
  };

  return (
    <div>
      <UploadFile maxFileSize={MAX_FILE_SIZE} accept={acceptFileTypes} file={file} onFileChange={handleFileChange} />
      <div className={styles.requirementsContainer}>
        <UploadDocumentRequirements />
      </div>
      <Button
        disabled={!file}
        onClick={handleDocumentUpload}
        kind="primary"
        size="form"
        isLoading={isUploadingInProgress}
        className={styles.submitButton}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default UploadDocumentForm;
