import { FC } from 'react';
import Spinner, { SpinnerProps } from 'product_modules/components/Spinner';
import clsx from 'clsx';
import styled from 'types/AppTheme';
import ReactComponentType from 'types/ReactComponentType';

export const SPINNER_CLASS_NAME = 'SpinnerWrapper-spinner';

export const StyleStyleComponent = <Component extends ReactComponentType>(component: Component, elementType: 'root' | 'child') => {
  const selector = elementType === 'root' ? '&' : '';

  // We have to use !important because product component adds styles directly to style attribute
  return styled(component)`
    ${selector}.${SPINNER_CLASS_NAME} {
      color: ${({ theme }) => theme.colors.accentColor} !important;
    }
  `;
};

const SpinnerStyled = StyleStyleComponent(Spinner, 'root');

const SpinnerWrapper: FC<SpinnerProps> = (props) => {
  return (
    <SpinnerStyled {...props} className={clsx(SPINNER_CLASS_NAME, props.className)} />
  );
};

export default SpinnerWrapper;
