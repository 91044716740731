import styled from 'types/AppTheme';

interface INewApplicationContainerStyledProps {
  active?: boolean;
}

const NewApplicationWrapperStyled = styled.div<INewApplicationContainerStyledProps>`
  border: ${({ active, theme }) => active ? `1px solid ${theme.colors.accentColor}` : ''};

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundHoverAccentColor};
    border: 1px solid ${({ theme }) => theme.colors.accentColor};
  }
`;

export default NewApplicationWrapperStyled;
