import React, { ReactNode } from 'react';
import QuestionIcon from 'product_modules/components/QuestionIcon';
import styles from './PopUpHeader.module.scss';
import clsx from 'clsx';

interface PopUpHeaderProps {
  title: ReactNode;
  children?: ReactNode;
  titleTooltip?: ReactNode;
  titleIcons?: ReactNode;
  className?: string;
  titleClassName?: string;
}

const PopUpHeader = ({ title, titleTooltip, titleIcons, className, titleClassName, children }: PopUpHeaderProps) => {
  return (
    <div className={clsx(styles.popupHeader, className)}>
      <div className={`${styles.popupTitle}`}>
        <div className={clsx(styles.titleText, titleClassName)}>{title}</div>
        {titleIcons && <div className={styles.titleIconsContainer}>
          {titleIcons}
        </div>}
        {titleTooltip && <QuestionIcon className={styles.questionIcon} size={24} tooltip={titleTooltip} />}
      </div>
      {children}
    </div>
  );
};

export default PopUpHeader;
