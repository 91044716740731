import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState, CSSProperties } from 'react';
import styles from './TableHead.module.scss';

export interface TableHeadProps {
  sticky?: boolean;
  className?: string;
  stickyMarginTop?: number;
}

const SCROLL_EVENT_LISTENER_OPTIONS = { capture: true };
const TABLE_MARGIN = 50;

const TableHead: FC<TableHeadProps> = ({ children, sticky, className, stickyMarginTop }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);
  const [tableWidth, setTableWidth] = useState(0);

  const measure = () => {
    const element = elementRef.current;

    if (!element || !element.parentElement || !sticky) {
      return;
    }
    const elementOffset = element.parentElement.getBoundingClientRect();

    setOffset(elementOffset.top);
    setTableWidth(elementOffset.width);
  };

  useEffect(() => {
    measure();
    document.documentElement.addEventListener('scroll', measure, SCROLL_EVENT_LISTENER_OPTIONS);
    window.addEventListener('resize', measure);
    return () => {
      document.documentElement.removeEventListener('scroll', measure, SCROLL_EVENT_LISTENER_OPTIONS);
      window.removeEventListener('resize', measure);
    };
  }, []);

  const style: CSSProperties = {
    position: 'fixed',
    top: stickyMarginTop,
    width: tableWidth + TABLE_MARGIN * 2,
    padding: `0 ${TABLE_MARGIN}px`,
  };

  return (
    <div
      className={clsx(
        styles.tableHead,
        sticky && styles.sticky,
        stickyMarginTop && offset <= stickyMarginTop && styles.stickyWithMargin,
        className,
      )}
      ref={elementRef}
      style={stickyMarginTop && offset <= stickyMarginTop ? style : {}}
    >
      <div className={styles.columns}>{children}</div>
    </div>
  );
};

export default TableHead;
