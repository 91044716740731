import BaseApi from 'api/BaseApi';
import { BorrowerType } from 'enums/BorrowerType';
import { DataType, IVariable, VariableNumberFormat, VariableStringFormat } from 'types/VariableTypes';
import { IBorrowerVariableConfiguration, ICard } from 'types/CardTypes';
import { GeocodeResult, Suggestions } from 'use-places-autocomplete';

export interface IFormulaCondition {
  formula: string;
  formulaRequiredVariables: string[];
}

export interface IVariableAdvancedDataTypeAttributes {
  dataType: DataType;
  optionsList: string[] | null;
  numberFormat: VariableNumberFormat | null;
  stringFormat: VariableStringFormat | null;
  phoneNumberFormat: string | null;
  identificationNumberType: string | null;
  identificationNumberDescription: string | null;
  currency: string | null;
  dateFormat: string | null;
  getSuggestions: ((inputValue: string, country: string) => Promise<Suggestions>) | null;
  getGeocode: ((placeId: string) => Promise<GeocodeResult[]>) | null;
  country: string | null;
  maxAllowedValue?: string | null;
  minAllowedValue?: string | null;
}

export interface IBaseVariableConfiguration {
  id: string;
  variable: IVariable;
  column: number;
  position: number;
  cardId: string;
  default?: boolean;
  required?: boolean;
  conditionalDisplayRule?: IFormulaCondition | null;
}

export interface IApplicationVariableConfiguration extends IBaseVariableConfiguration {
  productId: string;
}

export interface IBorrowerProfileCard extends ICard<IBorrowerVariableConfiguration> {
  borrowerType: BorrowerType;
}

export interface IApplicationDetailsCard extends ICard<IApplicationVariableConfiguration> {
  productId: string;
}

export interface IApplicationDocumentConfiguration {
  id: string;
  name: string;
  position: number;
  folderId?: string | null;
  required?: boolean;
  productId: string;
  organizationId: string;
}

interface ICardsApi {
  getBorrowerProfileCards(): Promise<IBorrowerProfileCard[]>;
  getApplicationDetailsCards(productId: string): Promise<IApplicationDetailsCard[]>;
  getApplicationDocumentsConfiguration(productId: string): Promise<IApplicationDocumentConfiguration[]>;
}

export default class CardsRestApi extends BaseApi implements ICardsApi {
  public getBorrowerProfileCards(): Promise<IBorrowerProfileCard[]> {
    return this.fetch('/borrower-profile-cards');
  }

  public getApplicationDetailsCards(productId: string): Promise<IApplicationDetailsCard[]> {
    const urlParams = new URLSearchParams();
    urlParams.append('productId', productId);

    return this.fetch(`/application-details-cards?${urlParams}`);
  };

  public getApplicationDocumentsConfiguration(productId: string): Promise<IApplicationDocumentConfiguration[]> {
    const urlParams = new URLSearchParams();
    urlParams.append('productId', productId);

    return this.fetch(`/application-document-configurations?${urlParams}`);
  }
}
