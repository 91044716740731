import { FC } from 'react';
import ActionPopUp from 'components/digifi-wrappers/ActionPopUp';
import ActionPopUpItem from 'components/digifi-wrappers/ActionPopUpItem';
import styles from './ProductsList.module.scss';
import { IProduct } from 'api/digifi/los/ProductsApi';
import { ClickAwayListener } from '@material-ui/core';

interface IProductsList {
  productsList: IProduct[];
  onClick: (product: IProduct) => void;
  onClose: () => void;
}

const ProductsList: FC<IProductsList> = ({ productsList, onClick, onClose }) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={styles.productsListContainer}>
        <ActionPopUp hideTitle className={styles.popUpContainer}>
          {productsList.map(product => (
            <ActionPopUpItem
              key={product.id}
              onClick={() => onClick(product)}
            >
              {product.name}
            </ActionPopUpItem>
          ))}
        </ActionPopUp>
      </div>
    </ClickAwayListener>
  );
};

export default ProductsList;
