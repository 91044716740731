
import clsx from 'clsx';
import styles from './LinkButton.module.scss';
import React, { PropsWithChildren } from 'react';
import { HTMLButtonProps } from 'product_modules/components/Button/Button';
import WrapperWithHoverableTooltip from 'product_modules/components/WrapperWithHoverableTooltip';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import { noop } from 'lodash';

export interface LinkButtonProps extends HTMLButtonProps {
  className?: string;
  tooltip?: React.ReactNode;
  hoverableTooltip?: boolean;
}

const LinkButton = ({ className, children, disabled, tooltip, onClick, hoverableTooltip, ...restProps }: PropsWithChildren<LinkButtonProps>) => {
  const wrapWithTooltip = (content: React.ReactElement) => {
    if (hoverableTooltip) {
      return (
        <WrapperWithHoverableTooltip tooltip={tooltip}>
          {content}
        </WrapperWithHoverableTooltip>
      );
    }

    return (
      <WrapperWithTooltip tooltip={tooltip}>
        {content}
      </WrapperWithTooltip>
    );
  }

  return (
    wrapWithTooltip(
      <button
        type="button"
        className={clsx(styles.linkButton, disabled && styles.disabled, className)}
        onClick={disabled ? noop : onClick}
        {...restProps}
      >
        {children}
      </button>
    )
  );
};

export default LinkButton;
