import { FC } from 'react';
import Stepper, { StepperProps } from 'product_modules/components/Stepper';
import styles from './Stepper.module.scss';
import styled from 'types/AppTheme';

const StepperStyled = styled(Stepper)`
  .${styles.currentStepContainer} .${styles.stepNumber} {
    background-color: ${({ theme }) => theme.colors.accentColor};
    border-color: ${({ theme }) => theme.colors.accentColor};
  }

  .${styles.passedStepContainer} .${styles.stepNumber} {
    border-color: ${({ theme }) => theme.colors.accentColor};
  }

  .${styles.passedStepContainer} .${styles.stepNumber} svg path {
    stroke: ${({ theme }) => theme.colors.accentColor};
  }
`;

const StepperWrapper: FC<StepperProps> = (props) => {
  return (
    <StepperStyled
      {...props}
      hideArrow
      stepClassName={styles.stepContainer}
      activeStepClassName={styles.activeStepContainer}
      passedStepClassName={styles.passedStepContainer}
      currentStepClassName={styles.currentStepContainer}
      wrapperClassName={styles.stepWrapper}
      numberClassName={styles.stepNumber}
      className={styles.container}
      scrollableElementClassName={styles.scrollableElement}
      touchScroll
    />
  );
};

export default StepperWrapper;
