import { forwardRef } from 'react';
import styles from './ModalOverlay.module.scss';
import PopUp from 'components/digifi-wrappers/PopUp';
import PopUpContent from 'components/digifi-wrappers/PopUpContent';
import clsx from 'clsx';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import { ReactNode } from 'react';

export interface IModalOverlayProps {
  title: string;
  onClick: () => void;
  headerClassName?: string;
  headerContent?: JSX.Element;
  headerFixedAtTop?: boolean;
  isLoading?: boolean;
  className?: string;
  titleClassName?: string;
  closeIconClassName?: string;
  children?: ReactNode;
}

const ModalOverlay = forwardRef<HTMLElement, IModalOverlayProps>(({
  title,
  onClick,
  headerContent,
  headerClassName,
  isLoading,
  children,
  className,
  closeIconClassName,
  titleClassName,
}, ref) => {
  const renderTitleSkeleton = () => {
    return (
      <div className={styles.skeletonContainer}>
        <SkeletonText width="580px" height="24px" color="primary20" lineHeight="44px" className={styles.desktopTitle} />
        <SkeletonText width="95%" height="20px" color="primary20" lineHeight="36px" className={styles.mobileTitle} />
      </div>
    );
  };

  return (
    <PopUp
      title={!isLoading ? title : renderTitleSkeleton()}
      subTitle={headerContent}
      closePopUp={onClick}
      classNames={{
        sectionClassName: styles.popUpContainer,
        header: clsx(styles.popUpHeader, headerClassName),
        popup: className,
        closeIcon: clsx(styles.closeIcon, closeIconClassName),
        title: clsx(styles.title, titleClassName),
      }}
      ref={ref}
    >
      <PopUpContent className={styles.popUpContent}>
        {children}
      </PopUpContent>
    </PopUp>
  );
});

export default ModalOverlay;
