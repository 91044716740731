import { FC } from 'react';
import TabContent from 'components/ApplicationDetails/TabContent';
import { ITask, TasksSortingField, TasksSortingType } from 'api/digifi/los/TasksApi';
import TasksTable from 'components/ApplicationDetails/TasksTable';

interface IOutstandingTasksTabProps {
  tasks: ITask[];
  applicationId: string;
  onSort: (sortingType: TasksSortingType) => void;
  sortingType: TasksSortingType,
  isLoading?: boolean;
}

const OutstandingTasksTab: FC<IOutstandingTasksTabProps> = ({ onSort, ...props }) => {
  const handleSort = (field: TasksSortingField, ascending: boolean) => {
    onSort({ field, ascending });
  };

  return (
    <TabContent tabTitle="Outstanding Tasks">
      <TasksTable
        onTasksSort={handleSort}
        {...props}
      />
    </TabContent>
  );
};

export default OutstandingTasksTab;
