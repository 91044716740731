import clsx from 'clsx';
import React, { FC } from 'react';
import { ArrowDownIcon } from 'product_modules/static/images';
import { getFormattedWidth } from 'product_modules/components/Table/utils';
import styles from './TableHeadCell.module.scss';

export interface TableHeadCellProps {
  width?: number;
  ascending?: boolean;
  onClick?: () => void;
  className?: string;
  lastColumnCell?: boolean;
  pixels?: boolean
  arrowClassName?: string;
}

const TableHeadCell: FC<TableHeadCellProps> = ({
  children,
  width,
  ascending,
  className,
  onClick,
  lastColumnCell = false,
  pixels = false,
  arrowClassName,
}) => {
  const renderSortingArrow = () => <ArrowDownIcon className={clsx(styles.arrow, ascending && styles.rotated, arrowClassName)} />;

  const isSorted = ascending !== undefined;

  return (
    <div
      className={clsx(
        styles.tableHeadCell,
        onClick && styles.sortable,
        isSorted && styles.sorted,
        lastColumnCell && styles.lastColumnCell,
        className,
      )}
      style={{ width: getFormattedWidth(pixels, width) }}
      onClick={onClick}
    >
      {children}
      {isSorted && renderSortingArrow()}
    </div>
  );
};

export default TableHeadCell;
