import { FC } from 'react';
import PopUp from 'components/digifi-wrappers/PopUp';
import PopUpContent from 'components/digifi-wrappers/PopUpContent';
import UploadDocumentForm from 'components/ApplicationDetails/UploadDocumentForm/UploadDocumentForm';
import { uploadApplicationDocument } from 'handlers/documentsSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import styles from './UploadDocumentPopUp.module.scss';
import { createNotification } from 'handlers/notificationsSlice';
import { useAppDispatch } from 'hooks/reduxHooks';

interface IUploadDocumentPopUp {
  onClose: () => void;
  applicationId: string;
  taskId: string;
}

const UploadDocumentPopUp: FC<IUploadDocumentPopUp> = ({ onClose, applicationId, taskId }) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();

  const handleDocumentUpload = async (file: File) => {
    try {
      await dispatchWithUnwrap(uploadApplicationDocument({ applicationId, taskId, file }));
      onClose();
      createNotification({
        notification: 'Your document has been uploaded successfully.',
        type: 'success',
        dispatch,
      });
    } catch(error) {
      createNotification({
        notification: error.message,
        type: 'error',
        dispatch,
      });
    };
  };

  return (
    <PopUp
      title="Upload Document"
      closePopUp={onClose}
      classNames={{
        sectionClassName: styles.popUpContainer,
        header: styles.popUpHeader,
      }}
    >
      <PopUpContent hasTopMargin className={styles.popUpContent}>
        <UploadDocumentForm buttonText="Upload File" onApplicationDocumentUpload={handleDocumentUpload} />
      </PopUpContent>
    </PopUp>
  );
};

export default UploadDocumentPopUp;
