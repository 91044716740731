import { FC } from 'react';
import Button, { ButtonProps } from 'product_modules/components/Button';
import clsx from 'clsx';
import StyleButtonComponent, { getButtonClassName } from 'components/digifi-wrappers/Button/StyleButtonComponent';

const ButtonStyled = StyleButtonComponent(Button, 'root');

const ButtonWrapper: FC<ButtonProps> = (props) => {
  return (
    <ButtonStyled
      {...props}
      className={clsx(getButtonClassName(props.kind), props.className)}
    />
  );
};

export default ButtonWrapper;
