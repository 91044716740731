import React, { ReactNode } from 'react';
import { TextFieldProps } from '@material-ui/core';
import TextInput from 'product_modules/components/TextInput';
import { CalendarImage } from 'product_modules/static/images';
import { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import styles from './Input.module.scss';
import clsx from 'clsx';

export type InputSize = 'form' | 'default' | 'small';

interface DatePickerInputProps {
  onIconClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  inputClassName?: string;
  inputSize?: InputSize;
  inputIcon?: ReactNode;
  errorMessage?: string;
  readOnly?: boolean;
  tooltip?: string;
}

const DatePickerInput = ({
  value,
  label,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  onIconClick,
  readOnly,
  disabled,
  showLoader,
  loaderState,
  onLoaderStateReset,
  inputRef,
  tabIndex,
  inputClassName,
  required,
  errorMessage,
  inputSize = 'default',
  inputIcon,
  tooltip,
}: DatePickerInputProps & TextFieldProps) => {
  const renderOverlay = () => {
    if (inputIcon) {
      return null;
    }

    return (
      <div className={styles.calendarInputOverlay} onClick={onIconClick}>
        <p className={clsx(styles.calendarIconLeftOffset, styles[inputSize])}>
          {value ? (value as ReactNode) : placeholder}
        </p>
        <div className={styles.calendarIconContainer}>
          <CalendarImage className={styles.calendarIcon} />
        </div>
      </div>
    );
  }

  return (
    <div ref={inputRef} className={styles.container}>
      <TextInput
        value={value as string}
        labelTitle={label as string}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        hasRightNeighbour
        hasRightPadding
        readOnly={readOnly}
        disabled={disabled}
        showLoader={showLoader}
        loaderState={loaderState}
        onLoaderStateReset={onLoaderStateReset}
        required={required}
        loaderClassName={styles.loader}
        tabIndex={tabIndex}
        errorMessage={errorMessage}
        className={clsx(styles[inputSize], inputClassName)}
        inputIcon={inputIcon}
        renderOverlay={renderOverlay}
        tooltip={tooltip}
      />
    </div>
  );
};

export default DatePickerInput;
