import { FC } from 'react';
import styles from './ApplicationOverview.module.scss';
import formatMonetaryValue from 'product_modules/utils/formatMonetaryValue';
import formatDate from 'utils/formatDate';
import formatDisplayId from 'product_modules/utils/userIdFormat';
import { ISimplifiedApplicationParams } from 'api/digifi/los/ApplicationsApi';
import InfoSection from 'components/common/InfoSection';
import { SkeletonApplicationOverview } from 'components/ApplicationDetails/ApplicationOverview/index';
import ContainerStyled from 'components/BrandingHelpers/ContainerStyled';
import { getBorrowerFullName } from 'utils/borrowerNameHelper';
import { BorrowerType } from 'enums/BorrowerType';

interface IApplicationOverviewProps {
  application: ISimplifiedApplicationParams;
  currency: string;
  borrowerType: BorrowerType;
  isLoading?: boolean;
}

const ApplicationOverview: FC<IApplicationOverviewProps> = ({ application, currency, isLoading, borrowerType }) => {
  const desktopOverviewCellsMap = [
    new Map<string, string>([
      ['Borrower', getBorrowerFullName(application.variables, borrowerType) || ''],
      ['Loan Amount', (application.loanAmount !== undefined && application.loanAmount !== null) ? formatMonetaryValue(application.loanAmount, currency) : ''],
    ]),
    new Map<string, string>([
      ['Loan Product', application.productName],
      ['Application ID', formatDisplayId(application.displayId)],
    ]),
    new Map<string, string>([
      ['Application Date', formatDate(application.createdAt)],
      ['Last Updated', application.updatedAt ? formatDate(application.updatedAt) : ''],
    ]),
  ];

  const mobileOverviewCellsMap = [
    new Map<string, string>([
      ['Borrower', getBorrowerFullName(application.variables, borrowerType) || ''],
      ['Loan Amount', (application.loanAmount !== undefined && application.loanAmount !== null) ? formatMonetaryValue(application.loanAmount, currency) : ''],
      ['Loan Product', application.productName],
      ['Application ID', formatDisplayId(application.displayId)],
      ['Application Date', formatDate(application.createdAt)],
      ['Last Updated', application.updatedAt ? formatDate(application.updatedAt) : ''],
    ]),
  ];

  return (
    <>
      {isLoading ? <SkeletonApplicationOverview /> : (
        <ContainerStyled className={styles.overviewContainer}>
          <div className={styles.overviewTitle}>
            Application Overview
          </div>
          <div className={styles.infoSectionDesktop}>
            <InfoSection
              sectionContent={desktopOverviewCellsMap}
              containerClassName={styles.overviewContent}
              fieldTitleClassName={styles.fieldTitle}
              fieldValueClassName={styles.fieldValue}
              fieldClassName={styles.field}
            />
          </div>
          <div className={styles.infoSectionMobile}>
            <InfoSection
              sectionContent={mobileOverviewCellsMap}
              containerClassName={styles.overviewContent}
              fieldTitleClassName={styles.fieldTitle}
              fieldValueClassName={styles.fieldValue}
              fieldClassName={styles.field}
            />
          </div>

        </ContainerStyled>
      )}
    </>
  );
};

export default ApplicationOverview;
