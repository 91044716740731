import { createContext, useContext } from 'react';
import ProductCalculationsRestApi from 'api/digifi/los/ProductCalculationsApi';

export interface IApiProviderValue {
  productCalculationsApi: ProductCalculationsRestApi;
}

export type Services =
  | ProductCalculationsRestApi


const ApiContext = createContext<IApiProviderValue>({} as IApiProviderValue);

const getService = <ServiceType extends Services>(serviceName: keyof IApiProviderValue) => () => {
  const services = useContext(ApiContext);

  return services[serviceName] as ServiceType;
};

export const useProductCalculationsApi = getService<ProductCalculationsRestApi>('productCalculationsApi');

const ApiProvider = ApiContext.Provider;

export default ApiProvider;
