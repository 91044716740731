import { FC, useState } from 'react';
import LoginDetails from 'components/BorrowerProfile/LoginDetails';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import ChangeEmailPopup from 'components/BorrowerProfile/LoginDetails/ChangeEmailPopup';
import ChangePhonePopup from 'components/BorrowerProfile/LoginDetails/ChangePhonePopup';
import ChangePasswordPopup from 'components/BorrowerProfile/LoginDetails/ChangePasswordPopup';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import {
  createPasswordValidationToken,
  sendPhoneVerificationCode,
  sendUpdateEmailAddressCode,
  sendUpdatePhoneNumberCode,
  updateEmailAddress,
  updatePassword,
  updatePhoneNumber,
  verifyPhone,
  logout,
} from 'handlers/authSlice';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'enums/AppRoute';
import MainLayout from 'layout/MainLayout';
import ApplicationsHeader from 'components/ApplicationsList/ApplicationsHeader';

interface IAuthorizedLayoutProps {
  disableScroll?: boolean;
  isLoading?: boolean;
  applicationsBrowserTitle?: string;
  layoutContentClassName?: string;
  hideBorrowerDetails?: boolean;
}

const AuthorizedLayout: FC<IAuthorizedLayoutProps> = ({
  children,
  disableScroll,
  layoutContentClassName,
  isLoading,
  applicationsBrowserTitle,
  hideBorrowerDetails,
}) => {
  const { accountData } = useAppSelector(state => state.auth);
  const { country } = useAppSelector(state => state.settings.branding);
  const [showLoginDetailsPopUp, setShowLoginDetailsPopUp] = useState<boolean>(false);
  const [showChangeEmailPopUp, setShowChangeEmailPopUp] = useState<boolean>(false);
  const [showChangePhonePopUp, setShowChangePhonePopUp] = useState<boolean>(false);
  const [showChangePasswordPopUp, setShowChangePasswordPopUp] = useState<boolean>(false);
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLoginDetailsPopUpClose = () => {
    setShowLoginDetailsPopUp(false);
  };

  const handleLoginDetailsClick = () => {
    setShowLoginDetailsPopUp(true);
  };

  const handleConfirmPassword = async (password: string) => {
    await dispatchWithUnwrap(createPasswordValidationToken(password));
  };

  const handleSendUpdateEmailAddressCode = async (newEmail: string) => {
    await dispatchWithUnwrap(sendUpdateEmailAddressCode(newEmail));
  };

  const handleUpdateEmailAddress = async (code: string) => {
    await dispatchWithUnwrap(updateEmailAddress(code));
  };

  const handleSendUpdatePhoneNumberCode = async (newPhone: string) => {
    await dispatchWithUnwrap(sendUpdatePhoneNumberCode(newPhone));
  };

  const handleUpdatePhoneNumber = async (code: string) => {
    await dispatchWithUnwrap(updatePhoneNumber(code));
  };

  const handlePasswordChange = async (oldPassword: string, newPassword: string) => {
    await dispatchWithUnwrap(updatePassword({ oldPassword, newPassword }));
  };

  const handleVerifyOldPhoneNumber = async (code: string) => {
    await dispatchWithUnwrap(verifyPhone(code));
  };

  const handleSendOldPhoneNumberCode = async () => {
    await dispatchWithUnwrap(sendPhoneVerificationCode());
  };

  const handleLogoutClick = () => {
    dispatch(logout());
    navigate(AppRoute.SignIn);
  };

  const getApplicationBrowserTitle = () => {
    if (showLoginDetailsPopUp) {
      return 'Login Details';
    }

    return applicationsBrowserTitle;
  };

  return (
    <MainLayout
      disableScroll={disableScroll || showLoginDetailsPopUp}
      applicationsBrowserTitle={getApplicationBrowserTitle()}
      contentClassName={layoutContentClassName}
    >
      <ApplicationsHeader
        onLoginDetailsClick={handleLoginDetailsClick}
        onLogoutClick={handleLogoutClick}
        isLoading={isLoading}
        hideBorrowerDetails={hideBorrowerDetails}
      />
      {children}
      {showLoginDetailsPopUp && (
        <LoginDetails
          email={accountData?.email}
          phone={accountData?.phone}
          onChangeEmail={() => setShowChangeEmailPopUp(true)}
          onChangePhone={() => setShowChangePhonePopUp(true)}
          onChangePassword={() => setShowChangePasswordPopUp(true)}
          onClose={handleLoginDetailsPopUpClose}
        />
      )}
      {showChangeEmailPopUp && (
        <ChangeEmailPopup
          onConfirmPassword={handleConfirmPassword}
          onSendCode={handleSendUpdateEmailAddressCode}
          onVerifyCode={handleUpdateEmailAddress}
          onClose={() => setShowChangeEmailPopUp(false)}
        />
      )}
      {showChangePhonePopUp && (
        <ChangePhonePopup
          phoneNumberFormat={country || undefined}
          onConfirmPassword={handleConfirmPassword}
          onSendOldPhoneCode={handleSendOldPhoneNumberCode}
          onSendCode={handleSendUpdatePhoneNumberCode}
          onVerifyNewPhoneCode={handleUpdatePhoneNumber}
          onVerifyOldPhoneCode={handleVerifyOldPhoneNumber}
          onClose={() => setShowChangePhonePopUp(false)}
          oldPhone={accountData?.phone || ''}
        />
      )}
      {showChangePasswordPopUp && (
        <ChangePasswordPopup
          onPasswordChange={handlePasswordChange}
          onClose={() => setShowChangePasswordPopUp(false)}
        />
      )}
    </MainLayout>
  );
};

export default AuthorizedLayout;
