import { FC } from 'react';
import ButtonWithImage, { ButtonWithImageProps } from 'product_modules/components/ButtonWithImage';
import styled from 'types/AppTheme';
import clsx from 'clsx';
import styles from './ButtonWithImage.module.scss';

interface IButtonWithImageWrapperProps extends ButtonWithImageProps {
  withBorder?: boolean;
  fillOnHover?: boolean;
  colorRemainsOnHover?: boolean;
}

const BUTTON_WITH_IMAGE_CLASS_NAME = 'ButtonWithImageWrapper-button';

interface IButtonWithImageStyledProps {
  withBorder?: boolean;
  fillOnHover?: boolean;
  colorRemainsOnHover?: boolean;
}

const ButtonWithImageStyled = styled(ButtonWithImage)<IButtonWithImageStyledProps>`
  border: ${({ withBorder, theme }) => withBorder ? `1px solid ${theme.colors.accentColor}` : 'none'};
  border-radius: 4px;
  padding: ${({ withBorder }) => withBorder ? '9px 19px' : ''};

  &.${BUTTON_WITH_IMAGE_CLASS_NAME}:hover, &.${BUTTON_WITH_IMAGE_CLASS_NAME}:focus {
    color: ${({ theme }) => theme.colors.hoverAccentColor};
    background-color: ${({ withBorder, theme, fillOnHover }) => withBorder || fillOnHover ? theme.colors.backgroundHoverAccentColor : 'transparent'};

    span {
      color: ${({ theme, withBorder, colorRemainsOnHover }) => (withBorder || colorRemainsOnHover) ? theme.colors.accentColor : theme.colors.hoverAccentColor};
    }

    svg path {
      stroke: ${({ theme, withBorder, colorRemainsOnHover }) => (withBorder || colorRemainsOnHover) ? theme.colors.accentColor : theme.colors.hoverAccentColor};
    }
  }

  span {
    color: ${({ theme }) => theme.colors.accentColor};
    font-weight: ${({ withBorder }) => withBorder ? '700' : ''};
    opacity: 1;
  }

  svg path {
    stroke: ${({ theme }) => theme.colors.accentColor};
  }
`;

const ButtonWithImageWrapper: FC<IButtonWithImageWrapperProps> = ({ className, ...props }) => {
  return (
    <ButtonWithImageStyled
      {...props}
      className={clsx(styles.buttonWithImage, BUTTON_WITH_IMAGE_CLASS_NAME, className)}
    />
  );
};

export default ButtonWithImageWrapper;
