import { FC, FocusEvent, useCallback, useEffect, useState } from 'react';
import { ApplicationFormStep } from 'enums/ApplicationFormStep';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { IBaseVariableConfiguration } from 'api/digifi/los/CardsApi';
import { getApplicationDetailsCards, selectApplicationDetailsCards } from 'handlers/applicationDetailsCardsSlice';
import { getBorrowerProfileCards, selectBorrowerProfileCards } from 'handlers/borrowerProfileCardsSlice';
import CreateApplicationForm from 'components/ApplicationForm/CreateApplicationForm';
import styles from './CreateApplication.module.scss';
import {
  getApplicationDocumentsConfiguration,
  selectApplicationDocumentsConfiguration,
} from 'handlers/applicationDocumentConfigurationSlice';
import { IDocumentUploadFile } from 'components/ApplicationForm/forms/DocumentUploadForm/DocumentUploadForm';
import { DataType, VariableStringFormat, VariableValue } from 'types/VariableTypes';
import { getExistingBorrowerData, selectAllBorrowers, updateBorrower } from 'handlers/borrowersSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createApplication } from 'handlers/applicationsSlice';
import { createNotification } from 'handlers/notificationsSlice';
import { batchUploadApplicationDocuments } from 'handlers/documentsSlice';
import { ISimplifiedApplicationParams } from 'api/digifi/los/ApplicationsApi';
import useProductCalculations from 'hooks/useProductCalculations';
import { IProduct } from 'api/digifi/los/ProductsApi';
import { ILegalDocument } from 'api/digifi/dlp/SettingsApi';
import { setSelectedDocument } from 'handlers/settingsSlice';
import WithFieldsValidationButton from 'components/digifi-wrappers/WithFieldsValidationButton';
import { getBorrowerFullName } from 'utils/borrowerNameHelper';
import { batch } from 'react-redux';

interface IStepProps {
  type: ApplicationFormStep;
  label: string;
}

interface ICreateApplicationProps {
  currentStep: IStepProps;
  onStepChange: (step: ApplicationFormStep) => void;
  onCreateSuccess: (application: ISimplifiedApplicationParams) => void;
  product: IProduct | null;
  onSubmittingStart: () => void;
  onContinueClick: () => void;
  isSubmittingInProgress?: boolean;
}

const CreateApplication: FC<ICreateApplicationProps> = ({
  currentStep,
  onStepChange,
  onCreateSuccess,
  product,
  onSubmittingStart,
  isSubmittingInProgress,
  onContinueClick,
}) => {
  const applicationDetailsCards = useAppSelector(selectApplicationDetailsCards);
  const borrowerProfileCards = useAppSelector(selectBorrowerProfileCards);
  const applicationDocumentsConfiguration = useAppSelector(selectApplicationDocumentsConfiguration);
  const { legalDocuments } = useAppSelector(state => state.settings);
  const [borrower] = useAppSelector(selectAllBorrowers);
  const createApplicationLegalDocuments = legalDocuments.filter(document => document.showOnSubmitApplication);
  const [applicationDetailsFormData, setApplicationDetailsFormData] = useState({});
  const [borrowerProfileFormData, setBorrowerProfileFormData] = useState({});
  const [documentFiles, setDocumentFiles] = useState<IDocumentUploadFile[]>([]);
  const [createdApplication, setCreatedApplication] = useState<ISimplifiedApplicationParams | null>(null);
  const [isApplicationDocumentsConfigurationLoading, setIsApplicationDocumentsConfigurationLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const productCalculations = useProductCalculations(product?.id || '');

  const fetchApplicationDocuments = async() => {
    try {
      setIsApplicationDocumentsConfigurationLoading(true);
      await dispatchWithUnwrap(getApplicationDocumentsConfiguration(product?.id || ''));
    } finally {
      setIsApplicationDocumentsConfigurationLoading(false);
    }
  };

  useEffect(() => {
    setBorrowerProfileFormData({...borrower.variables, ...borrowerProfileFormData});
  }, [borrower]);

  useEffect(() => {
    if (currentStep.type === ApplicationFormStep.BorrowerProfileStep) {
      batch(() => {
        dispatch(getBorrowerProfileCards());
        dispatch(getExistingBorrowerData());
      });

    }

    if (currentStep.type === ApplicationFormStep.ApplicationDetailsStep) {
      dispatch(getApplicationDetailsCards(product?.id || ''));
    }

    if (currentStep.type === ApplicationFormStep.DocumentUploadStep) {
      fetchApplicationDocuments();
    }
  }, [currentStep]);

  const isTrimmableString = (dataType: DataType, stringFormat?: VariableStringFormat | null) => {
    return dataType === 'String' && !stringFormat || stringFormat === 'EmailAddress';
  };

  const handleBorrowerProfileFieldChange = useCallback((variableConfiguration: IBaseVariableConfiguration, value: VariableValue) => {
    setBorrowerProfileFormData((previousBorrowerFormData) => ({
      ...previousBorrowerFormData,
      [variableConfiguration.variable.systemName]: value,
    }));
  }, []);

  const handleBorrowerProfileFieldBlur = useCallback((variableConfiguration: IBaseVariableConfiguration, event?: FocusEvent<HTMLInputElement>) => {
    const { dataType, stringFormat } = variableConfiguration.variable;

    setBorrowerProfileFormData((previousBorrowerFormData) => {
      if (!isTrimmableString(dataType, stringFormat)) {
        return previousBorrowerFormData;
      }

      return {
        ...previousBorrowerFormData,
        [variableConfiguration.variable.systemName]: (event?.target.value as string)?.trim(),
      };
    });
  }, []);

  const handleApplicationDetailsFieldChange = useCallback((variableConfiguration: IBaseVariableConfiguration, value: VariableValue) => {
    setApplicationDetailsFormData((previousApplicationDetailsFormData) => ({
      ...previousApplicationDetailsFormData,
      [variableConfiguration.variable.systemName]: value,
    }));
  }, []);

  const handleApplicationDetailsFieldBlur = useCallback((variableConfiguration: IBaseVariableConfiguration, event?: FocusEvent<HTMLInputElement>) => {
    const { dataType, stringFormat } = variableConfiguration.variable;

    setApplicationDetailsFormData((previousApplicationDetailsFormData) => {
      if (!isTrimmableString(dataType, stringFormat)) {
        return previousApplicationDetailsFormData;
      }

      return {
        ...previousApplicationDetailsFormData,
        [variableConfiguration.variable.systemName]: (event?.target.value as string)?.trim(),
      };
    });
  }, []);

  const handleFileAdd = (file: IDocumentUploadFile) => {
    setDocumentFiles([...documentFiles, file]);
  };

  const handleFileRemove = (configurationId: string) => {
    setDocumentFiles(documentFiles.filter((file) => file.configurationId !== configurationId));
  };

  const uploadDocuments = async (applicationId: string) => {
    if (!documentFiles.length) {
      return;
    }

    const params = documentFiles.map((documentFile) => {
      return {
        anchor: documentFile.folderId,
        file: new File([documentFile.actualFile], `${documentFile.configurationName} - ${documentFile.actualFile.name}`),
      };
    });

    await dispatchWithUnwrap(batchUploadApplicationDocuments({ applicationId, params }));
  };

  const handleCreateApplication = async () => {
    onSubmittingStart();

    await dispatchWithUnwrap(updateBorrower({ variables: borrowerProfileFormData }));

    const application = await dispatchWithUnwrap(
      createApplication({
        product: product?.name || '',
        applicationDetails: applicationDetailsFormData,
      }),
    );

    if (!application.id) {
      return;
    }

    try {
      await uploadDocuments(application.id);
    } catch (error) {
      createNotification({
        type: 'success',
        notification: 'Application was created, however documents upload failed. Please, try to upload documents through documents tab.',
        dispatch,
      });
    }

    setCreatedApplication(application);
  };

  const handleClick = async () => {
    if (currentStep.type === ApplicationFormStep.ReviewAndSubmitStep) {
      await handleCreateApplication();
    } else {
      onContinueClick();
    }
  };

  const renderActions = (
    isButtonDisabled: boolean,
    areFieldsInvalid: boolean,
  ) => {
    return (
      <WithFieldsValidationButton
        size="form"
        kind="primary"
        disabled={isButtonDisabled}
        onClick={handleClick}
        className={styles.buttonContainer}
        areFieldsInvalid={areFieldsInvalid}
        buttonClassName={styles.formButton}
      >
        {currentStep.type === ApplicationFormStep.ReviewAndSubmitStep ? 'Submit Application' : 'Continue'}
      </WithFieldsValidationButton>
    );
  };

  const handleBorrowerProfileEditClick = () => {
    onStepChange(ApplicationFormStep.BorrowerProfileStep);
  };

  const handleApplicationDetailsEditClick = () => {
    onStepChange(ApplicationFormStep.ApplicationDetailsStep);
  };

  const handleUploadDocumentsEditClick = () => {
    onStepChange(ApplicationFormStep.DocumentUploadStep);
  };

  const handleLegalDocumentClick = (legalDocument: ILegalDocument) => {
    dispatch(setSelectedDocument(legalDocument));
  };

  return (
    <div className={styles.formContainer}>
      {!isSubmittingInProgress && <div className={styles.formTitle}>{currentStep.label}</div>}
      <CreateApplicationForm
        currentStep={currentStep.type}
        borrowerProfileCards={borrowerProfileCards}
        applicationDetailsCards={applicationDetailsCards}
        applicationDocumentConfiguration={applicationDocumentsConfiguration}
        applicationDetailsFormData={applicationDetailsFormData}
        borrowerProfileFormData={borrowerProfileFormData}
        onBorrowerFieldChange={handleBorrowerProfileFieldChange}
        onBorrowerFieldBlur={handleBorrowerProfileFieldBlur}
        onApplicationDetailsFieldChange={handleApplicationDetailsFieldChange}
        onApplicationDetailsFieldBlur={handleApplicationDetailsFieldBlur}
        onDocumentFileAdd={handleFileAdd}
        onDocumentFileRemove={handleFileRemove}
        documentFiles={documentFiles}
        legalDocuments={createApplicationLegalDocuments}
        renderActions={renderActions}
        onBorrowerProfileEditClick={handleBorrowerProfileEditClick}
        onApplicationDetailsEditClick={handleApplicationDetailsEditClick}
        onUploadDocumentsEditClick={handleUploadDocumentsEditClick}
        borrowerName={getBorrowerFullName(borrower.variables, borrower.type)}
        isCreatingInProgress={isSubmittingInProgress}
        productCalculations={productCalculations}
        onLegalDocumentClick={handleLegalDocumentClick}
        onLoadingFinish={onCreateSuccess}
        createdApplication={createdApplication}
        isApplicationDocumentsConfigurationLoading={isApplicationDocumentsConfigurationLoading}
      />
    </div>
  );
};

export default CreateApplication;
