import { FC } from 'react';
import TableBodyCell from 'components/digifi-wrappers/TableBodyCell';
import { ApplicationStatus } from 'enums/ApplicationStatus';
import Tag from 'components/digifi-wrappers/Tag';
import formatDisplayId from 'product_modules/utils/userIdFormat';
import formatMonetaryValue from 'product_modules/utils/formatMonetaryValue';
import { ApplicationsTableColumnSizes } from 'components/ApplicationsList/ApplicationsTableRow/ApplicationsTableColumnSized';
import { ISimplifiedApplicationParams } from 'api/digifi/los/ApplicationsApi';
import formatDate from 'utils/formatDate';
import styles from './ApplicationsTableRow.module.scss';
import clsx from 'clsx';

interface IApplicationsTableRowProps {
  application: ISimplifiedApplicationParams;
  currency: string;
}

const ApplicationsTableRow: FC<IApplicationsTableRowProps> = ({ application, currency }) => {
  const getStatusColor = (status: ApplicationStatus) => {
    return status === ApplicationStatus.Approved ? 'green' : 'red';
  };

  const renderDesktopRow = () => {
    return (
      <>
        <TableBodyCell width={ApplicationsTableColumnSizes.ApplicationDate} overflowed className={styles.tableCellDesktop}>
          {formatDate(application.createdAt)}
        </TableBodyCell>
        <TableBodyCell width={ApplicationsTableColumnSizes.ProductName} overflowed className={styles.tableCellDesktop}>
          {application.productName}
        </TableBodyCell>
        <TableBodyCell width={ApplicationsTableColumnSizes.Id} overflowed className={styles.tableCellDesktop}>
          {formatDisplayId(application.displayId)}
        </TableBodyCell>
        <TableBodyCell width={ApplicationsTableColumnSizes.LoanAmount} overflowed className={styles.tableCellDesktop}>
          {application.loanAmount !== undefined && application.loanAmount !== null && formatMonetaryValue(application.loanAmount, currency)}
        </TableBodyCell>
        <TableBodyCell width={ApplicationsTableColumnSizes.Status} overflowed className={styles.tableCellDesktop}>
          <Tag color={getStatusColor((application.status))}>{application.status}</Tag>
        </TableBodyCell>
        <TableBodyCell width={ApplicationsTableColumnSizes.StatusDate} overflowed className={styles.tableCellDesktop}>
          {!!application.statusDate && formatDate(application.statusDate)}
        </TableBodyCell>
      </>
    );
  };

  const renderMobileRow = () => {
    return (
      <>
        <TableBodyCell className={styles.tableCellMobile}>
          <div className={clsx(styles.tableCellSection, styles.productNameSection)}>
            <div className={styles.productNameTitle}>{application.productName}</div>
            <Tag color={getStatusColor((application.status))}>{application.status}</Tag>
          </div>
          <div className={styles.tableCellContent}>
            <div className={styles.tableCellSection}>
              <div className={styles.sectionTitle}>Application ID</div>
              {formatDisplayId(application.displayId)}
            </div>
            <div className={styles.tableCellSection}>
              <div className={styles.sectionTitle}>Application Date</div>
              {formatDate(application.createdAt)}
            </div>
            {!!application.statusDate && <div className={styles.tableCellSection}>
              <div className={styles.sectionTitle}>Status Date</div>
              {formatDate(application.statusDate)}
            </div>}
            <div className={styles.tableCellSection}>
              <div className={styles.sectionTitle}>Loan Amount</div>
              {(application.loanAmount !== undefined && application.loanAmount !== null)
                ? formatMonetaryValue(application.loanAmount, currency)
                : '—'
              }
            </div>
          </div>
        </TableBodyCell>
      </>
    );
  };

  return (
    <>
      {renderDesktopRow()}
      {renderMobileRow()}
    </>
  );
};

export default ApplicationsTableRow;
