import { BorrowerType } from 'enums/BorrowerType';
import { BorrowerVariable } from 'enums/BorrowerVariable';
import { ISimplifiedBorrowerParams } from 'api/digifi/los/BorrowersApi';
import { FormLayoutData } from 'api/Types';

export const getBorrowerFirstName = (borrower?: ISimplifiedBorrowerParams | null) => {
  if (!borrower) {
    return '';
  }

  return borrower.type === BorrowerType.Person
    ? borrower.variables[BorrowerVariable.FirstName] as string
    : getBorrowerFullName(borrower.variables, borrower.type);
};

export const getBorrowerFullName = (variables: FormLayoutData, type: BorrowerType): string => {
  if (!variables || !Object.keys(variables).length) {
    return '';
  }

  return Object.values(getBorrowerName(variables, type)).join(' ');
};

const getBorrowerName = (variables: FormLayoutData, type: BorrowerType) => {
  return type === BorrowerType.Person
    ? {
      firstName: variables[BorrowerVariable.FirstName] as string,
      lastName: variables[BorrowerVariable.LastName] as string,
    }
    : {
      companyName: variables[BorrowerVariable.CompanyName] as string,
    };
};
