import { reduce } from 'lodash';
import moment from 'moment';
import { VariableAccessPermissionType } from 'product_modules/components/Variables/VariablesTypes';
import { VariableValue } from 'product_modules/api/LoanOriginationSystem/Types';

type DateFormatPatternChar = 'D' | 'M' | 'Y' | '/';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

const ASTERISK = '*';

export const hideDateExceptYear = (value: string, dateFormat: string = DEFAULT_DATE_FORMAT) => {
  const yearString = reduce(DEFAULT_DATE_FORMAT, (yearStringAcc, patternChar, position) => {
    switch (patternChar.toUpperCase() as DateFormatPatternChar) {
      case 'Y':
        return yearStringAcc + value[position];
      default:
        return yearStringAcc;
    }
  }, '');

  return dateFormat.split('/')
    .map((chars) => {
      switch (chars[0].toUpperCase() as DateFormatPatternChar) {
        case 'Y': {
          const year = parseInt(yearString, 10);
          return moment({ year }).format(chars);
        }
        default:
          return chars.replace(/./g, ASTERISK);
      }
    })
    .join('/');
};

export const fullyHideDate = (dateFormat: string) => dateFormat.replace(/[^/]/g, '*');

export const getCustomDateFormat = (variableValue: string, dateFormat: string) => {
  const UTCFormatDate = moment(variableValue.toString(), DEFAULT_DATE_FORMAT).toDate();
  const normalizedDate = moment(UTCFormatDate).format(dateFormat);
  return normalizedDate;
};

const formatDateValue = (
  variableValue: VariableValue | null,
  dateFormat: string | null,
  accessPermissions?: VariableAccessPermissionType,
) => {
  if (!variableValue) {
    return variableValue;
  }
  if (accessPermissions === VariableAccessPermissionType.PartialRead) {
    return hideDateExceptYear(variableValue as string, dateFormat || undefined);
  }
  if (accessPermissions === VariableAccessPermissionType.NoAccess) {
    return fullyHideDate(variableValue as string);
  }
  if (!dateFormat) {
    return variableValue;
  }
  const formattedDateValue = getCustomDateFormat(variableValue.toString(), dateFormat);
  return formattedDateValue;
};

export default formatDateValue;
