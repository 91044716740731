import React, { FC, useRef } from 'react';
import { useFormik } from 'formik';
import EmailInput from 'components/digifi-wrappers/EmailInput/EmailInput';
import PasswordInput from 'components/digifi-wrappers/PasswordInput/PasswordInput';
import { validateEmail } from 'validation';
import SubmitButton from 'components/common/SubmitButton';
import TopRightInputButton from 'components/digifi-wrappers/TopRightInputButton/TopRightInputButton';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'enums/AppRoute';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';

export interface ISignInFormContextType {
  email: string;
  password: string;
}

interface ISignInFromProps {
  handleSubmit: (values: ISignInFormContextType) => Promise<void>;
  isLoading?: boolean;
}

const SignInForm: FC<ISignInFromProps> = ({ handleSubmit, isLoading }) => {
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();

  const { setFieldError, errors, values, setFieldValue, handleChange } = useFormik<ISignInFormContextType>({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: handleSubmit,
  });

  const isButtonEnabled = !validateEmail(values.email) && !!values.password.length;

  const clearFieldErrorOnFocus = (fieldName: string) => {
    setFieldError(fieldName, '');
  };

  const handleResetPassword = () => {
    navigate(AppRoute.ResetPassword);
  };

  const topRightElement = (
    <TopRightInputButton onClick={handleResetPassword}>
      Reset Password
    </TopRightInputButton>
  );

  return (
    <div>
      <EmailInput
        labelTitle="Email"
        onChange={(value) => setFieldValue('email', value)}
        onFocus={() => clearFieldErrorOnFocus('email')}
        onKeyDown={TriggerEventOnEnterKeyDown(passwordInputRef, 'focus')}
        value={values.email || ''}
        errorMessage={errors.email || ''}
        name="email"
        disabled={isLoading}
        required
        tabIndex={1}
        autoComplete="username email"
      />
      <PasswordInput
        name="password"
        labelTitle="Password"
        required
        value={values.password}
        onChange={handleChange}
        onFocus={() => clearFieldErrorOnFocus('password')}
        onKeyDown={TriggerEventOnEnterKeyDown(buttonRef, 'click')}
        errorMessage={errors.password}
        disabled={isLoading}
        topRightElement={topRightElement}
        tabIndex={2}
        ref={passwordInputRef}
        autoComplete="current-password"
      />
      <SubmitButton
        title="Sign In"
        isButtonEnabled={isButtonEnabled}
        onSubmit={() => handleSubmit(values)}
        tabIndex={3}
        ref={buttonRef}
      />
    </div>
  );
};

export default SignInForm;
