import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import DocumentsRestApi, {
  ApplicationDocumentsSortingField, ApplicationDocumentsSortingType,
  IApplicationDocument,
  IBatchUploadDocumentParams,
  IUploadFileRequest,
} from 'api/digifi/los/DocumentsApi';
import { RootState } from 'store';
import { downloadBlobFile } from 'product_modules/utils/downloadBlobFile';
import { IApplicationsState } from 'handlers/applicationsSlice';

enum ApplicationDocumentsActionType {
  GetApplicationDocuments = 'applicationDocuments/getApplicationDocuments',
  UploadApplicationDocument = 'applicationDocuments/uploadApplicationDocument',
  BatchUploadApplicationDocuments = 'applicationDocuments/batchUploadApplicationDocuments',
  DownloadApplicationDocument = 'applicationDocuments/downloadApplicationDocument',
  DownloadArchiveOfApplicationDocuments = 'applicationDocuments/downloadArchiveOfApplicationDocuments',
}

const applicationDocumentsAdapter = createEntityAdapter<IApplicationDocument>();

export interface IApplicationDocumentsState extends EntityState<IApplicationDocument> {
  documentDownloadingState: Record<string, string>;
  sortingType: ApplicationDocumentsSortingType;
}

const initialState = applicationDocumentsAdapter.getInitialState({
  documentDownloadingState: {} as Record<string, string>,
  sortingType: {
    field: ApplicationDocumentsSortingField.Name,
    ascending: false,
  },
});

export const applicationDocumentsApi = new DocumentsRestApi();

export const getApplicationDocuments = createAsyncThunk(
  ApplicationDocumentsActionType.GetApplicationDocuments,
  async({ applicationId }: { applicationId: string }) => {
  const { documents } = await applicationDocumentsApi.getApplicationDocuments(applicationId);

  return documents;
});

export const uploadApplicationDocument = createAsyncThunk(
  ApplicationDocumentsActionType.UploadApplicationDocument, (params: IUploadFileRequest) => {
    return applicationDocumentsApi.upload(params);
  },
);

export const downloadApplicationDocument = createAsyncThunk(
  ApplicationDocumentsActionType.DownloadApplicationDocument, async ({ documentId }: { documentId: string }) => {
  const response = await applicationDocumentsApi.download(documentId);

  downloadBlobFile(response);
});

export const downloadAllDocuments = createAsyncThunk(
  ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocuments,
  async ({ applicationId }: { applicationId: string, documentId: string }, { getState }) => {
    const response = await applicationDocumentsApi.downloadAll(applicationId);
    const { applications } = getState() as { applications: IApplicationsState };

    downloadBlobFile({
      ...response,
      filename: `Application Documents - ${applications.selectedApplication?.displayId}`,
    });
  },
);

export const batchUploadApplicationDocuments = createAsyncThunk<void, { applicationId: string; params: IBatchUploadDocumentParams[] }>(
  ApplicationDocumentsActionType.BatchUploadApplicationDocuments,
  async ({ applicationId, params }) => {
    await applicationDocumentsApi.batchUpload(applicationId, params);
  },
);

const applicationDocumentsSlice = createSlice({
  name: 'applicationDocumentsSlice',
  initialState,
  reducers: {
    removeDocuments(state) {
      applicationDocumentsAdapter.removeAll(state);
    },
    setDocumentsSortingType(state, { payload }: PayloadAction<ApplicationDocumentsSortingType>) {
      state.sortingType = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getApplicationDocuments.fulfilled, (state, { payload }) => {
        applicationDocumentsAdapter.setAll(state, payload);
      })
      .addMatcher(isAnyOf(downloadApplicationDocument.pending, downloadAllDocuments.pending), (state, action) => {
        state.documentDownloadingState[action.meta.arg.documentId] = 'loading';
      })
      .addMatcher(isAnyOf(downloadApplicationDocument.fulfilled, downloadAllDocuments.fulfilled), (state, action) => {
        state.documentDownloadingState[action.meta.arg.documentId] = 'success';
      })
      .addMatcher(isAnyOf(downloadApplicationDocument.rejected, downloadAllDocuments.rejected), (state, action) => {
        state.documentDownloadingState[action.meta.arg.documentId] = 'failure';
      });
  },
});

export const { selectAll: selectAllDocuments } = applicationDocumentsAdapter.getSelectors((state: RootState) => state.applicationDocuments);
export const { removeDocuments, setDocumentsSortingType } = applicationDocumentsSlice.actions;

export default applicationDocumentsSlice.reducer;
