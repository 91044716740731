import moment, { MomentInput } from 'moment';
import { VariableValue } from 'product_modules/api/Types';
import { DataType } from 'product_modules/components/Variables/VariablesTypes';
import { buildFullAddressString } from 'product_modules/components/AddressInput/buildAddressString';

const convertStringToBoolean = (value: string) => {
  switch (value.toLowerCase().trim()) {
    case 'true': {
      return true;
    }
    case 'false': {
      return false;
    }
    default: {
      return null;
    }
  }
};

const convertStringToNumber = (value: string) => {
  const numberValue = Number(value);

  return Number.isNaN(numberValue) ? null : numberValue;
};

const convertToBooleanDataType = (variableValue: unknown) => {
  switch (typeof variableValue) {
    case 'boolean': {
      return variableValue;
    }
    case 'string': {
      return convertStringToBoolean(variableValue);
    }
    case 'number': {
      return Boolean(variableValue);
    }
    case 'object': {
      return true;
    }
    default: {
      return null;
    }
  }
};

const convertToNumberDataType = (variableValue: unknown) => {
  switch (typeof variableValue) {
    case 'boolean': {
      return Number(variableValue);
    }
    case 'string': {
      return convertStringToNumber(variableValue);
    }
    case 'number': {
      return variableValue;
    }
    default: {
      return null;
    }
  }
};

const convertToStringDataType = (variableValue: unknown) => {
  switch (typeof variableValue) {
    case 'boolean':
    case 'number':
    case 'string': {
      return variableValue.toString();
    }
    case 'object': {
      return buildFullAddressString(variableValue);
    }
    default: {
      return null;
    }
  }
};

const convertToDateDataType = (variableValue: unknown) => {
  if (typeof variableValue === 'boolean') {
    return variableValue;
  }

  return moment(variableValue as MomentInput).isValid()
    ? moment(variableValue as MomentInput).format('MM/DD/YYYY')
    : variableValue;
};

const convertToAddressDataType = (variableValue: unknown) => {
  if (typeof variableValue !== 'object' || variableValue?.constructor.name !== 'Object') {
    return null;
  }

  return variableValue;
};

export const convertVariableValueByDataType = (variableValue: unknown, dataType: DataType): VariableValue => {
  if (variableValue === null || variableValue === undefined || variableValue === '') {
    return null;
  }

  switch (dataType) {
    case 'Boolean': {
      return convertToBooleanDataType(variableValue);
    }
    case 'Number': {
      return convertToNumberDataType(variableValue);
    }
    case 'Date': {
      return convertToDateDataType(variableValue) as VariableValue;
    }
    case 'String': {
      return convertToStringDataType(variableValue);
    }
    case 'Address': {
      return convertToAddressDataType(variableValue);
    }
    default: {
      return null;
    }
  }
};

export const convertVariableValueToString = (variableValue: VariableValue | undefined, transformNullAndUndefinedToString?: boolean): string => {
  if (variableValue === null) {
    return transformNullAndUndefinedToString ? 'null' : '';
  }

  if (variableValue === undefined) {
    return transformNullAndUndefinedToString ? 'undefined' : '';
  }

  switch (typeof variableValue) {
    case 'boolean':
    case 'number': {
      return String(variableValue);
    }
    case 'string': {
      return variableValue;
    }
    case 'object': {
      return buildFullAddressString(variableValue);
    }
    default: {
      return '';
    }
  }
};
