import { Option } from 'product_modules/components/SelectInput/SelectInput';

export const TEXT_DATA_TYPES: Option[] = [
  { value: 'List', name: 'List', description: 'List of pre-defined options where only one can be selected' },
  { value: 'Text', name: 'Text', description: 'Any combination of letters, numbers and special characters' },
];

export const NUMBER_DATA_TYPES: Option[] = [
  { value: 'Number', name: 'Number', description: 'For simple numerical data, including integers and decimals' },
  {
    value: 'Monetary',
    name: 'Monetary ($)',
    description: 'For numerical data that should be displayed in monetary format',
  },
  {
    value: 'Percentage',
    name: 'Percentage (%)',
    description: 'For numerical data that should be displayed in percentage format',
  },
];

export const VARIABLE_DATA_TYPES: Option[] = [
  ...NUMBER_DATA_TYPES,
  ...TEXT_DATA_TYPES,
  { value: 'Boolean', name: 'Boolean', description: 'For data that is either true or false' },
  { value: 'Date', name: 'Date', description: 'For data that represents a date' },
  {
    value: 'Address',
    name: 'Address',
    description: 'For addresses that you want to autocomplete using Google Maps API',
  },
  {
    value: 'PhoneNumber',
    name: 'Phone Number',
    description: 'For data that represents a phone number',
  },
  { value: 'EmailAddress', name: 'Email Address', description: 'For data that represents an email address' },
  {
    value: 'IdentificationNumber',
    name: 'Identification Number',
    description: 'For data that represents an identification number (e.g. Passport Number, SSN, etc.)',
  },
];
