import { IBaseVariableConfiguration } from 'api/digifi/los/CardsApi';
import { ICard } from 'types/CardTypes';
import { useContext, useMemo } from 'react';
import { googlePlacesApiContext } from 'index';
import { useAppSelector } from 'hooks/reduxHooks';
import styles from 'components/ApplicationForm/forms/Form.module.scss';

const useModifiedCards = <
  VariableConfigurationType extends IBaseVariableConfiguration,
  CardsType extends ICard<VariableConfigurationType>,
  >(cards: CardsType[]) => {
  const { country } = useAppSelector(state => state.settings.branding);
  const placesApi = useContext(googlePlacesApiContext);

  const getSuggestions = async (inputValue: string, countryCode: string) => {
    return placesApi.getSuggestions(inputValue, countryCode);
  };

  const getGeocode = async (placeId: string) => {
    return placesApi.getGeocode(placeId);
  };

  const classNames = {
    sectionClassName: styles.popUpContainer,
    header: styles.popUpHeader,
    popupContent: styles.popUpContent,
    closeIcon: styles.closeIcon,
    title: styles.title,
    saveButton: styles.saveButton,
  };

  return useMemo(() => {
    return cards.map(card => {
      const updFields = card.fields.map(field => {
        if (field.variable.dataType === 'Address') {
          return {
            ...field,
            variable: {
              ...field.variable,
              getSuggestions,
              getGeocode,
              country,
              classNames,
            },
          };
        }

        return field;
      });

      return { ...card, fields: updFields };
    });
  }, [cards]);
};

export default useModifiedCards;
