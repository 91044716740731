import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { CreateAccountFormType } from 'types';
import { IAuthResponse } from 'api/digifi/auth/AuthTypes';

interface IGetCurrentAccountResponseParams {
  email: string;
  phone: string;
  isEmailVerified: boolean;
  isMfaComplete: boolean;
  borrowerId: string;
  isPhoneVerified: boolean;
}

interface ICreatePasswordValidationTokenResponse {
  passwordValidationToken: string;
}

export interface IAccountsApi {
  createAccount(body: CreateAccountFormType): Promise<IAuthResponse>;
  getCurrentAccount(): Promise<IGetCurrentAccountResponseParams>;
  createPasswordValidationToken(password: string): Promise<ICreatePasswordValidationTokenResponse>;
  sendUpdatePhoneNumberCode(phone: string): Promise<void>;
  updatePhoneNumber(code: string): Promise<void>;
  sendUpdateEmailAddressCode(email: string): Promise<void>;
  updateEmailAddress(code: string): Promise<void>;
  updatePassword(oldPassword: string, newPassword: string): Promise<void>;
}

export default class AccountsRestApi extends BaseApi implements IAccountsApi {
  public createAccount(body: CreateAccountFormType): Promise<IAuthResponse> {
    return this.fetch('/accounts', {
      method: FetchMethodType.POST,
      body,
    });
  };

  public getCurrentAccount(): Promise<IGetCurrentAccountResponseParams> {
    return this.fetch('/accounts');
  }

  public createPasswordValidationToken(password: string): Promise<ICreatePasswordValidationTokenResponse> {
    return this.fetch('/accounts/password-validation-token', {
      method: FetchMethodType.POST,
      body: {
        password,
      },
    });
  };

  public sendUpdatePhoneNumberCode(phone: string): Promise<void> {
    return this.fetch('/accounts/phone', {
      method: FetchMethodType.PUT,
      body: {
        phone,
      },
      includePasswordValidationToken: true,
    });
  }

  public updatePhoneNumber(code: string): Promise<void> {
    return this.fetch(`/accounts/phone/${code}`, {
      method: FetchMethodType.PUT,
    });
  }

  public sendUpdateEmailAddressCode(email: string): Promise<void> {
    return this.fetch('/accounts/email', {
      method: FetchMethodType.PUT,
      body: {
        email,
      },
      includePasswordValidationToken: true,
    });
  }

  public updateEmailAddress(code: string): Promise<void> {
    return this.fetch(`/accounts/email/${code}`, {
      method: FetchMethodType.PUT,
      body: {
        code,
      },
    });
  }

  public updatePassword(oldPassword: string, newPassword: string): Promise<void> {
    return this.fetch('/accounts/password', {
      method: FetchMethodType.PUT,
      body: {
        oldPassword,
        newPassword,
      },
    });
  }
}
