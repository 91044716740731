import { FC } from 'react';
import SkeletonUploadFileStub, { SkeletonDraggableUploadFileStubProps } from 'product_modules/components/SkeletonUploadFileStub';

const SkeletonUploadFIleStubWrapper: FC<SkeletonDraggableUploadFileStubProps> = (props) => {
  return (
    <SkeletonUploadFileStub {...props} />
  );
};

export default SkeletonUploadFIleStubWrapper;
