import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { IDataViewSortingType } from 'api/Types';

export enum TaskStatus {
  Done = 'Done',
  NotDone = 'Not Done',
  InReview = 'In Review',
}

export type EditableTaskStatus = TaskStatus.InReview | TaskStatus.NotDone;

export interface ITask {
  id: string;
  createdAt: string;
  updatedAt: string;
  dueDate: string;
  description: string;
  status: TaskStatus;
}

export interface IUpdateTaskParams {
  status: EditableTaskStatus;
}

export interface IGetApplicationTasksRequest {
  applicationId: string;
}

interface IGetApplicationTasksResponse {
  tasks: ITask[];
}

interface IUpdateTaskResponse {
  task: ITask;
}

export enum TasksSortingField {
  Description = 'description',
  Status = 'status',
  DueDate = 'dueDate',
}

export type TasksSortingType = IDataViewSortingType<TasksSortingField>;

interface ITasksApi {
  getApplicationTasks(params: IGetApplicationTasksRequest): Promise<IGetApplicationTasksResponse>;
  update(id: string, task: IUpdateTaskParams): Promise<IUpdateTaskResponse>;
}

export default class TasksRestApi extends BaseApi implements ITasksApi {
  public getApplicationTasks({ applicationId }: IGetApplicationTasksRequest): Promise<IGetApplicationTasksResponse> {
    const urlParams = new URLSearchParams();
    urlParams.append('applicationId', applicationId);
    urlParams.append('statuses', TaskStatus.NotDone);
    urlParams.append('statuses', TaskStatus.InReview);

    return this.fetch(`/tasks?${urlParams}`);
  }

  public update(id: string, task: Partial<IUpdateTaskParams>): Promise<IUpdateTaskResponse> {
    return this.fetch('/tasks', {
      method: FetchMethodType.PUT,
      body: {
        task,
        id,
      },
    });
  };
}
