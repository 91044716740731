import { FC, useEffect, useState } from 'react';
import Logo from 'components/common/Logo';
import styles from 'components/ApplicationForm/ApplicationSubmitFakeLoader/ApplicationSubmitFakeLoader.module.scss';
import { ISimplifiedApplicationParams } from 'api/digifi/los/ApplicationsApi';
import LinearProgressBar from 'components/ApplicationForm/LinearProgressBar';

const LOADER_TIMEOUT = 500;
const MAX_LOADING_TIME = 10000;
const MAX_PROGRESS = 95;
const DECIMAL_MULTIPLIER = 10;

interface IApplicationSubmitFakeLoaderProps {
  createdApplication: ISimplifiedApplicationParams | null;
  onLoadingFinish: (application: ISimplifiedApplicationParams) => void;
}

const ApplicationSubmitFakeLoader: FC<IApplicationSubmitFakeLoaderProps> = ({ createdApplication, onLoadingFinish }) => {
  const [progress, setProgress] = useState(0);
  const [loadingTime, setLoadingTime] = useState<number>(0);

  useEffect(() => {
    if (loadingTime > MAX_LOADING_TIME) {
      if (createdApplication) {
        return onLoadingFinish(createdApplication);
      }
    }

    setTimeout(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * DECIMAL_MULTIPLIER;
        return Math.min(oldProgress + diff, MAX_PROGRESS);
      });
      setLoadingTime(loadingTime + LOADER_TIMEOUT);
    }, LOADER_TIMEOUT);
  }, [loadingTime]);

  return (
    <div className={styles.loaderContainer}>
      <div className={styles.logoContainer}>
        <Logo className={styles.logoContent} />
      </div>
      <div className={styles.title}>Please Wait</div>
      <div className={styles.subtitle}>Your application is being processed.<br/>
        Please do not close this page.</div>
     <LinearProgressBar progress={progress} />
    </div>
  );
};

export default ApplicationSubmitFakeLoader;
