import { FC, FocusEvent } from 'react';
import { ApplicationFormStep } from 'enums/ApplicationFormStep';
import ApplicationDetailsForm from 'components/ApplicationForm/forms/ApplicationDetailsForm';
import {
  IApplicationDetailsCard,
  IApplicationDocumentConfiguration,
  IBaseVariableConfiguration,
  IBorrowerProfileCard,
} from 'api/digifi/los/CardsApi';
import { FormLayoutData } from 'api/Types';
import BorrowerProfileForm from 'components/ApplicationForm/forms/BorrowerProfileForm';
import DocumentUploadForm from 'components/ApplicationForm/forms/DocumentUploadForm';
import { IDocumentUploadFile } from 'components/ApplicationForm/forms/DocumentUploadForm/DocumentUploadForm';
import ReviewAndSubmitForm from 'components/ApplicationForm/forms/ReviewAndSubmitForm';
import { ILegalDocument } from 'api/digifi/dlp/SettingsApi';
import { VariableValue } from 'types/VariableTypes';
import ApplicationSubmitFakeLoader from 'components/ApplicationForm/ApplicationSubmitFakeLoader';
import { ProductCalculation } from 'product_modules/api/LoanOriginationSystem/ProductCalculationsApi';
import { ISimplifiedApplicationParams } from 'api/digifi/los/ApplicationsApi';

interface ICreateApplicationFormProps {
  currentStep: ApplicationFormStep;
  borrowerProfileCards: IBorrowerProfileCard[];
  applicationDetailsCards: IApplicationDetailsCard[];
  applicationDocumentConfiguration: IApplicationDocumentConfiguration[];
  applicationDetailsFormData: FormLayoutData;
  borrowerProfileFormData: FormLayoutData;
  onBorrowerFieldChange: (variableConfiguration: IBaseVariableConfiguration, value: VariableValue) => void;
  onBorrowerFieldBlur: (variableConfiguration: IBaseVariableConfiguration, event?: FocusEvent<HTMLInputElement>) => void;
  onApplicationDetailsFieldChange: (variableConfiguration: IBaseVariableConfiguration, value: VariableValue) => void;
  onApplicationDetailsFieldBlur: (variableConfiguration: IBaseVariableConfiguration, event?: FocusEvent<HTMLInputElement>) => void;
  onDocumentFileAdd: (file: IDocumentUploadFile) => void;
  onDocumentFileRemove: (configurationId: string) => void;
  documentFiles: IDocumentUploadFile[];
  legalDocuments: ILegalDocument[];
  renderActions: (submitDisabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
  onBorrowerProfileEditClick: () => void;
  onApplicationDetailsEditClick: () => void;
  onUploadDocumentsEditClick: () => void;
  borrowerName: string;
  productCalculations: ProductCalculation[] | null;
  onLegalDocumentClick: (legalDocument: ILegalDocument) => void;
  onLoadingFinish: (application: ISimplifiedApplicationParams) => void;
  createdApplication: ISimplifiedApplicationParams | null;
  isCreatingInProgress?: boolean;
  isApplicationDocumentsConfigurationLoading?: boolean;
}

const CreateApplicationForm: FC<ICreateApplicationFormProps> = ({
  currentStep,
  borrowerProfileCards,
  applicationDetailsCards,
  applicationDocumentConfiguration,
  borrowerProfileFormData,
  applicationDetailsFormData,
  onBorrowerFieldChange,
  onApplicationDetailsFieldChange,
  onDocumentFileAdd,
  onDocumentFileRemove,
  documentFiles,
  legalDocuments,
  renderActions,
  onBorrowerProfileEditClick,
  onApplicationDetailsEditClick,
  onUploadDocumentsEditClick,
  borrowerName,
  isCreatingInProgress,
  productCalculations,
  onLegalDocumentClick,
  onLoadingFinish,
  createdApplication,
  onBorrowerFieldBlur,
  onApplicationDetailsFieldBlur,
  isApplicationDocumentsConfigurationLoading,
}) => {
  const renderStep = () => {
    if (isCreatingInProgress) {
      return (
        <ApplicationSubmitFakeLoader
          onLoadingFinish={onLoadingFinish}
          createdApplication={createdApplication}
        />
      );
    }

    if (currentStep === ApplicationFormStep.BorrowerProfileStep) {
      return (
        <BorrowerProfileForm
          borrowerFormData={borrowerProfileFormData}
          applicationDetailsFormData={applicationDetailsFormData}
          cards={borrowerProfileCards}
          onFieldChange={onBorrowerFieldChange}
          onFieldBlur={onBorrowerFieldBlur}
          renderActions={renderActions}
          productCalculations={productCalculations}
        />
      );
    }

    if (currentStep === ApplicationFormStep.ApplicationDetailsStep) {
      return (
        <ApplicationDetailsForm
          borrowerFormData={borrowerProfileFormData}
          applicationDetailsFormData={applicationDetailsFormData}
          cards={applicationDetailsCards}
          onFieldChange={onApplicationDetailsFieldChange}
          onFieldBlur={onApplicationDetailsFieldBlur}
          renderActions={renderActions}
          productCalculations={productCalculations}
        />
      );
    }

    if (currentStep === ApplicationFormStep.DocumentUploadStep) {
      return (
        <DocumentUploadForm
          files={documentFiles}
          applicationDocumentConfiguration={applicationDocumentConfiguration}
          onFileAdd={onDocumentFileAdd}
          onFileRemove={onDocumentFileRemove}
          renderActions={renderActions}
          borrowerFormData={borrowerProfileFormData}
          applicationDetailsFormData={applicationDetailsFormData}
          productCalculations={productCalculations}
          isLoading={isApplicationDocumentsConfigurationLoading}
        />
      );
    }

    if (currentStep === ApplicationFormStep.ReviewAndSubmitStep) {
      return (
        <ReviewAndSubmitForm
          borrowerProfileCards={borrowerProfileCards}
          applicationDetailsCards={applicationDetailsCards}
          borrowerFormData={borrowerProfileFormData}
          applicationDetailsFormData={applicationDetailsFormData}
          legalDocuments={legalDocuments}
          documentFiles={documentFiles}
          renderActions={renderActions}
          applicationDocumentConfiguration={applicationDocumentConfiguration}
          onBorrowerFieldChange={onBorrowerFieldChange}
          onApplicationDetailsFieldChange={onApplicationDetailsFieldChange}
          onBorrowerProfileEditClick={onBorrowerProfileEditClick}
          onApplicationDetailsEditClick={onApplicationDetailsEditClick}
          onUploadDocumentsEditClick={onUploadDocumentsEditClick}
          borrowerName={borrowerName}
          onLegalDocumentClick={onLegalDocumentClick}
          productCalculations={productCalculations}
        />
      );
    }

    return null;
  };

  return (
    <div>
      {renderStep()}
    </div>
  );
};

export default CreateApplicationForm;
