import React, { FocusEvent, useMemo } from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import { FormLayoutData, VariableValue } from 'product_modules/api/LoanOriginationSystem/Types';
import { BaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { BaseCalculation } from 'product_modules/api/LoanOriginationSystem/Base/CalculationsApi';
import { Card } from 'product_modules/api/LoanOriginationSystem/Base/BaseCardsApi';
import { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import ConfigurableForm from 'product_modules/components/ConfigurableForm';
import CardTableView from 'product_modules/components/CardsForm/CardTableView';
import { isEmptyVariableValue } from 'product_modules/utils/isEmptyVariableValue';
import useUserPermissionGroupId from 'product_modules/components/MyAccount/useUserPermissionGroupId';
import styles from './Card.module.scss';

export interface CardProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
  > {
  className?: string;
  card: CardType;
  cardIndex: number;
  data: FormLayoutData;
  onFieldChange: (variableConfiguration: VariableConfigurationType, value: VariableValue) => void;
  onFieldFocus?: (variableConfiguration: VariableConfigurationType, event: FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (variableConfiguration: VariableConfigurationType, event?: FocusEvent<HTMLInputElement>) => void;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  loaderStateById?: Record<string, LoaderState | null | undefined>;
  onLoaderStateReset?: (variableConfiguration: VariableConfigurationType) => void;
  columnsPerCards?: number;
  isEditMode?: boolean;
  calculations?: CalculationType[] | null;
  displayFieldsAttributes?: Record<string, boolean>;
  rowIndex: number;
  fieldClassName?: string;
  fieldTitleClassName?: string;
  fieldValueClassName?: string;
  hiddenFieldClassName?: string;
}

const DEFAULT_FORM_INDEX_OFFSET = 4;

const CardComponent = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
>({
  className,
  data,
  card,
  onFieldChange,
  onFieldBlur,
  onFieldFocus,
  loaderStateById,
  onLoaderStateReset,
  isEditMode,
  columnsPerCards,
  formatVariableConfigurationDisplayTitle,
  displayFieldsAttributes,
  rowIndex,
  cardIndex,
  calculations,
  fieldClassName,
  fieldTitleClassName,
  fieldValueClassName,
  hiddenFieldClassName,
}: CardProps<VariableConfigurationType, CardType, CalculationType>) => {
  const isSomeFieldVisible = useMemo(() => {
    if (!displayFieldsAttributes) {
      return true;
    }

    if (!card.fields.length) {
      return false;
    }

    return card.fields.some((field) => {
      return displayFieldsAttributes[field.id] || !isEmptyVariableValue(data[field.variable.systemName]);
    });
  }, [card.fields, displayFieldsAttributes, data]);

  const userPermissionGroupId = useUserPermissionGroupId();

  const renderBody = () => {
    if (isEditMode) {
      return (
        <ConfigurableForm
          showLoader
          className={styles.configurableForm}
          columns={columnsPerCards}
          configurations={card.fields}
          data={data}
          loaderStateById={loaderStateById}
          onFieldChange={onFieldChange}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
          onLoaderStateReset={onLoaderStateReset}
          formatDisplayTitle={formatVariableConfigurationDisplayTitle}
          formIndex={cardIndex + rowIndex * DEFAULT_FORM_INDEX_OFFSET}
          displayFieldsAttributes={displayFieldsAttributes}
          calculations={calculations}
          userPermissionGroupId={userPermissionGroupId || ''}
        />
      );
    }

    return (
      <CardTableView
        columnsPerCard={columnsPerCards}
        data={data}
        fields={card.fields}
        formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
        displayFieldsAttributes={displayFieldsAttributes}
        userPermissionGroupId={userPermissionGroupId}
        fieldClassName={fieldClassName}
        fieldTitleClassName={fieldTitleClassName}
        fieldValueClassName={fieldValueClassName}
        hiddenFieldClassName={hiddenFieldClassName}
      />
    );
  }

  return (
    <Collapse className={styles.collapseContainer} in={isSomeFieldVisible}>
      <div key={card.id} className={clsx(styles.card, className)}>
        <h5 className={styles.cardName}>{card.name}</h5>
        {renderBody()}
      </div>
    </Collapse>
  );
};

export default CardComponent;
