import { createContext, StrictMode } from 'react';
import 'product_modules';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRoutes from './routes';
import { store } from './store';
import './index.scss';
import ApiProvider, { IApiProviderValue } from './providers/ApiServiceProvider';
import ProductCalculationsRestApi from 'api/digifi/los/ProductCalculationsApi';
import AppTheme from 'AppTheme';
import GooglePlacesRestApi from 'api/GooglePlacesApi';
import ErrorBoundary from 'ErrorBoundary';

const googlePlacesApi = new GooglePlacesRestApi();

export const productCalculationsApi = new ProductCalculationsRestApi();
export const googlePlacesApiContext = createContext<GooglePlacesRestApi>(googlePlacesApi);

const apiProviderValue: IApiProviderValue = {
  productCalculationsApi,
};

ReactDOM.render(
  <ErrorBoundary>
    <StrictMode>
        <Provider store={store}>
          <ApiProvider value={apiProviderValue}>
            <AppTheme>
                <AppRoutes />
            </AppTheme>
          </ApiProvider>
        </Provider>
    </StrictMode>
  </ErrorBoundary>,
  document.getElementById('root'),
);
