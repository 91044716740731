import { darken, getContrast, lighten, rgba } from 'polished';

export const getBackgroundHoverColor = (accentColor: string) => {
  return rgba(accentColor, 0.1);
};

export const getHoverColor = (accentColor: string) => {
  const darkenColor = darken(0.1, accentColor);
  const lightenColor = lighten(0.1, accentColor);

  return getContrast(accentColor, darkenColor) > getContrast(accentColor, lightenColor)
    ? darkenColor
    : lightenColor;
};
