import { FC } from 'react';
import UploadFile, { UploadFromFileProps } from 'product_modules/components/UploadFile';
import styled from 'types/AppTheme';

const BROWSE_LINK_CLASS_NAME = 'UploadFileWrapper-browse-link';

const UploadFileWrapperStyled = styled(UploadFile)`
  .${BROWSE_LINK_CLASS_NAME} {
    color: ${({ theme }) => theme.colors.accentColor}
  }

  .${BROWSE_LINK_CLASS_NAME}:hover {
    color: ${({ theme }) => theme.colors.hoverAccentColor}
  }
`;

const UploadFileWrapper: FC<UploadFromFileProps> = (props) => {
  return (
    <UploadFileWrapperStyled
      {...props}
      browseLinkClassName={BROWSE_LINK_CLASS_NAME}
    />
  );
};

export default UploadFileWrapper;
