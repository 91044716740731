import { DateTime } from 'luxon';

export enum DateTimeFormat {
  Long = 'MMM dd, yyyy, h:mm a',
  LongMonthDate = 'MMM dd, yyyy',
  Short = 'MM/dd/yyyy',
}

const formatDate = (date: string, format: DateTimeFormat = DateTimeFormat.Long) => {
  const dateObject = new Date(Date.parse(date));

  if (dateObject.toISOString() === date) {
    return DateTime.fromISO(date).toFormat(format);
  }

  return DateTime.fromFormat(date, DateTimeFormat.Short).toFormat(format);
};

export default formatDate;
