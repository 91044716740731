import React, { FC, DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './Tag.module.scss';
import TagCore from './TagCore';

export type TagColor = 'green' | 'gray' | 'red' | 'blue' | 'yellow' | 'white';

type HTMLDivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface TagProps extends HTMLDivProps {
  color?: TagColor;
  medium?: boolean;
  className?: string;
  invertColors?: boolean;
}

const getColorTagClassName = (type: TagColor): string => {
  switch (type) {
    case 'green':
      return styles.green;
    case 'gray':
      return styles.gray;
    case 'red':
      return styles.red;
    case 'blue':
      return styles.blue;
    case 'yellow':
      return styles.yellow;
    case 'white':
      return styles.white;
    default:
      return styles.gray;
  }
};

const Tag: FC<TagProps> = ({ medium, color = 'gray', className, invertColors,  ...tagProps }) => {
  return <TagCore className={clsx(getColorTagClassName(color), invertColors && styles.inverted, className)} medium={medium} {...tagProps} />;
};

export default Tag;
