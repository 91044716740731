import { FC, useEffect, useState } from 'react';
import ModalOverlay from 'components/common/ModalOverlay';
import ApplicationOverview from 'components/ApplicationDetails/ApplicationOverview';
import ApplicationTabs from 'components/ApplicationDetails/ApplicationTabs';
import { ApplicationTabType, ITabProps } from 'components/ApplicationDetails/ApplicationTabs/ApplicationTabs';
import { ApplicationStatus } from 'enums/ApplicationStatus';
import { ISimplifiedApplicationParams } from 'api/digifi/los/ApplicationsApi';
import { IApplicationDocument } from 'api/digifi/los/DocumentsApi';
import OutstandingTasksInfo from 'components/ApplicationDetails/OutstandingTasksInfo';
import isFinalStatus from 'utils/isFinalStatus';
import { ITask } from 'api/digifi/los/TasksApi';
import styles from './ApplicationDocumentsDetailsModal.module.scss';
import SkeletonApplicationDetailsModal
  from 'components/ApplicationDetails/ApplicationDetailsModal/SkeletonApplicationDetailsModal';
import { useQueryParams } from 'product_modules/hooks/useQueryParam';
import { useNavigate } from 'react-router-dom';
import { BorrowerType } from 'enums/BorrowerType';

interface IApplicationDetailsModalProps {
  application: ISimplifiedApplicationParams | null;
  documents: IApplicationDocument[];
  tasks: ITask[];
  onClose: () => void;
  currency: string;
  onApplicationDocumentClick: (document: IApplicationDocument) => void;
  onTabContentLoadingComplete: () => void;
  borrowerType: BorrowerType;
  isLoading?: boolean;
}

enum ApplicationTabId {
  Docs = 'Application Documents',
  Tasks = 'Outstanding Tasks',
}

const DOCUMENTS_TAB_PARAMS = {
  label: ApplicationTabType.DocumentsTab,
  id: ApplicationTabId.Docs,
};

const TASKS_TAB_PARAMS = {
  label: ApplicationTabType.TasksTab,
  id: ApplicationTabId.Tasks,
};

const ApplicationDetailsModal: FC<IApplicationDetailsModalProps> = ({
  application,
  documents,
  tasks,
  onClose,
  currency,
  onApplicationDocumentClick,
  isLoading,
  onTabContentLoadingComplete,
  borrowerType,
}) => {
  const queryParams = useQueryParams();
  const selectedTabId = queryParams.get('tab-id');
  const navigate = useNavigate();
  const [tabs, setTabs] = useState<ITabProps[]>([TASKS_TAB_PARAMS, DOCUMENTS_TAB_PARAMS]);

  useEffect(() => {
    if (application) {
      setTabs(isFinalStatus(application.status) && !isLoading ? [DOCUMENTS_TAB_PARAMS] : [TASKS_TAB_PARAMS, DOCUMENTS_TAB_PARAMS]);
    }
  }, [application, isLoading]);

  const getTitle = () => {
    switch (application?.status) {
      case ApplicationStatus.Approved:
        return 'Your Application Was Approved 🎉';
      case ApplicationStatus.Rejected:
        return 'Your Application Was Declined';
      default:
        return 'Your Application Is In Process';
    }
  };

  const handleTabChange = (tabId: string) => {
    navigate(`?tab-id=${tabId}`);
  };

  return (
    <ModalOverlay title={getTitle()} onClick={onClose} isLoading={isLoading || !application}>
      <div className={styles.modalContent}>
        {!application
          ? (
            <SkeletonApplicationDetailsModal
              tabs={tabs}
              selectedTabId={selectedTabId}
            />
          )
          : (
            <>
              <OutstandingTasksInfo
                tasks={tasks}
                isLoading={isLoading}
                status={application.status}
              />
              <ApplicationOverview
                application={application}
                currency={currency}
                isLoading={isLoading}
                borrowerType={borrowerType}
              />
              <ApplicationTabs
                applicationId={application.id}
                selectedTabId={selectedTabId}
                documents={documents}
                tabs={tabs}
                onSelect={handleTabChange}
                tasks={tasks}
                onApplicationDocumentClick={onApplicationDocumentClick}
                onTabContentLoadingComplete={onTabContentLoadingComplete}
                isLoading={isLoading}
                applicationDisplayId={application.displayId}
              />
            </>
          )}
      </div>
    </ModalOverlay>
  );
};

export default ApplicationDetailsModal;
