import React, { FC, useState, ReactNode } from 'react';
import accepts from 'attr-accept';
import { CloseImage, DocumentIcon, PptIcon, XlsIcon, DocIcon, JsonIcon, ImageIcon, PdfIcon } from 'product_modules/static/images';
import styles from './UploadFile.module.scss';
import clsx from 'clsx';
import { isArray } from 'lodash';
import { DocumentExtension } from 'product_modules/api/LoanOriginationSystem/DocumentsApi';
import tinyColor from 'tinycolor2';
import Label from 'product_modules/components/Label';

export const iconsByExtension = new Map([
  [DocumentExtension.Xls, <XlsIcon />],
  [DocumentExtension.Xlsx, <XlsIcon />],
  [DocumentExtension.Csv, <XlsIcon />],
  [DocumentExtension.Doc, <DocIcon />],
  [DocumentExtension.Docx, <DocIcon />],
  [DocumentExtension.Xml, <DocIcon />],
  [DocumentExtension.Txt, <DocIcon />],
  [DocumentExtension.Ppt, <PptIcon />],
  [DocumentExtension.Pptx, <PptIcon />],
  [DocumentExtension.Pdf, <PdfIcon />],
  [DocumentExtension.Json, <JsonIcon />],
  [DocumentExtension.Gif, <ImageIcon />],
  [DocumentExtension.Png, <ImageIcon />],
  [DocumentExtension.Jpeg, <ImageIcon />],
  [DocumentExtension.Jpg, <ImageIcon />],
  [DocumentExtension.Svg, <ImageIcon />],
  [DocumentExtension.Ico, <ImageIcon />],
  [DocumentExtension.Heic, <ImageIcon />],
  [DocumentExtension.Webp, <ImageIcon />],
]);

export interface UploadFromFileProps {
  file: File | null;
  onFileChange: (file: File | null) => void;
  accept?: string | string[];
  maxFileSize?: number;
  text?: ReactNode;
  closeIcon?: React.ReactNode;
  noMargin?: boolean;
  brandingColor?: string;
  inputLabel?: string;
  browseLinkClassName?: string;
  className?: string;
}

export const getFileName = (fileName: string): string =>
  fileName
    .split('.')
    .slice(0, -1)
    .join('.');

export const getFileFormat = (fileName: string): string =>
  fileName
    .split('.')
    .slice(-1)
    .join();

const BROWSE_LINK_DARKNESS_PERCENTAGE = 5;

const INPUT_ID = 'fileInput';

const UploadFile: FC<UploadFromFileProps> = ({
  file,
  onFileChange,
  accept,
  maxFileSize,
  text,
  closeIcon,
  noMargin,
  brandingColor,
  inputLabel,
  browseLinkClassName,
  className,
}) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);
  const [isBrowseLinkHovered, setIsBrowseLinkHovered] = useState(false);

  const validateFileSize = (fileSize: number) => {
    if (typeof maxFileSize === 'undefined') {
      return true;
    }

    return fileSize <= maxFileSize;
  };

  const validateFile = (newFile: File) => {
    const isAcceptable = !accept || accepts(newFile, accept);

    if (!isAcceptable || !validateFileSize(newFile.size)) {
      setIsError(true);
      return;
    }

    if (isError) {
      setIsError(false);
    }

    onFileChange(newFile);
  };

  const handleDeleteFile = () => onFileChange(null);
  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target!.files![0];
    validateFile(newFile);
  };

  const handleFileDrag = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const newFile = e.dataTransfer.files[0];
    validateFile(newFile);
    setIsDragOver(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsDragOver(false);
  };

  const labelClassName = clsx(
    isDragOver ? styles.dragOverInputWrapper : styles.inputWrapper,
    isError && styles.errorInputWrapper,
  );

  const renderCloseImage = () => (
    <div className={styles.fileDeleteContainer} onClick={handleDeleteFile}>
      {closeIcon || <CloseImage className={styles.fileDelete} />}
    </div>
  );

  const renderBrowserLink = () => {
    const browseLinkStyle = {
      color: isBrowseLinkHovered
        ? tinyColor(brandingColor).darken(BROWSE_LINK_DARKNESS_PERCENTAGE).toString()
        : brandingColor,
    };

    return (
      <span
        style={browseLinkStyle}
        onMouseEnter={() => setIsBrowseLinkHovered(true)}
        onMouseLeave={() => setIsBrowseLinkHovered(false)}
        className={clsx(styles.link, browseLinkClassName)}
      >
        browse
      </span>
    );
  };

  return (
    <div className={className}>
      {inputLabel ? (
        <Label htmlFor={INPUT_ID} required>
          {inputLabel}
        </Label>
      ) : null}
      {file ? (
        <div className={clsx(styles.fileInfoContainer, noMargin && styles.noMargin)}>
          <div className={styles.fileImage}>
            {iconsByExtension.get(file.name.split('.').pop()?.toLowerCase() as DocumentExtension) || <DocumentIcon />}
          </div>
          <div className={styles.fileInfo}>
            <span className={styles.fileName}>{getFileName(file.name)}</span>
            <span className={styles.fileFormat}>{getFileFormat(file.name)}</span>
          </div>
          {renderCloseImage()}
        </div>
      ) : (
        <label
          className={clsx(labelClassName, noMargin && styles.noMargin)}
          onDrop={handleFileDrag}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <p className={styles.textContainer}>
            {text || (
              <>
                Drag a file here or {renderBrowserLink()} for a file to upload.
              </>
            )}
          </p>
          <input
            type="file"
            id={INPUT_ID}
            className={styles.fileInput}
            onChange={handleUploadFile}
            accept={isArray(accept) ? accept.join(',') : accept}
          />
        </label>
      )}
    </div>
  );
};

export default UploadFile;
