import { numberToPercentage } from 'product_modules/components/PercentageInput/utils';
import maskNumberValue from './masks/maskNumberValue';

const formatPercentageValue = (value: number) => {
  const percentageValue = numberToPercentage(value.toString());
  const maskedValue = maskNumberValue(percentageValue.toString());
  return `${maskedValue}%`;
};

export default formatPercentageValue;
