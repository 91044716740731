import { useMemo, useRef } from 'react';
import { FormLayoutData } from 'api/Types';
import { isEmptyVariableValue } from 'product_modules/utils/isEmptyVariableValue';
import { IVariable, VariableValue } from 'types/VariableTypes';
import { validateVariable } from 'product_modules/components/ConfigurableForm/validation';

export interface IBaseConfigurableFormVariableField {
  id: string;
  variable: IVariable;
  required?: boolean;
}

const useVariablesFormValidation = (
  fields: IBaseConfigurableFormVariableField[] | null,
  data: FormLayoutData | null,
  displayFieldsAttributes?: Record<string, boolean>,
) => {
  const cachedValidationResults = useRef<Record<string, [VariableValue, boolean, boolean]>>({});

  const runValidationForField = (
    field: IBaseConfigurableFormVariableField,
    currentValue: VariableValue,
    shouldDisplayField: boolean,
  ) => {
    const cachedResult = cachedValidationResults.current[field.id];
    const [previousValue, previousShouldDisplayField, previousValidationResult] = cachedResult || [];

    if (cachedResult && previousValue === currentValue && previousShouldDisplayField === shouldDisplayField) {
      return previousValidationResult;
    }

    return !shouldDisplayField || validateVariable(field.variable, currentValue, field.required);
  };

  return useMemo(() => {
    if (!fields || !data) {
      return false;
    }

    for (const field of fields) {
      const value = data[field.variable.systemName];
      const shouldDisplayField = !displayFieldsAttributes
        || displayFieldsAttributes[field.id]
        || !isEmptyVariableValue(value);
      const validationResult = runValidationForField(field, value, shouldDisplayField);

      cachedValidationResults.current[field.id] = [value, shouldDisplayField, validationResult];

      if (!validationResult) {
        return false;
      }
    }

    return true;
  }, [fields, data, displayFieldsAttributes]);
};

export default useVariablesFormValidation;
