import { useState, useEffect } from 'react';
import { IProductCalculation } from 'api/digifi/los/ProductCalculationsApi';
import { useProductCalculationsApi } from 'providers/ApiServiceProvider';

const useProductCalculations = (productId: string | null) => {
  const [productCalculations, setProductCalculations] = useState<IProductCalculation[] | null>(null);
  const productCalculationsApi = useProductCalculationsApi();

  useEffect(() => {
    if (!productId) {
      return;
    }

    productCalculationsApi.getProductCalculations(productId).then((calculations) => setProductCalculations(calculations));
  }, [productId]);

  return productCalculations;
};

export default useProductCalculations;
