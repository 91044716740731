import { Option } from 'product_modules/components/SelectInput/SelectInput';
import clsx from 'clsx';
import styles from 'product_modules/components/SelectInput/SelectInput.module.scss';
import { CheckedImage } from 'product_modules/static/images';
import React, { forwardRef, Ref } from 'react';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import ApplicationTag from 'product_modules/components/Tag/ApplicationTag';

interface DropdownItemProps {
  option: Option;
  index: number;
  selected?: boolean;
  focused?: boolean;
  handleSelect: (option: Option) => void;
  setFocusedItemIndex: (index: number) => void;
  isLabelTag?: boolean;
}

const DropdownItem = forwardRef<HTMLDivElement, DropdownItemProps>((props, ref: Ref<HTMLDivElement>) => {
  const { option, index, setFocusedItemIndex, handleSelect, selected = false, focused = false, isLabelTag } = props;

  const optionClassName = clsx(
    styles.option,
    selected && styles.optionChecked,
    focused && styles.optionActive,
    option.disabled && styles.optionDisabled,
  );

  const renderOptionContent = () => {
    if (option.componentOption) {
      return option.componentOption;
    }

    return (
      <div className={styles.optionContent}>
        {option.icon}
        <p className={clsx(styles.optionTextContent, option.icon && styles.hasIcon)}>
          <span className={styles.name}>{option.name}</span>
          {option.description && <span className={styles.description}>{option.description}</span>}
        </p>
        {option.iconAfter}
      </div>
    );
  };

  const renderLabelTag = () => (
    <ApplicationTag color={option.color} medium>
      {option.name}
    </ApplicationTag>
  );

  return (
    <WrapperWithTooltip tooltip={option.tooltip}>
      <div
        ref={ref}
        className={clsx(optionClassName, option.withSeparator && styles.withSeparatorOption)}
        onMouseEnter={() => setFocusedItemIndex(index)}
        onClick={() => handleSelect(option)}
      >
        {isLabelTag ? renderLabelTag() : renderOptionContent()}
        <input type="radio" name="option" readOnly checked={selected} value={option.value} />
        {selected && <CheckedImage className={styles.optionCheckMark} />}
      </div>
    </WrapperWithTooltip>
  );
});

export default DropdownItem;
