import { FC } from 'react';
import styles from './OutstandingTasksInfo.module.scss';
import { CheckMarkIcon, ExclamationMarkIcon } from 'static/images';
import clsx from 'clsx';
import { SkeletonOutstandingTasksInfo } from 'components/ApplicationDetails/OutstandingTasksInfo/index';
import isFinalStatus from 'utils/isFinalStatus';
import { ApplicationStatus } from 'enums/ApplicationStatus';
import { ITask, TaskStatus } from 'api/digifi/los/TasksApi';
import pluralize from 'utils/pluralize';

interface IOutstandingTasksInfoProps {
  tasks: ITask[];
  status: ApplicationStatus;
  isLoading?: boolean;
}

const OutstandingTasksInfo: FC<IOutstandingTasksInfoProps> = ({ tasks, status, isLoading }) => {
  const incompleteTasksCount = tasks.filter(task => task.status === TaskStatus.NotDone).length;

  const renderIcon = () => {
    return !incompleteTasksCount ? <CheckMarkIcon /> : <ExclamationMarkIcon />;
  };

  const renderContent = () => {
    if (isFinalStatus(status)) {
      return;
    }

    return (
      <div className={styles.tasksInfoContainer}>
        <div className={clsx(styles.infoIconContainer, {[styles.infoIconContainerComplete]: !incompleteTasksCount})}>
          {renderIcon()}
        </div>
        <div className={styles.textContainer}>
          <div className={styles.tasksInfoTitle}>
            {incompleteTasksCount === 0
              ? 'Application In Review'
              : 'Outstanding Tasks'}
          </div>
          <div className={styles.tasksInfoSubtitle}>
            {incompleteTasksCount === 0
              ? 'You have no tasks to complete at this time'
              : `You have ${incompleteTasksCount} ${pluralize('task', incompleteTasksCount)} to complete`}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading
        ? <SkeletonOutstandingTasksInfo />
        : renderContent()
      }
    </>
  );
};

export default OutstandingTasksInfo;
