import { sortDates, sortStrings } from 'product_modules/utils/sort';
import { ITask, TasksSortingField, TasksSortingType } from 'api/digifi/los/TasksApi';

export const sortApplicationTasks = (tasks: ITask[], sortingType: TasksSortingType) => {
  return [...tasks].sort((firstTask, secondTask) => {
    return sortTasks(firstTask, secondTask)(sortingType);
  });
};

const sortTasks =
  (taskA: ITask, taskB: ITask) =>
    ({ ascending, field }: TasksSortingType) => {
      switch (field) {
        case TasksSortingField.Description:
        case TasksSortingField.Status: {
          return sortStrings(taskA[field], taskB[field], ascending);
        }
        case TasksSortingField.DueDate: {
          return sortDates(new Date(taskA[field]), new Date(taskB[field]), ascending);
        }

        default: {
          return -1;
        }
      }
    };
