import createWorkerHook, { ModuleMethods } from 'product_modules/hooks/createWorkerHook';
import { Worker } from 'threads';
import { IFormulaCondition } from 'api/digifi/los/CardsApi';
import { VariableValue } from 'types/VariableTypes';
// @ts-expect-error it don't want .ts
// eslint-disable-next-line import/no-webpack-loader-syntax
import workerURL from 'threads-plugin/dist/loader?name=conditions-worker!../workers/ConditionalDisplayLogicWorker';

export interface IWithConditionalDisplayRuleField {
  id: string;
  conditionalDisplayRule?: IFormulaCondition | null;
}

export type DisplayFieldsAttributes = Record<string, boolean>;

interface IConditionalDisplayWorkerModule extends ModuleMethods {
  runConditionalDisplayLogic(
    fields: Array<IWithConditionalDisplayRuleField>,
    data: Record<string, VariableValue>,
  ): Promise<DisplayFieldsAttributes>;
}

const useConditionalDisplayLogicWorker = createWorkerHook<IConditionalDisplayWorkerModule>(() => {
  return new Worker(workerURL);
});

export default useConditionalDisplayLogicWorker;
