import { AddressValue, AddressValueKey } from 'product_modules/api/Types';

interface AddressTemplateComponent {
  key: keyof AddressValue;
  dismissComma?: boolean;
}

const addressTemplateComponents: Array<AddressTemplateComponent> = [
  { key: AddressValueKey.StreetNumber },
  { key: AddressValueKey.StreetName, dismissComma: true },
  { key: AddressValueKey.Sublocality },
  { key: AddressValueKey.City },
  { key: AddressValueKey.StateOrProvince },
  { key: AddressValueKey.ZipOrPostalCode },
  { key: AddressValueKey.Country },
];

const templateAddressPart = (
  addressTemplateComponent: AddressTemplateComponent,
  addressPart: string,
  fullAddress: string,
) => {
  return addressTemplateComponent.dismissComma ? `${fullAddress} ${addressPart}` : `${fullAddress}, ${addressPart}`;
};

export const buildFullAddressString = (address: AddressValue | null | undefined) => {
  if (!address) {
    return '';
  }

  return addressTemplateComponents.reduce((fullAddress, addressTemplateComponent) => {
    const addressPart = address[addressTemplateComponent.key];

    if (!addressPart) {
      return fullAddress;
    }

    return fullAddress
      ? templateAddressPart(addressTemplateComponent, addressPart, fullAddress)
      : addressPart;
  }, '');
};
