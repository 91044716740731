import React from 'react';
import clsx from 'clsx';
import capitalize from 'capitalize';
import { VariableValue } from 'product_modules/api/LoanOriginationSystem/Types';
import { BaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import formatValueByVariable from 'product_modules/utils/formatValueByVariable';
import OverflowedText from 'product_modules/components/OverflowedText';
import useFormLayout from 'product_modules/components/ConfigurableForm/useFormLayout';
import { isEmptyVariableValue } from 'product_modules/utils/isEmptyVariableValue';
import { PermissionGroupId } from 'product_modules/components/PermissionGroups/Types';
import { getVariableAccessPermissions } from 'product_modules/components/Variables/utils';
import styles from './CardTableView.module.scss';

export interface CardTableViewProps<VariableConfigurationType extends BaseVariableConfiguration> {
  className?: string;
  fieldClassName?: string;
  fieldTitleClassName?: string;
  fieldValueClassName?: string;
  data: Record<string, VariableValue>;
  fields: VariableConfigurationType[];
  columnsPerCard?: number;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  displayFieldsAttributes?: Record<string, boolean>;
  userPermissionGroupId?: PermissionGroupId;
  hiddenFieldClassName?: string;
}

const formatBooleanValue = (value: string) => capitalize(value);

const DEFAULT_COLUMNS_PER_CARD = 2;

const CardTableView = <
  VariableConfigurationType extends BaseVariableConfiguration
>({
  fields,
  columnsPerCard = DEFAULT_COLUMNS_PER_CARD,
  data,
  formatVariableConfigurationDisplayTitle,
  fieldClassName,
  fieldTitleClassName,
  fieldValueClassName,
  className,
  displayFieldsAttributes,
  userPermissionGroupId,
  hiddenFieldClassName,
}: CardTableViewProps<VariableConfigurationType>) => {
  const layout = useFormLayout(fields, columnsPerCard);

  if (!layout) {
    return null;
  }

  const renderField = (field: VariableConfigurationType) => {
    const visible = !displayFieldsAttributes
      || displayFieldsAttributes[field.id]
      || !isEmptyVariableValue(data[field.variable.systemName]);

    return (
      <div key={field.id} className={clsx(styles.field, !visible && styles.hiddenField, fieldClassName, !visible && hiddenFieldClassName)}>
        <div className={clsx(styles.fieldTitle, fieldTitleClassName)}>
          <OverflowedText>
            {formatVariableConfigurationDisplayTitle
              ? formatVariableConfigurationDisplayTitle(field.variable.systemName, field.variable.displayName)
              : field.variable.displayName}
          </OverflowedText>
        </div>
        <div className={clsx(styles.fieldValue, fieldValueClassName)}>
          <OverflowedText>
            {formatValueByVariable(data[field.variable.systemName], field.variable, {
              formatBooleanValue,
              accessPermissions: getVariableAccessPermissions(field.variable, userPermissionGroupId),
            })}
          </OverflowedText>
        </div>
      </div>
    );
  };

  const renderFieldsColumn = (columnFields: VariableConfigurationType[], index: number) => (
    <div key={index}>
      {columnFields.map((field) => renderField(field))}
    </div>
  );

  const cardPreviewStyle = { gridTemplateColumns: `repeat(${layout.length}, minmax(0, 1fr))` };

  return (
    <div style={cardPreviewStyle} className={clsx(styles.cardTableView, className)}>
      {layout.map(renderFieldsColumn)}
    </div>
  );
};

export default CardTableView;
