import React, { FC } from 'react';
import styles from './InfoSection.module.scss';
import clsx from 'clsx';
import OverflowedText from 'components/digifi-wrappers/OverflowedText';

interface IInfoSectionProps {
  sectionContent: Map<string | JSX.Element, string | JSX.Element | null>[];
  containerClassName?: string;
  fieldTitleClassName?: string;
  fieldValueClassName?: string;
  fieldClassName?: string;
}

const InfoSection: FC<IInfoSectionProps> = ({
  sectionContent,
  containerClassName,
  fieldTitleClassName,
  fieldValueClassName,
  fieldClassName,
}) => {
  const renderField = (fieldTitle: string | JSX.Element, fieldValue: string | JSX.Element | null, index: number) => {
    return (
      <div key={index} className={clsx(styles.field, fieldClassName)}>
        <div className={clsx(styles.fieldTitle, fieldTitleClassName)}>
          <OverflowedText>{fieldTitle}</OverflowedText>
        </div>
        <div className={clsx(styles.fieldValue, fieldValueClassName)}>
          <OverflowedText>{fieldValue}</OverflowedText>
        </div>
      </div>
    );
  };

  const renderFieldsColumn = (columnFields: Map<string | JSX.Element, string | JSX.Element | null>, index: number) => (
    <div key={index} className={styles.fieldsColumn}>
      {Array.from(columnFields.keys())
        .map((fieldTitle, fieldIndex) => renderField(fieldTitle, columnFields.get(fieldTitle) || '', fieldIndex))}
    </div>
  );

  return (
    <div className={clsx(styles.sectionContainer, containerClassName)}>
      {sectionContent.map((column, index) => renderFieldsColumn(column, index))}
    </div>
  );
};

export default InfoSection;
