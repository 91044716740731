import { FC } from 'react';
import { LinearProgress } from '@material-ui/core';
import styles from './LinearProgressBar.module.scss';
import styled from 'types/AppTheme';
import { rgba } from 'polished';

export const getBackgroundProgressBrandColor = (brandColor: string) => {
  return rgba(brandColor, 0.1);
};

interface ILinearProgressBarProps {
  progress: number;
}

const LinearProgressStyled = styled(LinearProgress)`
  & .MuiLinearProgress-barColorPrimary {
    background-color: ${({ theme }) => theme.colors.accentColor};
  },
  & .MuiLinearProgress-root {
    background-color: ${({ theme }) => getBackgroundProgressBrandColor(theme.colors.brandColor)} !important;
  },
`;

const LinearProgressBar: FC<ILinearProgressBarProps> = ({ progress }) => {
  return (
    <LinearProgressStyled
      variant="determinate"
      color="primary"
      value={progress}
      className={styles.linearProgress}
    />
  );
};

export default LinearProgressBar;
