import React, { FC } from 'react';
import styles from 'components/PageLayout/LoaderOverlay/LoaderOverlay.module.scss';
import Spinner from 'components/digifi-wrappers/Spinner';

const LoaderOverlay: FC = () => {
  return (
    <div className={styles.loaderContainer}>
      <Spinner size={52} thickness={4} className={styles.spinner} />
    </div>
  );
};

export default LoaderOverlay;
